export const ProductDataPTBRTranslations = {
  productDataHeroTitle: 'Decisões precisas precisam de dados de qualidade',
  productDataHeroDescription:
    'Os indicadores e séries atualizados da Feature Store estão prontos para serem utilizados em suas atividades de planejamento e tomada de decisão.',

  productDataHeroButtonPrimary: 'Fale com vendas',
  productDataHeroButtonSecondary: 'Conheça nossos planos',

  productDataSection1Title: 'Todos os dados que você precisa em um só lugar',
  productDataSection1Description:
    'Acesse os dados econômicos e projeções da Feature Store, incluindo índices de preços, crédito e indicadores climáticos. Mais de 130.000 séries, 1.900 indicadores e 100 fontes confiáveis. A análise de dados é mais fácil e eficaz quando todas as informações que você precisa estão em um só lugar.',
  productDataSection1ImageAlt: '',

  productDataSection2Title: 'Indicadores prontos para uso',
  productDataSection2Description:
    'Coletamos, preparamos, estruturamos, formatamos e atualizamos todos os dados. Nossos especialistas econômicos validam tudo. Você precisa apenas se concentrar em integrar os indicadores em seus processos estratégicos e operacionais.',
  productDataSection2ImageAlt: '',

  productDataSection3ImageAlt: '',
  productDataSection3Title: 'Atualizações em tempo real',
  productDataSection3Description:
    'Configure alertas para receber notificações quando seus conjuntos de dados forem atualizados. Você receberá alertas por e-mail (e arquivos do Excel) para garantir que esteja sempre trabalhando com os dados mais recentes.',

  productDataSection4Title: 'Respostas instantâneas para suas perguntas',
  productDataSection4Description:
    'Use o Chat4i, o chatGPT da 4intelligence, para responder às suas perguntas sobre os dados e o seu negócio. É simples e intuitivo. Desde executivos até especialistas e equipes não técnicas, todos podem fazer perguntas e obter respostas em segundos.',
  productDataSection4ImageAlt: '',

  productDataSection5ImageAlt: '',
  productDataSection5Title: 'Fácil de integrar',
  productDataSection5Description:
    'Integre os dados e projeções da Feature Store ao seu software. Seja no Power BI, Excel, Python ou qualquer outro método, a Feature Store oferece completa flexibilidade e se integra facilmente ao seu fluxo de trabalho.',

  productDataContactSalesTitle:
    'Acesse dados econômicos prontos para uso e impulsione seu negócio',
  productDataContactSalesButton: 'Comece grátis',

  productDataTestimonialsClient1Name: 'Evandro Schulz',
  productDataTestimonialsClient1JobDescription: 'Risk Management Specialist',
  productDataTestimonialsClient1Company: 'Bradesco',
  productDataTestimonialsClient1Quote:
    '“A Feature Store nos impressionou muito. Essa ferramenta é incrível. Os indicadores estão muito bem-organizados, eliminando o trabalho árduo de processamento de dados. Tudo está concentrado em um só lugar, com arquivos padronizados. Não precisamos mais buscar em outros sites.”',

  productDataTestimonialsClient2Name: 'Tatiana Celani',
  productDataTestimonialsClient2JobDescription: 'Head of Customer Intelligence',
  productDataTestimonialsClient2Company: 'Enel',
  productDataTestimonialsClient2Quote:
    '“A 4intelligence é um parceiro forte e confiável. Apreciamos o formato, a qualidade e a precisão dos resultados, além da capacidade de resolver de forma eficaz os problemas que identificamos.”',

  productDataTestimonialsClient3Name: 'Pedro Andrade',
  productDataTestimonialsClient3JobDescription: 'Projections Specialist',
  productDataTestimonialsClient3Company: 'Energisa',
  productDataTestimonialsClient3Quote:
    '“Com a 4intelligence, conseguimos analisar rapidamente grandes volumes de dados. Podemos testar várias variáveis e obter uma compreensão profunda de quais indicadores realmente impactam nossas projeções.”',
};
