export const FreemiumSignUpENUSTranslations = {
  freemiumSignUpPageHeadTitle: 'Freemium Sign Up | 4intelligence',
  freemiumSignUpPageHeadDescription:
    'Create your account to start using our platform',

  freemiumSignUpPageTitle: 'Create your account to start using our platform',
  freemiumSignUpPageDescription:
    'Fill in your details below to register for free.',
  freemiumSignUpPageCreateAccount: 'Create account',

  freemiumSignUpPageNameFieldLabel: 'Name',
  freemiumSignUpPageNameFieldPlaceholder: 'Enter your full name',

  freemiumSignUpPageCompanyFieldLabel: 'Company',
  freemiumSignUpPageCompanyFieldPlaceholder: 'Enter your company’s name',

  freemiumSignUpPageSectorFieldLabel: 'Sector',
  freemiumSignUpPageSectorFieldPlaceholder: 'Select your company’s sector',

  freemiumSignUpPageDepartmentFieldLabel: 'Department',
  freemiumSignUpPageDepartmentFieldPlaceholder:
    'Select your department at the company',

  freemiumSignUpPagePositionFieldLabel: 'Position',
  freemiumSignUpPagePositionFieldPlaceholder:
    'Enter your position in the company',

  freemiumSignUpPageEmailFieldLabel: 'Email',
  freemiumSignUpPageEmailFieldPlaceholder: 'Enter your company email',

  freemiumSignUpPageConfirmEmailFieldLabel: 'Confirm Email',
  freemiumSignUpPageConfirmEmailFieldPlaceholder: 'Enter your email again',

  freemiumSignUpPagePhoneFieldLabel: 'Telephone (optional)',
  freemiumSignUpPagePhoneFieldPlaceholder: 'Enter your telephone number',

  freemiumSignUpPageSector1: 'Agriculture & Agribusiness',
  freemiumSignUpPageSector2: 'Consultancy & Professional Services',
  freemiumSignUpPageSector3: 'Energy & Natural Resources',
  freemiumSignUpPageSector4: 'Finance & Financial Services',
  freemiumSignUpPageSector5: 'Government & Public Sector',
  freemiumSignUpPageSector6: 'Infrastructure',
  freemiumSignUpPageSector7: 'Manufacturing & Production - Food & Beverage',
  freemiumSignUpPageSector8: 'Manufacturing & Production - Automotive',
  freemiumSignUpPageSector9:
    'Manufacturing & Production - Electronics & Household Appliances',
  freemiumSignUpPageSector10:
    'Manufacturing & Production - Personal Care & Beauty',
  freemiumSignUpPageSector11:
    'Manufacturing & Production - Chemicals & Materials',
  freemiumSignUpPageSector12:
    'Manufacturing & Production - Clothing and Textiles',
  freemiumSignUpPageSector13: 'Manufacturing & Production - Others',
  freemiumSignUpPageSector14: 'Transport & Logistics',
  freemiumSignUpPageSector15: 'Retail',
  freemiumSignUpPageSector16: 'Other',

  freemiumSignUpPageDepartment1: 'Analytics',
  freemiumSignUpPageDepartment2: 'Compras / Supply',
  freemiumSignUpPageDepartment3: 'Finance',
  freemiumSignUpPageDepartment4: 'Marketing',
  freemiumSignUpPageDepartment5: 'Planning',
  freemiumSignUpPageDepartment6: 'S&OP',
  freemiumSignUpPageDepartment7: 'Sales',
  freemiumSignUpPageDepartment8: 'Others',

  freemiumSignUpPageAgreement1: 'I have read and agree to the ',
  freemiumSignUpPageAgreement2: 'Terms of Use',
  freemiumSignUpPageAgreement3: '. Read our ',
  freemiumSignUpPageAgreement4: 'Privacy Policy.',

  freemiumSignUpPageAgreement:
    'I have read and agree to the Terms of Use. Read our Privacy Policy.',

  freemiumSignUpPageFirstCarouselTitle:
    'Explore over 60 ready-to-use indicators',
  freemiumSignUpPageFirstCarouselDescription:
    'Access data from multiple sources with end-to-end intelligence on a single platform. Get ready-to-use information on vital sectors of the economy, including economic activity, prices, interest rates, commodities and more.',
  freemiumSignUpPageFirstCarouselPageButton: 'Carousel first page button',

  freemiumSignUpPageFirstCarouselIndicator1: 'Broad Consumer Price Index - CPI',
  freemiumSignUpPageFirstCarouselIndicator2:
    'Maximum Air Temperature (capitals)',
  freemiumSignUpPageFirstCarouselIndicator3:
    'Tax and Social Security Collection',
  freemiumSignUpPageFirstCarouselIndicator4:
    'Exchange Rate - BRL/USD - Sell - Ptax',
  freemiumSignUpPageFirstCarouselIndicator5: 'Selic Rate - Target',

  freemiumSignUpPageSecondCarouselTitle:
    'Anticipate the future with projections delivered by predictive AI algorithms',
  freemiumSignUpPageSecondCarouselDescription:
    'Use our accurate forecasts, powered by cutting-edge technology and validated by experts, to turn uncertainty into opportunities. Always work with up-to-date projections.',
  freemiumSignUpPageSecondCarouselPageButton: 'Carousel second page button',

  freemiumSignUpPageThirdCarouselTitle:
    'Simplify complex information through productive, easy conversations',
  freemiumSignUpPageThirdCarouselDescription:
    'Use Chat 4i, 4intelligence’s chatGPT, to explore the connection between data and your business in an intuitive and dynamic way, democratizing the use of data in everyday life.',
  freemiumSignUpPageThirdCarouselPageButton: 'Carousel third page button',

  freemiumSignUpPageRequiredField:
    'Please complete all required fields before confirming.',
  freemiumSignUpPageInvalidEmail: 'Email must be formatted correctly.',
  freemiumSignUpPageIsNotCompanyEmail: 'Use a company email.',
  freemiumSignUpPageDifferentEmail: 'Emails do not match. Please verify.',
  freemiumSignUpPageEmailAlreadyExists:
    'This email is already linked to an account. <a href="https://app.4intelligence.ai/"target="_blank" rel="noreferrer">Faça login</a>.',
  nameErrorNoSpecialCharacters:
    'Special characters and numbers are not allowed.',
  charactersLengthError: 'Must not contain more than 100 characters.',
  invalidPhoneNumber: 'Please enter a valid phone number.',

  freemiumSignUpPageCreatedTitle: 'We have sent an email to the address ',
  freemiumSignUpPageCreatedDescription:
    'Access to create your password and log in to our platform for the first time.',
  freemiumSignUpPageCreatedButton: 'This is not my email',
};
