import React, { useEffect } from 'react';

import { ChartLineDown, ProjectorScreenChart } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SolutionCase from 'src/assets/automobiles-case-solution.jpg';
import Thumb from 'src/assets/automobiles-case.jpg';
import { ContactSales } from 'src/components/ContactSales';
import { Description } from 'src/components/Description/styles';
import { Head } from 'src/components/Head';
import { LanguageWarning } from 'src/components/LanguageWarning';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsPageView } from 'src/utils/google-analytics';

import {
  ChallengeContent,
  Container,
  Content,
  Image,
  SectionContainer,
} from '../components/Case/styles';

export function AutomobilesCase() {
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(
      location.pathname,
      'Tela de Cases - Automóveis',
    );
  }, [location.pathname]);

  return (
    <Container>
      <Head
        title={`Case | ${translate('casesAutomotive')} | 4intelligence`}
        description={translate('casesPageAutomobilesHeadDescription') ?? ''}
      />

      <Title>
        <h4>Case multinacional de automóveis</h4>
        <h2>Projeção de vendas para otimizar a produção de caminhões</h2>
      </Title>

      {urlPrefix === '/en' && <LanguageWarning />}

      <Description>
        <p>
          Veja como a multinacional de automóveis utilizou a 4intelligence para
          prever como seriam suas vendas em 2021 e, com isso, otimizar a
          produção de novos veículos.
        </p>
      </Description>

      <img src={Thumb} alt="automobiles case" />

      <SectionContainer className="case-challenge">
        <Content>
          <h2>A importância de um planejamento adequado</h2>
          <p>
            A companhia tinha a necessidade de aprimoramento das projeções de
            vendas para caminhões pesados e semipesados, além de suas
            respectivas aberturas, buscando reduzir os riscos de saúde
            financeira da empresa.
          </p>
        </Content>
        <ChallengeContent>
          <div>
            <div>
              <ChartLineDown />
            </div>
            <h3>Market Share</h3>
            <p>
              Empresas podem ter seu market share consideravelmente reduzido
              quando não fazem um planejamento adequado de produção.
            </p>
          </div>

          <div>
            <div>
              <ProjectorScreenChart />
            </div>
            <h3>Duras consequências</h3>
            <p>
              A falta de planejamento adequado pode acarretar em perda de
              receita e comprometimento da saúde financeira.
            </p>
          </div>
        </ChallengeContent>
      </SectionContainer>

      <SectionContainer>
        <Image>
          <img
            src={SolutionCase}
            alt="Represents automobiles case"
            style={{ height: '100%' }}
          />
        </Image>

        <Content>
          <h2>Solução</h2>
          <p>
            Com a 4intelligence, a multinacional construiu cenários prospectivos
            robustos que levaram em consideração um entendimento profundo do
            mercado e da conjuntura econômica, proporcionando previsibilidade
            aos tomadores de decisão e reação rápida a eventos não antecipados,
            antecedendo o mercado e os concorrentes.
          </p>

          <div className="numeric-list">
            <div>1</div>
            <p>
              Otimização da produção e maior capacidade de gestão de desafios
            </p>
          </div>
          <div className="numeric-list">
            <div>2</div> <p>Ganho de market share</p>
          </div>
        </Content>
      </SectionContainer>

      <SectionContainer>
        <Content style={{ width: '80%', textAlign: 'center' }}>
          <h2>Resultados</h2>
          <p>
            As vendas de caminhões aumentaram 45,7% em relação a 2020 (21,8 mil
            unidades). A expansão da produção exigiu a abertura de 800 novas
            vagas na fábrica. Hoje, 4,2 mil pessoas trabalham na empresa, a
            maioria na área de produção, que funciona em dois turnos.
          </p>
          <p>
            O desempenho no mercado interno em 2021 foi um marco para a empresa.
            Líder de vendas de caminhões com capacidade acima de 16 toneladas
            (semipesados e pesados), a companhia registrou em 2021 o melhor ano
            de sua história no Brasil.
          </p>
        </Content>
      </SectionContainer>

      <ContactSales />
    </Container>
  );
}
