export const FreemiumUpgradeENUSTranslations = {
  freemiumUpgradePageHeadTitle: 'Freemium - Upgrade | 4intellingence',
  freemiumUpgradePageHeadDescription:
    'Tire suas dúvidas conosco. Fale com nossos especialistas',
  freemiumUpgradePageTitle:
    'Faça upgrade para acessar mais dados e desbloquear os recursos de modelagem preditiva da nossa plataforma.',
  freemiumUpgradePageDescription:
    'Fale conosco pelo Whatsapp ou preencha o formulário abaixo.',
  freemiumUpgradePageWhatsappButton: 'Falar pelo Whatsapp',
};
