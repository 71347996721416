import React from 'react';

import parseHTML from 'html-react-parser';

import { ContainerInput, Input as InputStyle, ContentInput } from './styles';
import { InputProps } from './types';
import { Label } from '../Label';

export function Input({
  label,
  style,
  max,
  error,
  placeholder,
  ...rest
}: InputProps) {
  return (
    <div style={style}>
      <Label text={label} />
      <ContainerInput error={!!error}>
        <ContentInput>
          <InputStyle max={max} placeholder={placeholder} {...rest} />
        </ContentInput>
        <p
          data-testid={`input-error-${label
            .toLowerCase()
            .replaceAll(' ', '-')}`}
        >
          {parseHTML(error ?? '')}
        </p>
      </ContainerInput>
    </div>
  );
}
