import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content !important;

  display: flex;
  align-items: center;
  gap: 1rem;

  border: 1px ${({ theme }) => theme.colors.gray2} solid;
  border-radius: 0.5rem;

  padding: 1rem;
  margin-top: 2rem;

  p {
    color: ${({ theme }) => theme.colors.gray6};
  }

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
