import styled from 'styled-components';

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  z-index: 2 !important;

  @media (max-width: 500px) {
    h4 {
      max-width: 80%;
      line-height: 160%;
      margin: 0 auto 1.5rem auto;
    }
  }
`;

export const VideoContent = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 3rem;

  iframe {
    width: 658px;
    aspect-ratio: 16 / 9;

    border-radius: 12px;
  }
`;
