import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw !important;
  max-width: none !important;

  height: auto;

  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 29% 7rem;
  margin: -6rem 0 !important;

  z-index: 1 !important;

  h1,
  h3,
  h4,
  p,
  small {
    text-align: center;

    color: ${({ theme }) => theme.colors.white} !important;
  }

  > small {
    font-size: 1rem;

    margin-top: 6rem;
  }

  @media (max-width: 880px) {
    padding: 40% 7rem 32% 7rem;
  }

  @media (max-width: 760px) {
    padding: 48% 2rem 24% 2rem;
    margin-top: -8rem !important;
  }

  @media (max-width: 500px) {
    padding: 92% 2rem 40% 2rem;
    margin-top: -16rem !important;
  }
`;

export const Content = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5rem;

  margin-top: 4rem;

  @media (max-width: 760px) {
    flex-direction: column;
    margin-top: 2rem;
  }
`;

export const Value = styled.div`
  width: 100%;

  h1 {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 4rem;

    margin-bottom: 0.75rem;
  }

  h3 {
    text-transform: lowercase;
    letter-spacing: 0rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 190%;
  }
`;
