export const ProductPlanningENUSTranslations = {
  productPlanningHeroTitle: 'Improve decision-making through collaboration',
  productPlanningHeroDescription:
    'Traditional planning – where different areas of the organization create their own plans – is often fragmented and difficult to implement. In contrast, we give you a single place for teams to work together. Log in and start collaborating.',

  productPlanningHeroButtonPrimary: 'Book a Demo',
  productPlanningHeroButtonSecondary: 'See Business Critical plan',

  productPlanningSection1Title: 'Single source of truth',
  productPlanningSection1Description:
    'Workspace enables you to capture everything about the decision-making process in a single location. Keep a record of all the decisions, the data used, and the individuals involved. Avoid the confusion of a disjointed planning process by optimizing cross-functional processes and collaboration.',
  productPlanningSection1ImageAlt: '',

  productPlanningSection2Title: 'Check results against forecasts',
  productPlanningSection2Description:
    'It’s time to increase accountability and track your decisions! Simply navigate between published versions and compare results today with forecasts made at an earlier date. The platform enables you to integrate strategic planning outputs into your day-to-day, operational reality.',
  productPlanningSection2ImageAlt: '',

  productPlanningSection3Title: 'Multiple users',
  productPlanningSection3Description:
    'In Workspace, you have multiple user logins. Anyone involved in a project can update information in the control panel. You decide levels of access and ensure users receive outputs appropriate for their profile, either more technical or business-oriented.',
  productPlanningSection3ImageAlt: '',

  productPlanningSection4ImageAlt: '',
  productPlanningSection4Title: 'Approvals and governance made simple',
  productPlanningSection4Description:
    'As integrated planning happens at all levels of the organization, effective governance is vital. Who can make the decision? Who approves the decision? Who is responsible for the results? As well as showing the full history of how each decision gets made, Workspace has a simple system for managing approvals.',

  productPlanningSection5Title: 'Designed for your unique needs',
  productPlanningSection5Description:
    'Your organization is unique, with its own priorities, timings, structures, and processes. Our platform is flexible and adapts to the details of your operational reality. As a client, we partner with you and actively encourage your inputs on how we can improve the product.',
  productPlanningSection5ImageAlt: '',

  productPlanningContactSalesTitle:
    'Do you want a better integrated planning process?',
  productPlanningContactSalesButton: 'Book a Demo',

  productPlanningTestimonialsClient1Name: 'Gabriel Leite',
  productPlanningTestimonialsClient1JobDescription: 'Risk Coordinator',
  productPlanningTestimonialsClient1Company: 'HDI Insurance',
  productPlanningTestimonialsClient1Quote:
    '“We complete projections for many different products. Instead of bringing our results into Excel, we have started to use Workspace. It’s very user-friendly. It saves us a lot of time in presenting our outputs to the rest of the company.”',

  productPlanningTestimonialsClient2Name: 'Renata Maria Oliveira',
  productPlanningTestimonialsClient2JobDescription: 'Revenue Management',
  productPlanningTestimonialsClient2Company: 'M Dias Branco',
  productPlanningTestimonialsClient2Quote:
    '“We are liking Workspace a lot. Being able to visualizing aggregated and disaggregated projections is very useful for our planning and demand forecasting processes.”',
};
