import React, { useState } from 'react';

import { CaretDown } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/redux/store';
import { getPhosphorIcons } from 'src/utils/getPhosphorIcons';

import { Container, MenuItem } from './styles';
import { MenuMobileProps } from './types';
import { ItemProps } from '../../types';

export function MenuMobile({ menuData, currentPath }: MenuMobileProps) {
  const [openHelpCenter, setOpenHelpCenter] = useState(false);

  const [openMenu, setOpenMenu] = useState<string>(currentPath);

  const { language } = useSelector((state: RootState) => state.config);
  const navigate = useNavigate();

  const handleClickMenu = (path: string, clickedItem: string) => {
    if (clickedItem.endsWith('.md')) {
      navigate(`/help-center/${path}/${clickedItem}`);
    } else {
      const newPath = `${path}/${clickedItem}`;

      const isOpen = openMenu.startsWith(path ? newPath : clickedItem);

      if (isOpen) {
        if (openMenu.startsWith(`${clickedItem}`)) {
          setOpenMenu('');
        } else {
          setOpenMenu(path);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (path === '') setOpenMenu(clickedItem);
        else {
          setOpenMenu(newPath);
        }
      }
    }
  };

  const subMenu = (menu: ItemProps[], path: string) => (
    <ul>
      {menu.map((item) => (
        <MenuItem
          key={`${path}/${item.path}`}
          selected={
            currentPath === `${path}/${item.path}` ||
            (!item.path.endsWith('.md') &&
              currentPath.startsWith(`${path}/${item.path}`))
          }
          isOpen={!!openMenu.startsWith(`${path}/${item.path}`)}
          isLastLevel={item.path.endsWith('.md')}
        >
          <button
            type="button"
            onClick={() => handleClickMenu(path, item.path)}
          >
            {item.name[language]}
            {item.submenu && (
              <CaretDown
                weight={
                  currentPath.startsWith(`${path}/${item.path}`)
                    ? 'bold'
                    : 'regular'
                }
              />
            )}
          </button>
          {item.submenu && subMenu(item.submenu, `${path}/${item.path}`)}
        </MenuItem>
      ))}
    </ul>
  );

  return (
    <Container isOpen={openHelpCenter}>
      <div>
        <button
          type="button"
          onClick={() => setOpenHelpCenter(!openHelpCenter)}
        >
          <h3>Central de Ajuda</h3>
          <CaretDown weight={openHelpCenter ? 'bold' : 'regular'} />
        </button>

        {openHelpCenter && (
          <ul>
            {menuData.map((item, index) => (
              <MenuItem
                key={item.path}
                selected={
                  currentPath === item.path && item.path.endsWith('.md')
                }
                isLastLevel={item.path.endsWith('.md')}
                isOpen
                style={{ marginTop: index !== 0 ? '2rem' : 0 }}
              >
                <div className="first-level">
                  {getPhosphorIcons(item.icon, 'duotone')}
                  {item.name[language]}
                </div>
                {item.submenu && subMenu(item.submenu, item.path)}
              </MenuItem>
            ))}
          </ul>
        )}
      </div>
    </Container>
  );
}
