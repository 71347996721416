import styled, { css } from 'styled-components';

type ButtonStyled = {
  buttonType: 'primary' | 'secondary' | 'tertiary' | 'naked' | 'rounded';
  disabled?: boolean;
  iconInvert?: boolean;
  hasIcon?: boolean;
};

export const ButtonStyled = styled.button<ButtonStyled>`
  ${({ theme, buttonType, disabled }) =>
    ['primary', 'rounded'].includes(buttonType)
      ? css`
          background: ${!disabled ? theme.colors.primary : theme.colors.gray3};
          color: ${theme.colors.white};
        `
      : buttonType === 'secondary'
      ? css`
          border: 1px solid
            ${!disabled ? theme.colors.primary : theme.colors.gray3};
          color: ${!disabled ? theme.colors.primary : theme.colors.gray3};
        `
      : buttonType === 'tertiary'
      ? css`
          background: ${`${
            !disabled ? theme.colors.primary : theme.colors.gray3
          }1F`};
          color: ${!disabled ? theme.colors.primary : theme.colors.gray3};
        `
      : buttonType === 'naked' &&
        css`
          background: transparent;
          color: ${!disabled ? theme.colors.primary : theme.colors.gray3};
        `}

  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};

  height: fit-content;

  border-radius: ${({ buttonType }) =>
    buttonType === 'rounded' ? '999px' : '8px'};

  font-size: 1rem;
  font-weight: 600;

  padding: 1rem 1.5rem;

  transition: all 0.2s;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 1rem;
      height: 1rem;

      margin-left: ${({ iconInvert }) => iconInvert && '0.5rem'};
      margin-right: ${({ iconInvert }) => !iconInvert && '0.5rem'};
    }
  }

  &:hover {
    ${({ theme, buttonType, disabled }) =>
      ['primary', 'rounded'].includes(buttonType)
        ? css`
            background: ${!disabled && theme.colors.primaryDark};
          `
        : buttonType === 'secondary'
        ? css`
            color: ${!disabled && theme.colors.primaryDark};
            border: ${!disabled
              ? `1px solid ${theme.colors.primaryDark}`
              : `1px solid ${theme.colors.gray3}`};
          `
        : buttonType === 'tertiary'
        ? css`
            color: ${!disabled && theme.colors.primaryDark};
          `
        : buttonType === 'naked' &&
          css`
            color: ${!disabled && theme.colors.primaryDark};
          `};
  }
`;

interface SpinnerProps {
  hasIcon?: boolean;
  spinnerType: 'primary' | 'secondary' | 'tertiary' | 'naked' | 'rounded';
}

export const Spinner = styled.div<SpinnerProps>`
  width: 0.875rem;
  height: 0.875rem;

  margin: 0 -0.625rem 0 0.625rem;

  border: 3px solid
    ${({ theme, spinnerType }) =>
      ['primary', 'rounded'].includes(spinnerType)
        ? theme.colors.primaryDark
        : spinnerType === 'secondary'
        ? theme.colors.gray2
        : spinnerType === 'tertiary' && theme.colors.gray3};
  border-left-color: ${({ theme, spinnerType }) =>
    ['primary', 'rounded'].includes(spinnerType)
      ? theme.colors.gray2
      : (spinnerType === 'secondary' || spinnerType === 'tertiary') &&
        theme.colors.primaryDark};
  border-radius: 50%;

  animation: spin 0.8s linear infinite;

  ${({ hasIcon }) =>
    hasIcon === true &&
    css`
      margin-right: 0.125rem;
    `}

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
