import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  height: 30%;
  width: 30%;
  padding: 18% 20%;
  background: linear-gradient(0deg, #4c94ff, #4c94ff), #533ed1;
  border-radius: 5%;
`;

const upAnimation = keyframes`
  0%, 100% {
    transform: translate3d(0, 70%,0);

  }
  50% {
    transform:translate3d(0,0,0);

  }
`;

const downAnimation = keyframes`
  0%, 100% {
    transform: translate3d(0, -70%,0);
  }

  50% {
    transform:translate3d(0,0,0);
  }

`;

export const BarContents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  div {
    background: ${({ theme }) => theme.colors.white};
    width: 20%;
    border-radius: 15%;
  }

  > div:nth-child(1) {
    height: 60%;
    animation: ${upAnimation} 0.7s ease infinite;
  }
  > div:nth-child(2) {
    height: 100%;
  }
  > div:nth-child(3) {
    margin-top: 45%;
    height: 60%;
    animation: ${downAnimation} 0.7s ease infinite;
  }
`;
