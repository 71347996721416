import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow-x: hidden;

  padding: 0 7rem;

  > div {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth};

    height: 100%;

    z-index: 0;
  }

  > div + div {
    margin-top: 8rem;
  }

  @media (max-width: 768px) {
    padding: 2rem;

    p {
      font-size: 1rem;
      line-height: 1.75rem;
    }

    h4 {
      font-size: 1rem;
    }
  }
`;
