import React, { useRef, useEffect, useState, useCallback } from 'react';

type CounterProps = {
  finalValue: number;
};

export function Counter({ finalValue }: CounterProps) {
  const [value, setValue] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const counterRef = useRef<HTMLDivElement>(null);

  const increaseValue = useCallback(() => {
    if (value < finalValue && isVisible) {
      setValue(value + 1);
    }
  }, [value, finalValue, isVisible]);

  useEffect(() => {
    const handleScroll = () => {
      if (counterRef.current) {
        const rect = counterRef.current.getBoundingClientRect();

        setIsVisible(
          rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <=
              (window.innerWidth || document.documentElement.clientWidth),
        );
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setTimeout(increaseValue, 2000 / finalValue);
  }, [value, isVisible, finalValue, increaseValue]);

  return <h1 ref={counterRef}>{value}%</h1>;
}
