import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import clientPlaceholderImage from 'src/assets/client-placeholder.svg';
import section1ImageENUS from 'src/assets/product/planning/1-en.svg';
import section1ImagePTBR from 'src/assets/product/planning/1-pt.svg';
import section2VectorENUS from 'src/assets/product/planning/2-en.svg';
import section2Image from 'src/assets/product/planning/2-image.png';
import section2VectorPTBR from 'src/assets/product/planning/2-pt.svg';
import section3Background from 'src/assets/product/planning/3-background.svg';
import section3VectorENUS from 'src/assets/product/planning/3-en.svg';
import section3Image from 'src/assets/product/planning/3-image.png';
import section3VectorPTBR from 'src/assets/product/planning/3-pt.svg';
import section4ImageENUS from 'src/assets/product/planning/4-en.svg';
import section4ImagePTBR from 'src/assets/product/planning/4-pt.svg';
import section5ImageENUS from 'src/assets/product/planning/5-en.svg';
import section5ImagePTBR from 'src/assets/product/planning/5-pt.svg';
import heroBackground from 'src/assets/product/planning/hero-background.svg';
import heroVectorENUS from 'src/assets/product/planning/hero-en.svg';
import heroImage from 'src/assets/product/planning/hero-image.png';
import heroVectorPTBR from 'src/assets/product/planning/hero-pt.svg';
import client1Image from 'src/assets/product/planning/testimonials/client1.png';
import { ContactSales } from 'src/components/ContactSales';
import { Head } from 'src/components/Head';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';
import { useTheme } from 'styled-components';

import {
  Container,
  Content,
  Hero,
  HeroContent,
  HeroButtons,
  Column,
  Row,
  BackgroundSection,
  BackgroundImageSection,
  HeroBackground,
} from '../components/Module/styles';
import { Testimonials } from '../components/Testimonials';
import { VectorImage } from '../components/VectorImage';

export function ProductPlanning() {
  const { t: translate } = useTranslation();
  const { urlPrefix, language } = useSelector(
    (state: RootState) => state.config,
  );
  const location = useLocation();
  const theme = useTheme();

  return (
    <Container>
      <Head
        title={`${translate('productPlanning')} | 4intelligence`}
        description={translate('productPlanningHeroDescription') ?? ''}
      />

      <Content>
        <HeroBackground src={heroBackground} alt="hero background" />

        <Hero backgroundType="image">
          <HeroContent>
            <div>
              <Title>
                <h2>{translate('productPlanningHeroTitle')}</h2>
              </Title>

              <p>{translate('productPlanningHeroDescription')}</p>

              <HeroButtons
                color={theme.colors.green4}
                hover={theme.colors.green6}
              >
                <Link
                  to={`${urlPrefix}/contact-sales`}
                  onClick={() => {
                    TrackGoogleAnalyticsEvent(
                      'Falar com vendas',
                      'Ir para tela Falar com Vendas',
                      location.pathname,
                    );
                  }}
                >
                  {translate('productPlanningHeroButtonPrimary')}
                </Link>
                <Link
                  to={`${urlPrefix}/plans`}
                  onClick={() => {
                    TrackGoogleAnalyticsEvent(
                      'Ver planos',
                      'Ir para tela de planos',
                      location.pathname,
                    );
                  }}
                >
                  {translate('productPlanningHeroButtonSecondary')}
                </Link>
              </HeroButtons>
            </div>

            <VectorImage
              under={heroImage}
              over={language === 'pt-br' ? heroVectorPTBR : heroVectorENUS}
              alt={translate('productModelsSection1ImageAlt') ?? ''}
            />
          </HeroContent>
        </Hero>

        <Column>
          <div>
            <Title>
              <h2>{translate('productPlanningSection1Title')}</h2>
            </Title>
            <p>{translate('productPlanningSection1Description')}</p>
          </div>

          <img
            src={language === 'pt-br' ? section1ImagePTBR : section1ImageENUS}
            alt={translate('productPlanningSection1ImageAlt') ?? ''}
          />
        </Column>

        <Row>
          <div>
            <Title>
              <h2>{translate('productPlanningSection2Title')}</h2>
            </Title>
            <p>{translate('productPlanningSection2Description')}</p>
          </div>

          <VectorImage
            under={section2Image}
            over={
              language === 'pt-br' ? section2VectorPTBR : section2VectorENUS
            }
            alt={translate('productPlanningSection2ImageAlt') ?? ''}
          />
        </Row>

        <BackgroundImageSection
          color={theme.colors.green4}
          style={{
            backgroundImage: `url(${section3Background})`,
          }}
        >
          <Row>
            <VectorImage
              over={section3Image}
              under={
                language === 'pt-br' ? section3VectorPTBR : section3VectorENUS
              }
              alt={translate('productPlanningSection3ImageAlt') ?? ''}
            />

            <div>
              <Title>
                <h2>{translate('productPlanningSection3Title')}</h2>
              </Title>
              <p>{translate('productPlanningSection3Description')}</p>
            </div>
          </Row>
        </BackgroundImageSection>

        <Row>
          <div>
            <Title>
              <h2>{translate('productPlanningSection4Title')}</h2>
            </Title>
            <p>{translate('productPlanningSection4Description')}</p>
          </div>

          <img
            src={language === 'pt-br' ? section4ImagePTBR : section4ImageENUS}
            alt={translate('productPlanningSection4ImageAlt') ?? ''}
          />
        </Row>

        <BackgroundSection color={theme.colors.green4}>
          <Row>
            <img
              src={language === 'pt-br' ? section5ImagePTBR : section5ImageENUS}
              alt={translate('productPlanningSection5ImageAlt') ?? ''}
            />

            <div>
              <Title>
                <h2>{translate('productPlanningSection5Title')}</h2>
              </Title>
              <p>{translate('productPlanningSection5Description')}</p>
            </div>
          </Row>
        </BackgroundSection>

        <Testimonials
          dotsColor={theme.colors.green4}
          testimonials={[
            {
              name: translate('productPlanningTestimonialsClient1Name'),
              jobDescription: translate(
                'productPlanningTestimonialsClient1JobDescription',
              ),
              company: translate('productPlanningTestimonialsClient1Company'),
              image: client1Image,
              quote: translate('productPlanningTestimonialsClient1Quote'),
            },
            {
              name: translate('productPlanningTestimonialsClient2Name'),
              jobDescription: translate(
                'productPlanningTestimonialsClient2JobDescription',
              ),
              company: translate('productPlanningTestimonialsClient2Company'),
              image: clientPlaceholderImage,
              quote: translate('productPlanningTestimonialsClient2Quote'),
            },
          ]}
        />
      </Content>

      <ContactSales
        content={{
          title: translate('productPlanningContactSalesTitle') ?? '',
          button: translate('productPlanningContactSalesButton') ?? '',
        }}
        color={{ normal: theme.colors.green4, hover: theme.colors.green6 }}
      />
    </Container>
  );
}
