import { ContactSalesComponentENUSTranslations } from 'src/components/ContactSales/translations/en-us';
import { ContactSalesComponentPTBRTranslations } from 'src/components/ContactSales/translations/pt-br';
import { CookiesENUSTranslations } from 'src/components/Cookies/translations/en-us';
import { CookiesPTBRTranslations } from 'src/components/Cookies/translations/pt-br';
import { FormENUSTranslations } from 'src/components/Form/translations/en-us';
import { FormPTBRTranslations } from 'src/components/Form/translations/pt-br';
import { LanguageMenuENUSTranslations } from 'src/components/LanguageMenu/translations/en-us';
import { LanguageMenuPTBRTranslations } from 'src/components/LanguageMenu/translations/pt-br';
import { FooterENUSTranslations } from 'src/components/Layout/Footer/translations/en-us';
import { FooterPTBRTranslations } from 'src/components/Layout/Footer/translations/pt-br';
import { HeaderENUSTranslations } from 'src/components/Layout/Header/translations/en-us';
import { HeaderPTBRTranslations } from 'src/components/Layout/Header/translations/pt-br';
import { MenuItemsENUSTranslations } from 'src/components/MenuItems/translations/en-us';
import { MenuItemsPTBRTranslations } from 'src/components/MenuItems/translations/pt-br';
import { MobileMenuENUSTranslations } from 'src/components/MobileMenu/translations/en-us';
import { MobileMenuPTBRTranslations } from 'src/components/MobileMenu/translations/pt-br';
import { CasesPageENUSTranslations } from 'src/pages/Cases/translations/en-us';
import { CasesPagePTBRTranslations } from 'src/pages/Cases/translations/pt-br';
import { ContactSalesPageENUSTranslations } from 'src/pages/ContactSales/translations/en-us';
import { ContactSalesPagePTBRTranslations } from 'src/pages/ContactSales/translations/pt-br';
import { DocumentationPageENUStranslations } from 'src/pages/Documentation/translations/en-us';
import { DocumentationPagePTBRTranslations } from 'src/pages/Documentation/translations/pt-br';
import { FreemiumSignUpENUSTranslations } from 'src/pages/Freemium/SignUp/translations/en-us';
import { FreemiumSignUpPTBRTranslations } from 'src/pages/Freemium/SignUp/translations/pt-br';
import { FreemiumUpgradeENUSTranslations } from 'src/pages/Freemium/Upgrade/translations/en-us';
import { FreemiumUpgradePTBRTranslations } from 'src/pages/Freemium/Upgrade/translations/pt-br';
import { LandingPageCasesContentENUSTranslations } from 'src/pages/Landing/content/Cases/translations/en-us';
import { LandingPageCasesContentPTBRTranslations } from 'src/pages/Landing/content/Cases/translations/pt-br';
import { LandingPageCarouselTabENUSTranslations } from 'src/pages/Landing/content/Departments/components/CarouselTab/translations/en-us';
import { LandingPageCarouselTabPTBRTranslations } from 'src/pages/Landing/content/Departments/components/CarouselTab/translations/pt-br';
import { LandingPageDepartmentsContentENUSTranslations } from 'src/pages/Landing/content/Departments/translations/en-us';
import { LandingPageDepartmentsContentPTBRTranslations } from 'src/pages/Landing/content/Departments/translations/pt-br';
import { LandingPageHeroContentENUSTranslations } from 'src/pages/Landing/content/Hero/translations/en-us';
import { LandingPageHeroContentPTBRTranslations } from 'src/pages/Landing/content/Hero/translations/pt-br';
import { LandingPageModulesContentENUSTranslations } from 'src/pages/Landing/content/Modules/translations/en-us';
import { LandingPageModulesContentPTBRTranslations } from 'src/pages/Landing/content/Modules/translations/pt-br';
import { LandingPagePlansContentENUSTranslations } from 'src/pages/Landing/content/Plans/translations/en-us';
import { LandingPagePlansContentPTBRTranslations } from 'src/pages/Landing/content/Plans/translations/pt-br';
import { LandingPageRoiContentENUSTranslations } from 'src/pages/Landing/content/ROI/translations/en-us';
import { LandingPageRoiContentPTBRTranslations } from 'src/pages/Landing/content/ROI/translations/pt-br';
import { LandingPageVideoContentENUSTranslations } from 'src/pages/Landing/content/Video/translations/en-us';
import { LandingPageVideoContentPTBRTranslations } from 'src/pages/Landing/content/Video/translations/pt-br';
import { LandingPageENUSTranslations } from 'src/pages/Landing/translations/en-us';
import { LandingPagePTBRTranslations } from 'src/pages/Landing/translations/pt-br';
import { NotFoundPageENUSTranslations } from 'src/pages/NotFound/translations/en-us';
import { NotFoundPagePTBRTranslations } from 'src/pages/NotFound/translations/pt-br';
import { PlansENUSTranslations } from 'src/pages/Plans/translations/en-us';
import { PlansPTBRTranslations } from 'src/pages/Plans/translations/pt-br';
import { ProductTestimonialsENUSTranslations } from 'src/pages/Product/components/Testimonials/translations/en-us';
import { ProductTestimonialsPTBRTranslations } from 'src/pages/Product/components/Testimonials/translations/pt-br';
import { ProductDataENUSTranslations } from 'src/pages/Product/EconomicData/translations/en-us';
import { ProductDataPTBRTranslations } from 'src/pages/Product/EconomicData/translations/pt-br';
import { ProductModelsENUSTranslations } from 'src/pages/Product/Models/translations/en-us';
import { ProductModelsPTBRTranslations } from 'src/pages/Product/Models/translations/pt-br';
import { ProductPlanningENUSTranslations } from 'src/pages/Product/Planning/translations/en-us';
import { ProductPlanningPTBRTranslations } from 'src/pages/Product/Planning/translations/pt-br';

import {
  CommonTextsENUSTranslations,
  CommonTextsPTBRTranslations,
} from './translations/common';

export const enUSTranslations = {
  ...FooterENUSTranslations,
  ...HeaderENUSTranslations,
  ...LandingPageENUSTranslations,
  ...NotFoundPageENUSTranslations,
  ...ContactSalesPageENUSTranslations,
  ...LanguageMenuENUSTranslations,
  ...CookiesENUSTranslations,
  ...LandingPageCarouselTabENUSTranslations,
  ...MobileMenuENUSTranslations,
  ...CommonTextsENUSTranslations,
  ...FreemiumUpgradeENUSTranslations,
  ...FormENUSTranslations,
  ...FreemiumSignUpENUSTranslations,
  ...ContactSalesComponentENUSTranslations,
  ...LandingPageCasesContentENUSTranslations,
  ...LandingPageDepartmentsContentENUSTranslations,
  ...LandingPageHeroContentENUSTranslations,
  ...LandingPageModulesContentENUSTranslations,
  ...LandingPagePlansContentENUSTranslations,
  ...LandingPageRoiContentENUSTranslations,
  ...LandingPageVideoContentENUSTranslations,
  ...MenuItemsENUSTranslations,
  ...ProductDataENUSTranslations,
  ...ProductModelsENUSTranslations,
  ...ProductPlanningENUSTranslations,
  ...ProductTestimonialsENUSTranslations,
  ...DocumentationPageENUStranslations,
  ...PlansENUSTranslations,
  ...CasesPageENUSTranslations,
};

export const ptBRTranslations = {
  ...FooterPTBRTranslations,
  ...HeaderPTBRTranslations,
  ...LandingPagePTBRTranslations,
  ...NotFoundPagePTBRTranslations,
  ...ContactSalesPagePTBRTranslations,
  ...LanguageMenuPTBRTranslations,
  ...CookiesPTBRTranslations,
  ...LandingPageCarouselTabPTBRTranslations,
  ...MobileMenuPTBRTranslations,
  ...CommonTextsPTBRTranslations,
  ...FreemiumUpgradePTBRTranslations,
  ...FormPTBRTranslations,
  ...FreemiumSignUpPTBRTranslations,
  ...ContactSalesComponentPTBRTranslations,
  ...LandingPageCasesContentPTBRTranslations,
  ...LandingPageDepartmentsContentPTBRTranslations,
  ...LandingPageHeroContentPTBRTranslations,
  ...LandingPageModulesContentPTBRTranslations,
  ...LandingPagePlansContentPTBRTranslations,
  ...LandingPageRoiContentPTBRTranslations,
  ...LandingPageVideoContentPTBRTranslations,
  ...MenuItemsPTBRTranslations,
  ...ProductDataPTBRTranslations,
  ...ProductModelsPTBRTranslations,
  ...ProductPlanningPTBRTranslations,
  ...ProductTestimonialsPTBRTranslations,
  ...DocumentationPagePTBRTranslations,
  ...PlansPTBRTranslations,
  ...CasesPagePTBRTranslations,
};

export const resources: { [language: string]: any } = {
  'en-US': {
    name: 'English',
    translation: enUSTranslations,
  },
  'pt-BR': {
    name: 'Portuguese - Brazil',
    translation: ptBRTranslations,
  },
};
