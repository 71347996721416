import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { Layout } from 'src/components/Layout';
import { Cases } from 'src/pages/Cases';
import { AutomobilesCase } from 'src/pages/Cases/AutomobilesCase';
import { FoodCase } from 'src/pages/Cases/FoodCase';
import { HygieneCase } from 'src/pages/Cases/HygieneCase';
import { LogisticsCase } from 'src/pages/Cases/LogisticsCase';
import { ContactSales } from 'src/pages/ContactSales';
import { DocumentationMenu } from 'src/pages/Documentation';
import { DocumentationContent } from 'src/pages/Documentation/components/Content';
import { FreemiumSignUp } from 'src/pages/Freemium/SignUp';
import { FreemiumUpgrade } from 'src/pages/Freemium/Upgrade';
import { Landing } from 'src/pages/Landing';
import { NotFound } from 'src/pages/NotFound';
import { Plans } from 'src/pages/Plans';
import { ProductEconomicData } from 'src/pages/Product/EconomicData';
import { ProductModels } from 'src/pages/Product/Models';
import { ProductPlanning } from 'src/pages/Product/Planning';

export function AppRoutes() {
  return (
    <Layout>
      <Routes>
        <Route path="/en?" element={<Landing />} />

        <Route path="/en?/cases" element={<Cases />} />
        <Route path="/en?/cases/automobiles" element={<AutomobilesCase />} />
        <Route path="/en?/cases/food" element={<FoodCase />} />
        <Route path="/en?/cases/hygiene-beauty" element={<HygieneCase />} />
        <Route path="/en?/cases/logistics" element={<LogisticsCase />} />

        <Route
          path="/en?/product/economic-data"
          element={<ProductEconomicData />}
        />
        <Route path="/en?/product/ai-models" element={<ProductModels />} />
        <Route
          path="/en?/product/integrated-planning"
          element={<ProductPlanning />}
        />

        <Route path="/en?/contact-sales" element={<ContactSales />} />

        <Route path="/en?/freemium/upgrade" element={<FreemiumUpgrade />} />
        <Route path="/en?/freemium/sign-up" element={<FreemiumSignUp />} />

        <Route path="/en?/help-center" element={<DocumentationMenu />} />
        <Route
          path="/help-center/:path?/:path?/:path"
          element={<DocumentationContent />}
        />

        <Route element={<NotFound />} path="*" />

        <Route path="/en?/plans" element={<Plans />} />
      </Routes>
    </Layout>
  );
}
