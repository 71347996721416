import styled from 'styled-components';

export const ModalFooter = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

  position: absolute;
  bottom: 0;

  padding: 1.5rem;

  background: ${({ theme }) => theme.colors.gray0};

  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 0 0 8px 8px;

  button + button {
    margin-left: 1.5rem;
  }

  .naked-button {
    padding: 0;

    color: ${({ theme }) => theme.colors.gray5};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
