export const LandingPageDepartmentsContentPTBRTranslations = {
  landingPageDepartmentSubtitle: 'EXPERIÊNCIA EM DIVERSOS SETORES',
  landingPageDepartmentTitle: 'Para desafios únicos da sua indústria',
  landingPageDepartmentDescription: 'Nossas soluções incluem:',

  landingPageDepartmentFoodTitle: 'Alimentos e bebidas',
  landingPageDepartmentFoodList1: 'Previsão de demanda',
  landingPageDepartmentFoodList2: 'Otimização de estoque',
  landingPageDepartmentFoodList3: 'Gerenciamento de Market Share',
  landingPageDepartmentFoodList4: 'Projeção de preço de commodities',
  landingPageDepartmentFoodList5: 'Análises de tendências',
  landingPageDepartmentFoodImageAlt: 'Imagem de exemplo de Alimentos e bebidas',

  landingPageDepartmentAgribusinessTitle: 'Agronegócio',
  landingPageDepartmentAgribusinessList1:
    'Projeção de produtividade durante a safra',
  landingPageDepartmentAgribusinessList2:
    'Projeção de preço de commodities agrícolas',
  landingPageDepartmentAgribusinessList3:
    'Acompanhamento do ritmo de colheita, plantio e sazonalidade',
  landingPageDepartmentAgribusinessList4:
    'Otimização do estoque e projeção de exportação',
  landingPageDepartmentAgribusinessList5:
    'Monitoramento de Market Share e insights sobre potencial de captura',
  landingPageDepartmentAgribusinessImageAlt: 'Imagem de exemplo de Agronegócio',

  landingPageDepartmentLogisticsTitle: 'Logística',
  landingPageDepartmentLogisticsList1: 'Projeção do preço do frete',
  landingPageDepartmentLogisticsList2: 'Projeção do preço do diesel',
  landingPageDepartmentLogisticsList3:
    'Projeção de oferta e demanda de produtos',
  landingPageDepartmentLogisticsList4:
    'Projeção da capacidade de armazenamento',
  landingPageDepartmentLogisticsList5:
    'Otimização do transporte através da melhor rota',
  landingPageDepartmentLogisticsImageAlt: 'Imagem de exemplo de Logística',

  landingPageDepartmentFinancialTitle: 'Financeiro',
  landingPageDepartmentFinancialList1:
    'Definição de indicadores de eficiência e sustentabilidade',
  landingPageDepartmentFinancialList2:
    'Diagnóstico de ofensores ou promotores do portfólio',
  landingPageDepartmentFinancialList3: 'Antecipação de risco de inadimplência',
  landingPageDepartmentFinancialList4:
    'Análise de cenários de oferta e restrição de crédito',
  landingPageDepartmentFinancialList5:
    'Segmentação e projeção do desempenho dos seus portfólios',
  landingPageDepartmentFinancialImageAlt: 'Imagem de exemplo de Financeiro',

  landingPageDepartmentPersonalCareTitle: 'Higiene e beleza',
  landingPageDepartmentPersonalCareList1:
    'Projeções de sell-out para o mercado e sua marca',
  landingPageDepartmentPersonalCareList2:
    'Gestão da ruptura de estoque nos pontos de venda',
  landingPageDepartmentPersonalCareList3:
    'Possibilidade de antecipação de movimentos de mercado',
  landingPageDepartmentPersonalCareList4:
    'Rastreabilidade das projeções de demanda',
  landingPageDepartmentPersonalCareList5:
    'Simulação do impacto de variações de preço no Market Share',
  landingPageDepartmentPersonalCareImageAlt:
    'Imagem de exemplo de Higiene e beleza',
};
