import React from 'react';

import anbimaLogo from 'src/assets/clients/anbima.svg';
import bayerLogo from 'src/assets/clients/bayer.svg';
import bradescoLogo from 'src/assets/clients/bradesco.svg';
import comgasLogo from 'src/assets/clients/comgas.svg';
import danoneLogo from 'src/assets/clients/danone.svg';
import enelLogo from 'src/assets/clients/enel.svg';
import hdiLogo from 'src/assets/clients/hdi.svg';
import heringLogo from 'src/assets/clients/hering.svg';
import jjLogo from 'src/assets/clients/johnson.svg';
import mdiasLogo from 'src/assets/clients/mdias.svg';
import vliLogo from 'src/assets/clients/vli.svg';
import volvoLogo from 'src/assets/clients/volvo.svg';

import { Container, Content } from './styles';

export function Clients() {
  return (
    <Container>
      <Content>
        <div>
          <img src={bradescoLogo} alt="Bradesco logo" />
          <img src={danoneLogo} alt="Danone logo" />
          <img src={hdiLogo} alt="HDI logo" />
          <img src={comgasLogo} alt="Comgas logo" />
          <img src={vliLogo} alt="VLI logo" />
          <img src={heringLogo} alt="Hering logo" />
          <img src={mdiasLogo} alt="M. Dias Branco logo" />
          <img src={anbimaLogo} alt="Anbima logo" />
          <img src={jjLogo} alt="Johnson & Johnson logo" />
          <img src={volvoLogo} alt="Volvo logo" />
          <img src={enelLogo} alt="Enel logo" />
          <img src={bayerLogo} alt="Bayer logo" />
        </div>

        <div>
          <img src={bradescoLogo} alt="Bradesco logo" />
          <img src={danoneLogo} alt="Danone logo" />
          <img src={hdiLogo} alt="HDI logo" />
          <img src={comgasLogo} alt="Comgas logo" />
          <img src={vliLogo} alt="VLI logo" />
          <img src={heringLogo} alt="Hering logo" />
          <img src={mdiasLogo} alt="M. Dias Branco logo" />
          <img src={anbimaLogo} alt="Anbima logo" />
          <img src={jjLogo} alt="Johnson & Johnson logo" />
          <img src={volvoLogo} alt="Volvo logo" />
          <img src={enelLogo} alt="Enel logo" />
          <img src={bayerLogo} alt="Bayer logo" />
        </div>
      </Content>
    </Container>
  );
}
