import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 7rem 7rem 0 7rem;

  > div:first-child {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth};
  }

  .react-tooltip {
    display: none;
    max-width: calc(100vw - 10rem);
  }

  .react-tooltip__place-left {
    > div {
      border-bottom: 0px !important;
      border-right: 0px !important;

      top: 50% !important;
      right: -3px !important;
    }
  }

  .react-tooltip__place-right {
    > div {
      border-bottom: 0px !important;
      border-right: 0px !important;

      top: 50% !important;
      left: -3px !important;
    }
  }

  .react-tooltip__place-top {
    > div {
      border-bottom: 0px !important;
      border-right: 0px !important;

      bottom: -4px !important;
    }
  }

  .react-tooltip__place-bottom {
    > div {
      border-top: 0px !important;
      border-left: 0px !important;

      top: -4px !important;
    }
  }

  @media (max-width: 1100px) {
    padding: 4rem 4rem 0 4rem;
  }

  @media (max-width: 615px) {
    padding: 2rem 0;

    .react-tooltip {
      display: flex;
      max-width: calc(100vw - 10rem);
    }
  }
`;

export const Background = styled.img`
  position: absolute;
  top: 0;

  width: 100vw;

  z-index: -1;
`;

export const Content = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};

  margin-bottom: 8rem;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;

  margin-top: 3rem;
  margin-bottom: 8rem;

  padding: 0 2rem;

  > div:first-of-type {
    button {
      border-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        background-color: ${({ theme }) => theme.colors.primaryDark};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 570px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 6rem;
  }
`;

export const PlanCard = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  background-color: ${({ theme }) => theme.colors.white};

  border: 1px ${({ theme }) => theme.colors.gray2} solid;
  border-radius: 0.5rem;

  text-align: center;

  padding: 1.5rem;

  h3 {
    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 1rem;
  }

  button {
    width: 100%;
    margin-top: auto;
  }
`;

export const TableContent = styled.div`
  margin-top: 8rem;
`;

export const PlansType = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    color: ${({ theme }) => theme.colors.gray6};

    font-size: 1.49rem;
    font-weight: 600;

    margin-bottom: 1rem;
  }

  button {
    width: 100%;
    font-size: 1rem;

    margin-top: auto;
    padding: 0.5rem 1rem;
  }
`;

export const Table = styled.table`
  padding: 0 1rem;

  background: white;

  border-collapse: separate;
  border-spacing: 1rem;

  text-align: center;

  margin-top: 3rem;

  table-layout: fixed;

  th {
    vertical-align: top;

    border-radius: 0.5rem;
    padding: 1rem;
  }

  th:first-of-type,
  td:first-of-type {
    width: 40%;

    background-color: white;
  }

  th:not(:first-of-type),
  td:not(:first-of-type) {
    width: 15%;

    button {
      white-space: nowrap;
    }
  }

  th:nth-child(2) {
    background-color: #f6f0fe;

    button {
      border-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        background-color: ${({ theme }) => theme.colors.primaryDark};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  th:nth-child(3) {
    background-color: #e9f2ff;
  }

  th:nth-child(4) {
    background-color: #fff1f7;
  }

  th:last-of-type {
    background-color: #e0f3e8;
  }

  td {
    background-color: ${({ theme }) => theme.colors.gray0};
    border-radius: 0.5rem;

    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1rem;
    font-weight: 400;

    padding: 0.75rem 1rem;

    svg {
      vertical-align: middle;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1rem;
    font-weight: 400;
  }

  td:first-of-type {
    background-color: ${({ theme }) => theme.colors.gray0};
  }
  td:nth-child(2) {
    background-color: ${({ theme }) => `${theme.colors.purple4}1F`};
  }
  td:nth-child(3) {
    background-color: ${({ theme }) => `${theme.colors.primary}1F`};
  }
  td:nth-child(4) {
    background-color: ${({ theme }) => `${theme.colors.secondary}1F`};
  }
  td:last-of-type {
    background-color: ${({ theme }) => `${theme.colors.green4}1F`};
  }

  tr {
    td:first-of-type {
      text-align: start;
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .table-explain {
    td {
      background: none;

      h2 {
        color: ${({ theme }) => theme.colors.gray6};
        font-size: 1.5rem;
      }

      p {
        color: ${({ theme }) => theme.colors.gray5};
        margin-top: 0.5rem;
      }
    }
  }

  .table-explain:not(:first-child) {
    td {
      padding-top: 3rem;
    }
  }

  .table-title {
    td {
      width: 100%;

      background-color: ${({ theme }) => theme.colors.gray0};

      font-size: 1.25rem;
      font-weight: 600;
      letter-spacing: 0.1rem;
      text-transform: uppercase;

      position: relative;
    }
  }

  .table-text {
    svg {
      display: none;
    }
  }

  @media (max-width: 1366px) {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  @media (max-width: 1100px) {
    border-spacing: 0.75rem;

    th:first-of-type {
      width: 40%;
    }
    th:nth-child(2) {
      width: 30%;
    }
    th:last-of-type {
      width: 30%;
    }
  }

  @media (max-width: 840px) {
    th,
    td {
      width: 30%;
    }

    th:not(:first-of-type),
    td:not(:first-of-type) {
      width: 17.5% !important;
    }

    th {
      height: 8.602rem;

      > div {
        height: 100%;

        h2 {
          height: calc(100% - 3rem - 2rem);

          align-content: center;
        }
      }

      h2 {
        white-space: pre-wrap;
      }

      button {
        white-space: pre-wrap !important;
      }
    }

    th:last-of-type {
      button {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    border-spacing: 0;

    thead {
      position: sticky;
      top: 5.8rem;
      z-index: 2;
    }

    th {
      h2 {
        font-size: 1rem;
        height: calc(100% - 2.375rem - 1rem - 0.75rem) !important;
      }

      button {
        font-size: 0.75rem;
      }
    }

    td,
    th {
      padding: 0.75rem;
      border: 0.25rem solid white;
    }

    .table-title {
      td {
        font-size: 1rem;
      }
    }

    .table-explain {
      h2 {
        font-size: 1.25rem !important;
      }
      p {
        font-size: 0.875rem !important;
      }
    }

    td {
      font-size: 0.875rem;
    }

    th:not(:first-of-type) {
      max-width: 10% !important;
      width: 10% !important;
    }
    th:first-of-type {
      max-width: 60% !important;
    }
  }

  @media (max-width: 615px) {
    th:not(:first-of-type),
    td:not(:first-of-type) {
      width: 4rem !important;

      padding: 0.75rem 0.5rem;

      overflow: hidden;
    }

    th:nth-child(2) {
      h2 {
        margin-left: 45%;
      }
    }

    th:nth-child(3) {
      h2 {
        margin-left: 45%;
      }
    }

    th:nth-child(5) {
      h2 {
        margin-left: 25%;
      }
    }

    th {
      div {
        align-content: center;
        width: 3rem !important;
      }

      h2 {
        transform: rotate(60deg);
        margin: auto 0;

        white-space: nowrap;

        width: fit-content;
      }

      button {
        display: none;
      }
    }

    .table-text {
      p {
        display: none;
      }

      svg {
        display: flex;

        margin: auto;
      }
    }
  }

  @media (max-width: 500px) {
    thead {
      position: sticky;
      top: 5.55rem;
      z-index: 2;
    }

    td,
    th {
      border: 0.125rem solid white;
    }

    th:nth-child(2) {
      h2 {
        margin-left: 0;
      }
    }

    th:nth-child(3) {
      h2 {
        margin-left: 0;
      }
    }

    th:nth-child(4) {
      h2 {
        margin-left: -40%;
      }
    }

    th:nth-child(5) {
      h2 {
        margin-left: -20%;
      }
    }
  }
`;
