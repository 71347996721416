import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';

import { Container, Icon, Information, Item, MenuContent } from './styles';
import { SubmenuProps } from './types';

export function Submenu({ menu, visible, ...props }: SubmenuProps) {
  const location = useLocation();
  const { t: translate } = useTranslation();

  return (
    <Container visible={visible} {...props}>
      <MenuContent>
        {menu.map((item) => (
          <Item
            key={item.id}
            href={item.url}
            target={
              item.id === 'blog' || item.id === 'api-documentation'
                ? '_blank'
                : '_self'
            }
            data-testid={`menu-${item.id}-link`}
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                `Menu mobile - ${translate(item.title, { lng: 'pt-BR' })}`,
                `Ir para a tela ${translate(item.title, { lng: 'pt-BR' })}`,
                location.pathname,
              );
            }}
          >
            <Icon color={item.color} data-testid={`menu-${item.id}-icon`}>
              {item.icon}
            </Icon>

            <Information>
              <h2 data-testid={`menu-${item.id}-title`}>
                {translate(item.title)}
              </h2>
              <p data-testid={`menu-${item.id}-description`}>
                {translate(item.description)}
              </p>
            </Information>
          </Item>
        ))}
      </MenuContent>
    </Container>
  );
}
