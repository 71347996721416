export const ProductModelsENUSTranslations = {
  productModelsHeroTitle: 'Create accurate forecasts quickly',
  productModelsHeroDescription:
    'Business planning involves analyzing historical data and future forecasting. You need to map risks, explore alternative scenarios, and anticipate events. Log in and get accurate forecasts instantly.',

  productModelsHeroButtonPrimary: 'Book a Demo',
  productModelsHeroButtonSecondary: 'See Essential plan',

  productModelsSection1Title: 'Up to 95% accuracy',
  productModelsSection1Description:
    'Using predictive AI, Models increase the accuracy of your forecasts. For anyone in strategic planning, S&OP or a specialist business unit, increasing your predictive accuracy is a game changer. Higher accuracy leads to better outcomes.',
  productModelsSection1ImageAlt: '',

  productModelsSection2Title: 'No coding or technical ability required',
  productModelsSection2Description:
    'Welcome to one-click modelling! Simply start your project, upload your data and choose your variables. The step-by-step guide enables non-technical users to create powerful forecasts. Instant results, no coding necessary.',
  productModelsSection2ImageAlt: '',

  productModelsSection3ImageAlt: '',
  productModelsSection3Title: 'Clearly explained outputs',
  productModelsSection3Description:
    'There’s little value creating models only for data scientists and technical teams. You need forecasts that can be understood and utilized by business or non-technical users. The outputs in Models solve this problem by providing you with clear insights using simple language and visuals.',

  productModelsSection4Title: 'Powered by predictive AI',
  productModelsSection4Description:
    'Humans can’t equal AI’s ability to identify patterns and make accurate predictions. A good analyst can manually build 5 models per day. Our algorithms search 50,000 models, select and test the best one, and have results ready in 30 minutes or less.',
  productModelsSection4ImageAlt: '',

  productModelsSection5ImageAlt: '',
  productModelsSection5Title: 'Combine macro and company data',
  productModelsSection5Description:
    'Your forecasting goes to the next level when you combine your internal company data (for example, previous sales or pricing) with the macro-economic data in the Feature Store. Generate new insights from the connections between external factors and your business performance.',

  productModelsSection6ImageAlt: '',
  productModelsSection6Title: 'Enterprise-grade security',
  productModelsSection6Description:
    'With the global average cost of a data breach at US$4.5million, you need the highest levels of information security and protection. Our investment in cloud-native security technologies (Google, Microsoft), practices and policies ensure your company’s data is completely secure.',

  productModelsContactSalesTitle:
    'Do you want to improve the accuracy of your forecasts?',
  productModelsContactSalesButton: 'Book a Demo',

  productModelsTestimonialsClient1Name: 'Gabriela Amaral',
  productModelsTestimonialsClient1JobDescription: 'Senior Procurement Manager',
  productModelsTestimonialsClient1Company: 'Danone',
  productModelsTestimonialsClient1Quote:
    '“At Danone, we need to plan demand. We need to know the volume we will sell to direct our factories how much to produce. The savings we’ve made using AI to predict demand effectively and avoid waste are huge. We’ve also used 4intelligence’s AI forecasting to predict important commodity costs for us more accurately, including milk and diesel.”',

  productModelsTestimonialsClient2Name: 'Allan Apter',
  productModelsTestimonialsClient2JobDescription:
    'Head of Marketing & Market Intelligence',
  productModelsTestimonialsClient2Company: 'ArcelorMittal Gonvarri',
  productModelsTestimonialsClient2Quote:
    '“As a steel service center, we need to make accurate predictions on how much raw material to buy, based on estimates of consumption. Previously, we hired consultancies for our forecasting. But every quarter, a new event happened and we needed to change the numbers. It was a very rear-view model. Today, with 4intelligence, we have a windshield model. We combine macroeconomic data with our own company information and market knowledge to accurately forecast demand and reduce operational delays.”',

  productModelsTestimonialsClient3Name: 'Luiz Bisneto',
  productModelsTestimonialsClient3JobDescription: 'Revenue Management Manager',
  productModelsTestimonialsClient3Company: 'M Dias Branco',
  productModelsTestimonialsClient3Quote:
    '“Understanding price elasticity in a granular way helped us design scenarios in which we were able to pass on the company’s costs efficiently, maintaining the volume projection. Despite implementing a price increase of more than 30%, we did not lose sales volume between one year and the next.”',
};
