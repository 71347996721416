import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  z-index: 100;

  padding: 1.5rem 7rem;

  background-color: transparent;
  transition: background-color 0.4s;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    height: 2rem;
  }

  nav {
    display: flex;
    flex-direction: row;

    gap: 2rem;
  }

  @media (max-width: 880px) {
    .header-nav,
    .language-button,
    .login-button,
    .contact-button {
      display: none !important;
    }
  }

  @media (max-width: 500px) {
    padding: 1.5rem 2rem;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

export const MenuContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;

  > nav > a {
    align-content: center;

    font-size: 1rem;
    line-height: 1.688rem;
    font-weight: 500;

    color: ${({ theme }) => theme.colors.gray6};

    transition: color 0.2s;

    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Tab = styled.button`
  position: relative;

  font-size: 1rem;
  line-height: 1.688rem;
  font-weight: 500;

  color: ${({ theme }) => theme.colors.gray6};

  transition: color 0.2s;

  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    svg {
      width: 1rem;
      height: 1rem;

      transition: transform 0.2s;
    }

    .hidden-menu {
      transform: rotate(180deg);
    }
  }
`;

export const ActionContent = styled.div`
  display: flex;
  flex-direction: row;

  gap: 2rem;

  .login-button,
  .contact-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: 1rem;
  }

  .login-button {
    background: transparent;
    color: ${({ theme }) => theme.colors.gray6};

    font-weight: 500;

    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .contact-button {
    padding: 1rem 1.5rem;

    background: ${({ theme }) => theme.colors.primary};
    border-radius: 8px;

    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;

    transition: all 0.2s;

    &:hover {
      background: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  .menu-button {
    display: none;
    background-color: ${({ theme }) => theme.colors.white};

    padding: 0.5rem;

    svg {
      width: 2rem;
      height: 2rem;

      margin: 0;
    }
  }

  @media (max-width: 1200px) {
    gap: 1.5rem;

    .menu-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 500px) {
    .menu-button {
      padding: 0.5rem 0.65rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
`;
