import React, { useEffect } from 'react';

import { init } from '@fullstory/browser';
import i18next from 'i18next';
import ReactGA from 'react-ga4';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Cookies } from './components/Cookies';
import { store } from './redux/store';
import { AppRoutes } from './routes';
import { queryClient } from './service/queryClient';
import './i18n/config.ts';

const FULL_STORY_ID = process.env.REACT_APP_FULLSTORY_ID;
const FULL_STORY_HOST = process.env.REACT_APP_FULLSTORY_HOST;

if (FULL_STORY_ID) {
  init({
    orgId: FULL_STORY_ID,
    host: FULL_STORY_HOST,
    script: `${FULL_STORY_HOST}/s/fs.js`,
  });
}

export function App(): React.ReactElement {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const language = currentPath.startsWith('/en') ? 'en-us' : 'pt-br';

    i18next.changeLanguage(language);
  }, []);

  ReactGA.initialize(
    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || 'G-FAKE',
  );

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
        <Cookies />
      </QueryClientProvider>
    </Provider>
  );
}
