export const ProductModelsPTBRTranslations = {
  productModelsHeroTitle: 'Crie previsões precisas rapidamente',
  productModelsHeroDescription:
    'O planejamento de negócios envolve analisar dados históricos e prever o futuro. Você precisa mapear os riscos, explorar cenários alternativos e antecipar eventos. Faça login e obtenha previsões precisas instantaneamente.',
  productModelsHeroButtonPrimary: 'Fale com vendas',
  productModelsHeroButtonSecondary: 'Conheça nossos planos',

  productModelsSection1Title: 'Até 95% de precisão',
  productModelsSection1Description:
    'Com o uso de IA preditiva, os modelos aumentam a precisão de suas previsões. Para qualquer pessoa em planejamento estratégico, S&OP ou uma unidade de negócios especializada, aumentar sua precisão preditiva é um grande diferencial. Uma maior precisão leva a melhores resultados.',
  productModelsSection1ImageAlt: '',

  productModelsSection2Title:
    'Sem necessidade de programação ou habilidade técnica',
  productModelsSection2Description:
    'Bem-vindo à modelagem com apenas um clique! Basta iniciar seu projeto, enviar seus dados e escolher suas variáveis. O guia passo a passo permite que usuários não técnicos criem previsões poderosas. Resultados instantâneos, sem necessidade de programação.',
  productModelsSection2ImageAlt: '',

  productModelsSection3ImageAlt: '',
  productModelsSection3Title: 'Resultados claramente explicados',
  productModelsSection3Description:
    'Não adianta criar modelos apenas para cientistas de dados e equipes técnicas. Você precisa de previsões que possam ser entendidas e utilizadas por usuários de negócios ou não técnicos. Nossos modelos resolvem esse problema fornecendo insights claros através de linguagem simples e visual.',

  productModelsSection4Title: 'Impulsionado por IA preditiva',
  productModelsSection4Description:
    'Humanos não podem igualar a capacidade da IA de identificar padrões e fazer previsões precisas. Um bom analista pode construir manualmente 5 modelos por dia. Nossos algoritmos procuram 50.000 modelos, selecionam e testam o melhor deles, e têm resultados prontos em 30 minutos ou menos.',
  productModelsSection4ImageAlt: '',

  productModelsSection5ImageAlt: '',
  productModelsSection5Title:
    'Possibilidade de combinar dados macroeconômicos com dados da empresa',
  productModelsSection5Description:
    'Suas previsões alcançam um novo patamar quando você combina seus dados internos da empresa (por exemplo, vendas anteriores ou preços) com os dados macroeconômicos disponíveis na Feature Store. Gere novos insights a partir das conexões entre fatores externos e o desempenho de seu negócio.',

  productModelsSection6ImageAlt: '',
  productModelsSection6Title: 'Segurança de nível empresarial',
  productModelsSection6Description:
    'Com o custo médio global de uma violação de dados em US$4,5 milhões, é essencial contar com os mais altos níveis de segurança e proteção de informações. Nosso investimento em tecnologias de segurança nativas da nuvem (Google, Microsoft), práticas e políticas garantem que os dados de sua empresa estejam completamente seguros.',

  productModelsContactSalesTitle:
    'Você quer melhorar a precisão das suas previsões?',
  productModelsContactSalesButton: 'Solicite uma demonstração',

  productModelsTestimonialsClient1Name: 'Gabriela Amaral',
  productModelsTestimonialsClient1JobDescription: 'Senior Procurement Manager',
  productModelsTestimonialsClient1Company: 'Danone',
  productModelsTestimonialsClient1Quote:
    '“Aqui na Danone, precisamos planejar a demanda. Precisamos saber o volume que venderemos para direcionar nossas fábricas sobre quanto produzir. As economias que obtivemos usando IA para prever a demanda de forma eficaz e evitar desperdícios são enormes. Também utilizamos as previsões de IA da 4intelligence para prever com mais precisão os custos de commodities importantes para nós, como leite e diesel.”',

  productModelsTestimonialsClient2Name: 'Allan Apter',
  productModelsTestimonialsClient2JobDescription:
    'Head of Marketing & Market Intelligence',
  productModelsTestimonialsClient2Company: 'ArcelorMittal Gonvarri',
  productModelsTestimonialsClient2Quote:
    '“Como um centro de serviços de aço, precisamos fazer previsões precisas sobre a quantidade de matéria-prima a ser comprada, com base nas estimativas de consumo. Anteriormente, contratávamos consultorias para fazer nossas previsões, mas eventos inesperados nos obrigavam a revisar os números frequentemente, utilizando um modelo retroativo. Hoje, com a 4intelligence, temos um modelo proativo. Combinamos dados macroeconômicos com informações internas da empresa e conhecimento de mercado para prever a demanda com precisão e reduzir os atrasos operacionais.”',

  productModelsTestimonialsClient3Name: 'Luiz Bisneto',
  productModelsTestimonialsClient3JobDescription: 'Revenue Management Manager',
  productModelsTestimonialsClient3Company: 'M Dias Branco',
  productModelsTestimonialsClient3Quote:
    '“Compreender a elasticidade-preço de maneira detalhada nos ajudou a criar cenários nos quais conseguimos repassar os custos da empresa de forma eficiente, mantendo a projeção de volume. Apesar de implementar um aumento de preço de mais de 30%, não perdemos volume de vendas de um ano para o outro.”',
};
