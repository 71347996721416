import React from 'react';

import ReactSelect from 'react-select';
import { Label } from 'src/components/Label';
import { Skeleton } from 'src/components/Skeleton';

import { ContainerLabel, ContainerSelect } from './styles';
import { SelectProps } from './types';

export function Select({
  label,
  style,
  className,
  placeholder,
  isMulti = false,
  isLoading = false,
  ...rest
}: SelectProps) {
  if (!isLoading)
    return (
      <div
        style={style}
        className={className}
        data-cy={
          label?.replaceAll(' ', '-').toLocaleLowerCase() ??
          placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase() ??
          undefined
        }
        data-testid={
          label?.replaceAll(' ', '-').toLocaleLowerCase() ??
          placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase() ??
          undefined
        }
      >
        {label ? (
          <ContainerLabel>
            <Label
              htmlFor={
                `react-select-${label
                  ?.replaceAll(' ', '-')
                  .toLocaleLowerCase()}` ??
                placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase()
              }
              text={label}
            />
          </ContainerLabel>
        ) : null}

        <ContainerSelect isMulti={isMulti}>
          <ReactSelect
            name={
              `react-select-${label
                ?.replaceAll(' ', '-')
                .toLocaleLowerCase()}` ??
              placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase()
            }
            inputId={
              `react-select-${label
                ?.replaceAll(' ', '-')
                .toLocaleLowerCase()}` ??
              placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase()
            }
            placeholder={placeholder}
            instanceId={
              label?.replaceAll(' ', '-').toLocaleLowerCase() ??
              placeholder
                ?.toString()
                ?.replaceAll(' ', '-')
                .toLocaleLowerCase() ??
              undefined
            }
            isMulti={isMulti}
            noOptionsMessage={() => 'Sem opções'}
            classNamePrefix="select"
            {...rest}
          />
        </ContainerSelect>
      </div>
    );

  return (
    <div
      style={style}
      className={className}
      data-cy={`loading-${
        label?.replaceAll(' ', '-').toLocaleLowerCase() ??
        placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase() ??
        undefined
      }`}
      data-testid={`loading-${
        label?.replaceAll(' ', '-').toLocaleLowerCase() ??
        placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase() ??
        undefined
      }`}
    >
      <ContainerLabel>
        <Label
          htmlFor={
            `react-select-${label?.replaceAll(' ', '-').toLocaleLowerCase()}` ??
            placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase()
          }
          text={label ?? ''}
        />
      </ContainerLabel>

      <Skeleton withLoading={false} style={{ height: '3.188rem' }} />
    </div>
  );
}
