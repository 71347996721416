import styled from 'styled-components';

export const Description = styled.div`
  max-width: 55rem;

  margin-top: 2rem;

  p {
    font-size: 1.5rem;
    line-height: 2.4rem;
    font-weight: 400;

    text-align: center;

    color: ${({ theme }) => theme.colors.gray5};
  }

  p + p {
    margin-top: 1rem;
  }

  @media (max-width: 1400px) {
    p {
      font-size: 1.25rem;
      line-height: 2.15rem;
    }
  }

  @media (max-width: 768px) {
    width: 70%;

    p {
      font-size: 1rem;
      line-height: 1.75rem;
    }
  }
`;
