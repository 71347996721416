import React from 'react';

import { ButtonStyled, Spinner } from './styles';
import { ButtonProps } from './types';

export function Button({
  buttonType,
  icon,
  children,
  disabled = false,
  loading = false,
  iconInvert = false,
  ...props
}: ButtonProps) {
  return (
    <ButtonStyled
      type="button"
      buttonType={buttonType}
      disabled={disabled}
      iconInvert={iconInvert}
      {...props}
    >
      {iconInvert === true ? (
        <div>
          {children}
          {loading && (
            <Spinner
              hasIcon={!!icon}
              spinnerType={buttonType}
              data-testid="button-spinner"
              data-cy="button-spinner"
            />
          )}
          {icon}
        </div>
      ) : (
        <div>
          {icon}
          {children}
          {loading && (
            <Spinner
              hasIcon={!!icon}
              spinnerType={buttonType}
              data-testid="button-spinner"
              data-cy="button-spinner"
            />
          )}
        </div>
      )}
    </ButtonStyled>
  );
}
