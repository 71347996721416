import React from 'react';

import { Loading } from 'src/components/Loading';

import { Container } from './styles';
import { SkeletonProps } from './types';

export function Skeleton({
  boxShadow = false,
  withLoading = true,
  ...rest
}: SkeletonProps) {
  return (
    <Container hasLoading={withLoading} boxShadow={boxShadow} {...rest}>
      {withLoading && (
        <Loading
          className="loading"
          style={{ width: '70px', height: '70px' }}
        />
      )}
    </Container>
  );
}
