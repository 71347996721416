export const CasesPageENUSTranslations = {
  casesPageHeadDescription:
    '4intelligence, your company’s great ally when it comes to making precise decisions',

  casesPageAutomobilesHeadDescription:
    'Find out how 4intelligence helped a car manufacturer increase truck sales by 45.7%',

  casesPageFoodHeadDescription:
    'Find out how 4intelligence helped a food company increase its revenue by 26%',

  casesPageHygieneHeadDescription:
    'Find out how 4intelligence helped a consumer goods industry to reduce S&OP bureaucracy',

  casesPageLogisticsHeadDescription:
    'Find out how 4intelligence helped a logistics industry increase its total cargo transported by 10%',

  casesPageRetailHeadDescription:
    'Find out how 4intelligence helped a major retailer expand its store network across Brazil',
};
