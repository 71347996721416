export function scrollTo(
  toTop: boolean,
  targetScroll: number,
  interval: number,
) {
  function scroll() {
    const currentScroll =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;

    if (
      (toTop && currentScroll > targetScroll) ||
      (!toTop && currentScroll < targetScroll)
    ) {
      window.requestAnimationFrame(scroll);

      const y = toTop ? currentScroll - interval : currentScroll + interval;

      window.scrollTo(0, y < 0 ? 0 : y);
    }
  }

  scroll();
}
