import styled, { css, keyframes } from 'styled-components';

interface ContainerProps {
  boxShadow: boolean;
  hasLoading?: boolean;
}

const shimmer = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 18.75rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  background-size: 400%, 400%;

  ${({ hasLoading, theme }) =>
    hasLoading
      ? css`
          background: #fff;
        `
      : css`
          background-image: linear-gradient(
            -90deg,
            #fff 0%,
            var(--light-grey) 50%,
            #fff 100%
          );

          background-image: linear-gradient(
            -90deg,
            ${theme.colors.white} 0%,
            ${theme.colors.gray3} 50%,
            ${theme.colors.white} 100%
          );
        `}

  box-shadow: ${({ boxShadow }) =>
    boxShadow ? '0px 1px 3px rgba(0, 0, 0, 0.12)' : 'none'};

  ${({ hasLoading }) =>
    !hasLoading &&
    css`
      animation: ${shimmer} 1.2s ease-in-out infinite;
    `};

  svg {
    width: 4rem;
    height: 4rem;
  }
`;
