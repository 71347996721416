import styled, { css } from 'styled-components';

type ContainerProps = {
  animation: boolean;
  visible: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 31rem;
  max-width: 100%;
  height: 100dvh;

  padding: 2rem 3rem;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: -10px 0px 25px 0px rgba(0, 0, 0, 0.1);

  transform: translate3d(35rem, 0, 0);

  ${({ animation, visible }) =>
    animation &&
    css`
      animation: ${visible ? 'show' : 'hidden'} 1s forwards;
    `}

  @keyframes show {
    from {
      transform: translate3d(35rem, 0, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes hidden {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(35rem, 0, 0);
    }
  }

  @media (max-width: 500px) {
    width: 29rem;

    padding: 1.5rem 1.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 4rem;

  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

  > img {
    height: 2rem;
  }

  .close-button {
    padding: 0;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      color: ${({ theme }) => theme.colors.gray5};

      transition: all 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.gray7};
      }
    }
  }
`;

export const Menu = styled.div`
  flex-grow: 1;

  overflow-y: auto;

  button + button {
    margin-top: 2.5rem;
  }
`;

export const MenuItem = styled.button`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;

    color: ${({ theme }) => theme.colors.gray6};

    cursor: pointer;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      transition: all 0.2s;
    }

    transition: all 0.2s;

    .hidden-menu {
      transform: rotate(180deg);
    }

    :hover {
      color: ${({ theme }) => theme.colors.gray8};
    }

    @media (max-width: 500px) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`;

export const ActionContent = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: nowrap;

  gap: 1.5rem;

  background: ${({ theme }) => theme.colors.white};

  padding-top: 1.5rem;
  margin-top: 1.5rem;

  border-top: 1px solid ${({ theme }) => theme.colors.gray2};

  .mobile-login-button,
  .mobile-contact-button {
    height: 2.813rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: 0.875rem;
    font-weight: 600;
  }

  .mobile-login-button {
    background: transparent;
    color: ${({ theme }) => theme.colors.gray5};

    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.gray7};
    }
  }

  .mobile-contact-button {
    padding: 0.75rem 1.25rem;

    background: ${({ theme }) => theme.colors.primary};
    border-radius: 8px;

    color: ${({ theme }) => theme.colors.white};

    transition: all 0.2s;

    &:hover {
      background: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  .mobile-language-button {
    > div {
      top: -3.75rem !important;
    }

    @media (max-width: 500px) {
      > div {
        top: -8.5rem !important;
      }
    }
  }
`;
