export const PlansPTBRTranslations = {
  plansPageHeadDescription:
    'Conheça nossos planos. Veja qual plano funciona melhor para o seu negócio.',

  plansPageTitle: 'Planos',
  plansPageTableTitle: 'Veja como nossos planos se comparam',

  plansPageFreeDescription:
    'Para um único usuário que precisa acessar gratuitamente uma quantidade limitada de dados econômicos.',
  plansPageFreeButton: 'Comece grátis',

  plansPageStarterDescription:
    'Para um único usuário que precisa acessar uma ampla variedade de dados e projeções econômicas.',
  plansPageStarterButton: 'Fale conosco',

  plansPageEssentialDescription:
    'Para um único usuário que necessita de dados econômicos e um ambiente de prototipagem de modelo, incluindo configuração e suporte individual.',
  plansPageEssentialButton: 'Fale conosco',

  plansPageBusinessDescription:
    'Para vários usuários que precisam de tudo no plano Essential, com recursos adicionais de colaboração, governança e suporte avançado. ',
  plansPageBusinessButton: 'Fale conosco',

  plansExplainDataTitle: 'Economic Data',
  plansExplainDataDescription:
    'Repositório de dados 4intelligence com indicadores de diversas fontes públicas e privadas em um só local, com histórico e projeções desses dados.',

  plansPageDataRow0Name: 'Feature Store',
  plansPageDataRow1Name: 'Acesso à mais de 2.000 indicadores e 130.000 séries',
  plansPageDataRow1Free: 'Limitado',
  plansPageDataRow2Name: 'Alertas de novas divulgações',
  plansPageDataRow3Name: 'Alertas de novas projeções',
  plansPageDataRow4Name: 'Chat4i',
  plansPageDataRow5Name: 'Plug-in para o Excel',
  plansPageDataRow6Name:
    'Criação de grupos de indicadores customizados para acompanhamento',
  plansPageDataRow7Name: 'Compartilhamento de grupos e features',
  plansPageDataRow8Name: 'Rest API para construção de pipelines automatizados',
  plansPageDataRow9Name: 'Exportação de dados em formatos tabulares',
  plansPageDataRow10Name: 'Diferentes fontes e cenários de projeção',
  plansPageDataRow11Name: 'Relatórios econômicos analíticos',

  plansExplainModelsTitle: 'AI Models',
  plansExplainModelsDescription:
    'Atividades essenciais para obtenção de modelos preditivos acurados de forma automática.',

  plansPageModelsRow0Name: 'Models',
  plansPageModelsRow1Name: 'Descoberta automática de features (Pick4me)',
  plansPageModelsRow2Name: 'Modelos de Séries de Tempo',
  plansPageModelsRow3Name: 'Modelos de Classificação',
  plansPageModelsRow4Name: 'Composição de Modelos',
  plansPageModelsRow5Name: 'Modelagem de múltiplas séries simultâneas',
  plansPageModelsRow6Name: 'Atualização de modelos',
  plansPageModelsRow7Name: 'Construção de cenários',
  plansPageModelsRow8Name: 'Explicabilidade de resultados e projeções',
  plansPageModelsRow9Name: 'Limite de modelagens por ano',
  plansPageModelsRow9Essential: '120 ou 600',
  plansPageModelsRow9Business: 'Ilimitado',

  plansExplainPlanningTitle: 'Integrated Planning',
  plansExplainPlanningDescription:
    'Funcionalidades críticas para compartilhamento e utilização prática das projeções em ambientes de negócio, permitindo o acompanhamento de seu ciclo de vida  e governança sob modificações de cenários.',

  plansPagePlanningRow0Name: 'Workspaces',
  plansPagePlanningRow1Name: 'Compartilhamento com outros usuários',
  plansPagePlanningRow2Name: 'Permissionamento por usuário',
  plansPagePlanningRow3Name:
    'Compatibilização de séries multi-nível (top down, middle out e bottom up)',
  plansPagePlanningRow4Name: 'Layouts de visualização customizados',
  plansPagePlanningRow5Name:
    'Dashboard com insights que facilitam a tomada de decisão',
  plansPagePlanningRow6Name: 'Segmentação dos dados - Curva ABC',
  plansPagePlanningRow7Name:
    'Formatação e inclusão de diferentes tipos de dados',
  plansPagePlanningRow8Name:
    'Tree View com até 10 níveis hierárquicos para categorização de séries',
  plansPagePlanningRow9Name:
    'Inclusão de séries externas (modeladas fora do módulo AI Models)',
  plansPagePlanningRow10Name: 'Explicabilidade de resultados e projeções',
  plansPagePlanningRow11Name: 'Governança',
  plansPagePlanningRow12Name: 'Criação de Fluxos de Planejamento',
  plansPagePlanningRow13Name:
    'Aprovação hierárquica dos Ciclos de Planejamento',
  plansPagePlanningRow14Name: 'Horizontes de planejamentos customizáveis',
  plansPagePlanningRow15Name: 'Acompanhamento de Acurácia',
  plansPagePlanningRow16Name: 'Histórico de versões dos Ciclos de Planejamento',
  plansPagePlanningRow17Name: 'Calendarização de atividades por etapas',
  plansPagePlanningRow18Name:
    'Rastreabilidade das alterações e ajustes de dados',
  plansPagePlanningRow19Name: 'Report automático de compliance',

  plansExplainEnvironmentTitle: 'Ecossistema (Utilização e Segurança)',

  plansPageEnvironmentRow0Name: 'Rest APIs para Modelagem',
  plansPageEnvironmentRow1Name: 'SSO',
  plansPageEnvironmentRow2Name: 'Criptografia automática de todos os dados',
  plansPageEnvironmentRow3Name: 'Suporte',
  plansPageEnvironmentRow3Starter: '9x5 por Email SLA de resposta: 24 horas',
  plansPageEnvironmentRow3Essential: '9x5 por Email SLA de resposta: 24 horas',
  plansPageEnvironmentRow3Business:
    '9x5 por Email e Teams SLA de Resposta: 4 horas',
  // plansPageEnvironmentRow4Name: 'Atendimento',
  // plansPageEnvironmentRow4Starter:
  //   'Atendimento por email para retirar dúvidas da Plataforma 4intelligence, módulo "Feature Store"',
  // plansPageEnvironmentRow4Essential:
  //   'Atendimento por email para retirar dúvidas da Plataforma 4intelligence, módulos "Feature Store" e "Models"',
  // plansPageEnvironmentRow4Business:
  //   'Atendimento para retirada de dúvidas da Plataforma 4intelligence, na modalidade de contratação "Autosserviço", para os clientes que optam por construir seus próprios modelos preditivos + suporte de cientistas de dados, econometristas e especialistas setoriais, na modalidade de contratação "Assisted", para os clientes que optam que o time 4intelligence faça a construção dos modelos preditivos e o acompanhamento dos ciclos de vida',

  expandRow0Title: 'Abrir/fechar feature store',
};
