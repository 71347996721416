import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 6.5625rem - 46px - 3rem - 2rem) !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 880px) {
    height: auto !important;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;

  width: 100% !important;
  height: 100vh;

  background: linear-gradient(180deg, #cad9fc 0%, #fff 70%);

  @media (max-width: 880px) {
    height: 480px;
  }
`;

export const Information = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1.25fr 1fr;
  grid-gap: 6rem;

  padding-top: 1rem;

  @media (max-width: 880px) {
    grid-template-columns: 1fr;
    grid-gap: 4rem;
  }
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: start;

  h1 {
    font-weight: 600;
    white-space: nowrap;
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    width: 480px;

    font-size: 1.25rem;
    line-height: 2.25rem;
    font-weight: 400;

    color: ${({ theme }) => theme.colors.gray6};

    margin: 2rem 0;
  }

  button {
    background-color: ${({ theme }) => theme.colors.secondary};

    &:hover {
      background-color: ${({ theme }) => theme.colors.secondaryDark};
    }
  }

  @media (max-width: 880px) {
    align-items: center;
    text-align: center;

    p {
      width: 92%;
    }
  }

  @media (max-width: 500px) {
    width: calc(100vw - 4rem);
  }
`;

export const ImageContainer = styled.div`
  height: 100%;

  display: flex;
  align-items: center;

  > img {
    width: 100%;
    height: 100%;
    max-height: 600px;
  }

  @media (max-width: 880px) {
    img {
      display: none;
    }
  }
`;
