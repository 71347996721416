import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 14.938rem);

  overflow-y: auto;

  padding: 1.5rem;
  margin-top: 1.5rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  p,
  li,
  a,
  b {
    font-size: 1rem;
    font-weight: 400;
    line-height: 160%;

    color: ${({ theme }) => theme.colors.gray5};
  }

  a {
    color: ${({ theme }) => theme.colors.primary};

    text-decoration-line: underline;
    text-underline-offset: 0.25rem;
  }

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
  }

  u {
    text-underline-offset: 0.25rem;
  }

  b {
    color: ${({ theme }) => theme.colors.gray6};
    font-weight: 600;
  }

  b + p {
    margin-top: 1rem;
  }

  p + p {
    margin-top: 0.5rem;
  }

  > ul {
    list-style: initial;

    margin-top: 0.5rem;

    ul {
      list-style: circle;

      margin-left: 1.5rem;
    }
  }

  li {
    list-style-position: inside;
  }

  li + li {
    margin-top: 0.25rem;
  }

  ul + p {
    margin-top: 0.5rem;
  }

  @media (max-width: 1366px) {
    p,
    li,
    a,
    b {
      font-size: 0.875rem;
    }
  }
`;
