import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10rem;

  height: fit-content;
  min-height: 65vh;

  background: ${({ theme }) => theme.colors.gray0};

  padding: 5rem 15rem;

  > div {
    width: 50%;
  }

  .info-divider {
    display: none;
  }

  @media (max-width: 1366px) {
    width: 100%;
    padding: 4rem 6.5rem;
  }

  @media (max-width: 1200px) {
    padding: 4rem 5rem;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    > div {
      width: 70%;
    }

    .info-divider {
      display: block;

      margin: 1.5rem 0 !important;
    }

    .contact-divider {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 4rem 3rem;

    > div {
      width: 90%;
    }
  }

  @media (max-width: 500px) {
    padding: 3rem 1.5rem;

    > div {
      width: 100%;
    }
  }
`;

export const LeftContent = styled.div`
  h2 {
    width: 100%;

    font-size: 1.75rem;
    font-weight: 600;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray7};

    margin-bottom: 1rem;
  }

  > p {
    width: 80%;

    font-size: 1.125rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};
  }

  > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    width: fit-content;
    height: fit-content;

    padding: 1rem 2rem;

    background: ${({ theme }) => `${theme.colors.green4}29`};
    border-radius: 8px;

    color: ${({ theme }) => theme.colors.green4};

    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    margin-top: 1.5rem;

    svg {
      width: 2rem;
      height: 2rem;
    }

    &:hover {
      background: ${({ theme }) => `${theme.colors.green4}40`};
    }
  }

  @media (max-width: 1366px) {
    > p {
      font-size: 1rem;
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    > div {
      text-align: start;
    }

    > a {
      margin-bottom: 2.5rem;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  border: 1px solid ${({ theme }) => theme.colors.gray2};

  margin: 2.25rem 0;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: 1366px) {
    gap: 2rem;
  }

  @media (max-width: 1100px) {
    gap: 1.5rem;
  }
`;

export const Card = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  padding: 2.75rem 2.25rem;

  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  box-shadow: 0px 12px 24px 0px rgba(160, 174, 192, 0.16);

  p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6875rem;
    color: ${({ theme }) => theme.colors.gray5};
  }

  @media (max-width: 1366px) {
    p {
      font-size: 1rem;
    }
  }

  @media (max-width: 1100px) {
    padding: 3rem;
  }

  @media (max-width: 500px) {
    padding: 2rem;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.25rem;

  @media (max-width: 600px) {
    gap: 1.5rem;
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: fit-content;

  padding: 1rem;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background: linear-gradient(
      0deg,
      rgba(226, 232, 240, 0.56) 0%,
      rgba(226, 232, 240, 0.56) 100%
    ),
    ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 16px 0px rgba(203, 213, 224, 0.48);

  img {
    width: 2.625rem;
    height: 2.625rem;
  }

  @media (max-width: 600px) {
    padding: 0.5rem;

    img {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const FreemiumInformation = styled.div`
  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.25rem;
  }

  @media (max-width: 600px) {
    h3 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`;

export const Tag = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 1.375rem;

  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: column;
  padding: 0.375rem 0.75rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  p {
    font-size: 0.625rem;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.05rem;
    text-transform: uppercase;

    color: ${({ theme }) => theme.colors.gray4};
  }

  @media (max-width: 1000px) {
    right: 3rem;
  }

  @media (max-width: 500px) {
    right: 0.75rem;
    top: 0.75rem;
  }
`;

export const PlatformInformation = styled.div`
  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.primary};

    margin-bottom: 0.25rem;
  }

  @media (max-width: 600px) {
    h3 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`;

export const PlatformList = styled.div`
  margin-top: 2.5rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      color: ${({ theme }) => theme.colors.primary};
    }

    p {
      flex: 1;
    }
  }

  > div + div {
    margin-top: 1.75rem;
  }

  @media (max-width: 1100px) {
    margin-top: 2.25rem;

    > div + div {
      margin-top: 1.5rem;
    }
  }

  @media (max-width: 600px) {
    > div {
      gap: 1rem;
    }
  }
`;
