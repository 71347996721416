import styled, { css } from 'styled-components';

type ContainerProps = {
  isOpen: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: none;

  margin-bottom: 2rem;

  text-align: center;

  > div {
    background-color: ${({ theme }) => theme.colors.gray0};

    border: 1px solid ${({ theme }) => theme.colors.gray2};
    border-radius: 8px;

    padding: 1rem;

    > button {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      color: ${({ theme }) => theme.colors.gray7};
      font-size: 1rem;

      svg {
        width: 1rem;
        height: 1rem;

        transition: all 0.2s;

        rotate: ${({ isOpen }) => (isOpen ? '-180deg' : '0deg')};
      }
    }

    > ul {
      margin-top: 1rem;
    }
  }

  .first-level {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;

    font-size: 0.875rem;
    line-height: 2rem;
    font-weight: 600;

    text-transform: uppercase;
    letter-spacing: 0.08em;

    color: ${({ theme }) => theme.colors.primary} !important;

    &:hover {
      color: ${({ theme }) => theme.colors.primary} !important;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  @media (max-width: 900px) {
    display: block;
  }
`;

type MenuItemProps = {
  selected: boolean;
  isLastLevel: boolean;
  isOpen: boolean;
};

export const MenuItem = styled.li<MenuItemProps>`
  ${({ selected, isLastLevel, isOpen, theme }) => css`
    > button {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      text-align: start;
      gap: 1rem;

      color: ${selected && isLastLevel
        ? theme.colors.primary
        : selected
        ? theme.colors.gray7
        : theme.colors.gray6} !important;

      background: ${selected && isLastLevel
        ? 'rgba(76, 148, 255, 0.12)'
        : 'transparent'};
      border-radius: 5px;
      padding: ${isLastLevel ? '0.5rem 1rem' : '0.25rem 0'};

      font-weight: ${selected ? 600 : 400};

      transition: all 0.2s;

      svg {
        width: 1rem;
        height: 1rem;

        transition: all 0.2s;

        rotate: ${isOpen ? '-180deg' : '0deg'};
      }

      &:hover {
        color: ${selected && isLastLevel
          ? theme.colors.primary
          : theme.colors.gray8} !important;
      }
    }
  `}

  > ul {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  }
`;
