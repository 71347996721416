import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import section1ImageENUS from 'src/assets/product/models/1-en.svg';
import section1ImagePTBR from 'src/assets/product/models/1-pt.svg';
import section2ImageENUS from 'src/assets/product/models/2-en.svg';
import section2ImagePTBR from 'src/assets/product/models/2-pt.svg';
import section3Background from 'src/assets/product/models/3-background.svg';
import section3VectorENUS from 'src/assets/product/models/3-en.svg';
import section3Image from 'src/assets/product/models/3-image.png';
import section3VectorPTBR from 'src/assets/product/models/3-pt.svg';
import section4ImageENUS from 'src/assets/product/models/4-en.svg';
import section4ImagePTBR from 'src/assets/product/models/4-pt.svg';
import section5VectorENUS from 'src/assets/product/models/5-en.svg';
import section5Image from 'src/assets/product/models/5-image.png';
import section5VectorPTBR from 'src/assets/product/models/5-pt.svg';
import section6Image from 'src/assets/product/models/6.svg';
import heroBackground from 'src/assets/product/models/hero-background.svg';
import heroImageENUS from 'src/assets/product/models/hero-en.svg';
import heroImagePTBR from 'src/assets/product/models/hero-pt.svg';
import client1Image from 'src/assets/product/models/testimonials/client1.png';
import client2Image from 'src/assets/product/models/testimonials/client2.png';
import client3Image from 'src/assets/product/models/testimonials/client3.png';
import { ContactSales } from 'src/components/ContactSales';
import { Head } from 'src/components/Head';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';
import { useTheme } from 'styled-components';

import {
  Container,
  Content,
  Hero,
  HeroContent,
  HeroButtons,
  Row,
  BackgroundSection,
  BackgroundImageSection,
  HeroBackground,
} from '../components/Module/styles';
import { Testimonials } from '../components/Testimonials';
import { VectorImage } from '../components/VectorImage';

export function ProductModels() {
  const { t: translate } = useTranslation();
  const { urlPrefix, language } = useSelector(
    (state: RootState) => state.config,
  );
  const location = useLocation();
  const theme = useTheme();

  return (
    <Container>
      <Head
        title={`${translate('productModels')} | 4intelligence`}
        description={translate('productModelsHeroDescription') ?? ''}
      />

      <Content>
        <Hero backgroundType="image">
          <HeroBackground src={heroBackground} alt="hero background" />

          <HeroContent>
            <div>
              <Title>
                <h2>{translate('productModelsHeroTitle')}</h2>
              </Title>

              <p>{translate('productModelsHeroDescription')}</p>

              <HeroButtons
                color={theme.colors.secondary}
                hover={theme.colors.secondaryDark}
              >
                <Link
                  to={`${urlPrefix}/contact-sales`}
                  onClick={() => {
                    TrackGoogleAnalyticsEvent(
                      'Falar com vendas',
                      'Ir para tela Falar com Vendas',
                      location.pathname,
                    );
                  }}
                >
                  {translate('productModelsHeroButtonPrimary')}
                </Link>
                <Link
                  to={`${urlPrefix}/plans`}
                  onClick={() => {
                    TrackGoogleAnalyticsEvent(
                      'Ver planos',
                      'Ir para tela de planos',
                      location.pathname,
                    );
                  }}
                >
                  {translate('productModelsHeroButtonSecondary')}
                </Link>
              </HeroButtons>
            </div>

            <img
              src={language === 'pt-br' ? heroImagePTBR : heroImageENUS}
              alt={translate('productModelsSection1ImageAlt') ?? ''}
            />
          </HeroContent>
        </Hero>

        <Row>
          <img
            src={language === 'pt-br' ? section1ImagePTBR : section1ImageENUS}
            alt={translate('productModelsSection1ImageAlt') ?? ''}
          />

          <div>
            <Title>
              <h2>{translate('productModelsSection1Title')}</h2>
            </Title>
            <p>{translate('productModelsSection1Description')}</p>
          </div>
        </Row>

        <Row>
          <div>
            <Title>
              <h2>{translate('productModelsSection2Title')}</h2>
            </Title>
            <p>{translate('productModelsSection2Description')}</p>
          </div>

          <img
            src={language === 'pt-br' ? section2ImagePTBR : section2ImageENUS}
            alt={translate('productModelsSection2ImageAlt') ?? ''}
          />
        </Row>

        <BackgroundImageSection
          color={theme.colors.secondary}
          style={{ backgroundImage: `url(${section3Background})` }}
        >
          <Row>
            <VectorImage
              over={section3Image}
              under={
                language === 'pt-br' ? section3VectorPTBR : section3VectorENUS
              }
              alt={translate('productModelsSection3ImageAlt') ?? ''}
            />

            <div>
              <Title>
                <h2>{translate('productModelsSection3Title')}</h2>
              </Title>
              <p>{translate('productModelsSection3Description')}</p>
            </div>
          </Row>
        </BackgroundImageSection>

        <Row>
          <div>
            <Title>
              <h2>{translate('productModelsSection4Title')}</h2>
            </Title>
            <p>{translate('productModelsSection4Description')}</p>
          </div>

          <img
            src={language === 'pt-br' ? section4ImagePTBR : section4ImageENUS}
            alt={translate('productModelsSection4ImageAlt') ?? ''}
          />
        </Row>

        <Row>
          <VectorImage
            under={section5Image}
            over={
              language === 'pt-br' ? section5VectorPTBR : section5VectorENUS
            }
            alt={translate('productModelsSection5ImageAlt') ?? ''}
          />

          <div>
            <Title>
              <h2>{translate('productModelsSection5Title')}</h2>
            </Title>
            <p>{translate('productModelsSection5Description')}</p>
          </div>
        </Row>

        <BackgroundSection color={theme.colors.secondary}>
          <Row>
            <div>
              <Title>
                <h2>{translate('productModelsSection6Title')}</h2>
              </Title>
              <p>{translate('productModelsSection6Description')}</p>
            </div>

            <img
              src={section6Image}
              alt={translate('productModelsSection6ImageAlt') ?? ''}
            />
          </Row>
        </BackgroundSection>

        <Testimonials
          dotsColor={theme.colors.secondary}
          testimonials={[
            {
              name: translate('productModelsTestimonialsClient1Name'),
              jobDescription: translate(
                'productModelsTestimonialsClient1JobDescription',
              ),
              company: translate('productModelsTestimonialsClient1Company'),
              image: client1Image,
              quote: translate('productModelsTestimonialsClient1Quote'),
            },
            {
              name: translate('productModelsTestimonialsClient2Name'),
              jobDescription: translate(
                'productModelsTestimonialsClient2JobDescription',
              ),
              company: translate('productModelsTestimonialsClient2Company'),
              image: client2Image,
              quote: translate('productModelsTestimonialsClient2Quote'),
            },
            {
              name: translate('productModelsTestimonialsClient3Name'),
              jobDescription: translate(
                'productModelsTestimonialsClient3JobDescription',
              ),
              company: translate('productModelsTestimonialsClient3Company'),
              image: client3Image,
              quote: translate('productModelsTestimonialsClient3Quote'),
            },
          ]}
        />
      </Content>

      <ContactSales
        content={{
          title: translate('productModelsContactSalesTitle') ?? '',
          button: translate('productModelsContactSalesButton') ?? '',
        }}
        color={{
          normal: theme.colors.secondary,
          hover: theme.colors.secondaryDark,
        }}
      />
    </Container>
  );
}
