import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Title } from 'src/components/Title/styles';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';

import { VideoContainer, VideoContent } from './styles';

export function Video() {
  const videoRef = useRef(null);
  const { t: translate } = useTranslation();
  const location = useLocation();

  return (
    <VideoContainer>
      <Title>
        <h4>{translate('landingPageVideoSubtitle')}</h4>
        <h3>{translate('landingPageVideoTitle')}</h3>
      </Title>

      <VideoContent
        role="button"
        onClick={() => {
          TrackGoogleAnalyticsEvent(
            `Play`,
            'Visualizar vídeo',
            location.pathname,
          );
        }}
      >
        <iframe
          ref={videoRef}
          src="https://www.youtube.com/embed/CMvCzJ82Td0?enablejsapi=1"
          title={translate('landingPageStartVideoTitle') ?? ''}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          loading="lazy"
          frameBorder="0"
        />
      </VideoContent>
    </VideoContainer>
  );
}
