import ReactGA from 'react-ga4';

export const TrackGoogleAnalyticsPageView = (path: string, title: string) => {
  ReactGA.send({
    hitType: 'pageview',
    page: path,
    title,
  });
};

export const TrackGoogleAnalyticsEvent = (
  category: string,
  action: string,
  label: string,
) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
