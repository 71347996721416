import React, { useEffect } from 'react';

import { ChartLineDown, ProjectorScreenChart } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SolutionCase from 'src/assets/hygiene-case-solution.jpg';
import Thumb from 'src/assets/hygiene-case.png';
import { ContactSales } from 'src/components/ContactSales';
import { Description } from 'src/components/Description/styles';
import { Head } from 'src/components/Head';
import { LanguageWarning } from 'src/components/LanguageWarning';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsPageView } from 'src/utils/google-analytics';

import {
  ChallengeContent,
  Container,
  Content,
  Image,
  SectionContainer,
} from '../components/Case/styles';

export function HygieneCase() {
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(
      location.pathname,
      'Tela de Cases - Higiene e Beleza',
    );
  }, [location.pathname]);

  return (
    <Container>
      <Head
        title={`Case | ${translate('casesPersonalCare')} | 4intelligence`}
        description={translate('casesPageHygieneHeadDescription') ?? ''}
      />

      <Title>
        <h4>Case multinacional de higiene e beleza</h4>
        <h2>Projeção de mercado para otimizar o processo de S&OP</h2>
      </Title>

      {urlPrefix === '/en' && <LanguageWarning />}

      <Description>
        <p>
          Veja como multinacional de higiene e beleza desburocratizou o
          planejamento e análise de vendas e operações, S&OP, com uma plataforma
          mais ágil, assertiva e que oferece projeções com alto grau de
          acurácia.
        </p>
      </Description>

      <img src={Thumb} alt="hygiene case" />

      <SectionContainer className="case-challenge">
        <Content>
          <h2>Impacto das variável econômicas e de mercado nas vendas</h2>
          <p>
            A multinacional de higiene e beleza tinha a necessidade de fazer o
            aprimoramento das projeções de vendas de seus itens, compreendendo
            quais eram os principais determinantes, econômicos, climáticos,
            sazonais e de mercado, nesse processo.
          </p>
        </Content>
        <ChallengeContent>
          <div>
            <div>
              <ChartLineDown />
            </div>
            <h3>Ferramenta customizada</h3>
            <p>
              A multinacional implementou a nova plataforma para fazer o
              monitoramento das projeção de vendas, sell out e gerenciamento do
              market share companhia
            </p>
          </div>

          <div>
            <div>
              <ProjectorScreenChart />
            </div>
            <h3>Mais agilidade e assertividade</h3>
            <p>
              A implementação da ferramenta customizada na companhia veio para
              agilizar o processo de tomada de decisão e torná-lo mais preciso
            </p>
          </div>
        </ChallengeContent>
      </SectionContainer>

      <SectionContainer>
        <Image>
          <img
            src={SolutionCase}
            alt="Represents hygiene case"
            style={{ height: '100%' }}
          />
        </Image>

        <Content>
          <h2>Solução</h2>
          <p>
            Com a 4intelligence, foram construídas informações em nível de área
            de ponderação para todo o território nacional. Dados como potencial
            de varejo, potencial de faturamento, saturação em termos de lojas de
            varejo de roupas entre outros foram combinados a fim de que cada
            localidade tivesse uma nota.
          </p>
        </Content>
      </SectionContainer>

      <SectionContainer>
        <Content style={{ width: '80%', textAlign: 'center' }}>
          <h2>Resultados</h2>
          <p style={{ fontStyle: 'italic' }}>
            “Para encerrar 2022 e começar 2023 com chave de ouro, tivemos a
            implementação da plataforma de projeção de mercado, Sell Out e Share
            na companhia. Um projeto muito desafiador desenvolvido ao longo de 8
            meses em conjunto com um time multifuncional, que vem para
            revolucionar a forma como fazemos projeções! Trazendo mais ciência,
            agilidade e assertividade.&rdquo;{' '}
            <span style={{ fontStyle: 'normal' }}>
              Strategy Analyst na multinacional de higiene e beleza.
            </span>
          </p>
        </Content>
      </SectionContainer>

      <ContactSales />
    </Container>
  );
}
