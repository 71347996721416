import React from 'react';

import {
  InstagramLogo,
  LinkedinLogo,
  YoutubeLogo,
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import logo from 'src/assets/4i-logo.svg';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';

import { SocialLinks, Column, LogoColumn, Container } from './styles';

export function Footer() {
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const location = useLocation();

  return (
    <Container>
      <LogoColumn>
        <img src={logo} alt="4intelligence logo" />

        <small>
          © 2024 4intelligence <br /> All rights reserved.
        </small>
      </LogoColumn>

      <Column>
        <h3>4intelligence</h3>

        <Link
          to={`${urlPrefix}/plans`}
          data-cy="plans-button"
          data-testid="plans-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Planos',
              'Acessar planos',
              location.pathname,
            );
          }}
        >
          {translate('plans')}
        </Link>

        <Link
          to={`${urlPrefix}/cases`}
          data-cy="cases-button"
          data-testid="cases-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Cases',
              'Acessar cases',
              location.pathname,
            );
          }}
        >
          {translate('cases')}
        </Link>

        <Link
          to="https://blog.4intelligence.ai/"
          data-cy="blog-button"
          data-testid="blog-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Blog',
              'Acessar blog',
              location.pathname,
            );
          }}
        >
          {translate('blog')}
        </Link>

        <Link
          to="https://conteudos.4intelligence.ai/pagina-de-carreiras"
          target="_blank"
          rel="noreferrer"
          data-cy="hire-button"
          data-testid="hire-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Trabalhe conosco',
              'Acessar página de vagas',
              location.pathname,
            );
          }}
        >
          {translate('footerWorkWithUs')}
        </Link>

        <Link
          to={`${urlPrefix}/contact-sales`}
          data-cy="contact-us-button"
          data-testid="contact-us-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Fale conosco',
              'Acessar fale com vendas',
              location.pathname,
            );
          }}
        >
          {translate('footerContactUs')}
        </Link>
      </Column>

      <Column>
        <h3>{translate('product')}</h3>

        <Link
          to={`${urlPrefix}/product/economic-data`}
          data-cy="product-data-button"
          data-testid="product-data-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Produto - Dados Econômicos',
              'Acessar Produto - Dados Econômicos',
              location.pathname,
            );
          }}
        >
          {translate('productData')}
        </Link>

        <Link
          to={`${urlPrefix}/product/ai-models`}
          data-cy="product-models-button"
          data-testid="product-models-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Produto - Dados Econômicos',
              'Acessar Produto - Dados Econômicos',
              location.pathname,
            );
          }}
        >
          {translate('productModels')}
        </Link>

        <Link
          to={`${urlPrefix}/product/integrated-planning`}
          data-cy="product-planning-button"
          data-testid="product-planning-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Produto - Planejamento Integrado',
              'Acessar Produto - Planejamento Integrado',
              location.pathname,
            );
          }}
        >
          {translate('productPlanning')}
        </Link>
      </Column>

      <Column>
        <h3>{translate('about')}</h3>

        <Link
          to={`${urlPrefix}/help-center`}
          data-cy="help-button"
          data-testid="help-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Central de Ajuda',
              'Acessar central de ajuda',
              location.pathname,
            );
          }}
        >
          {translate('helpCenter')}
        </Link>

        <Link
          to={process.env.REACT_APP_PRODUCT_TERMS_OF_USE ?? ''}
          target="_blank"
          rel="noreferrer"
          data-testid="terms-of-use-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Link Termos de uso',
              'Visualizer arquivo dos termos de uso',
              location.pathname,
            );
          }}
        >
          {translate('termsOfUse')}
        </Link>

        <Link
          to={process.env.REACT_APP_PRIVACY_POLICY ?? ''}
          target="_blank"
          rel="noreferrer"
          data-testid="privacy-policy-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Footer - Link Política de Privacidade',
              'Visualizer arquivo da política de privacidade',
              location.pathname,
            );
          }}
        >
          {translate('privacyPolicy')}
        </Link>
      </Column>

      <Column>
        <h3>{translate('footerSocialMedia')}</h3>

        <SocialLinks>
          <Link
            to="https://www.linkedin.com/company/4intelligence-ai/"
            target="_blank"
            rel="noreferrer"
            aria-label={translate('footerLinkedinLabel') ?? ''}
            data-cy="linkedin-button"
            data-testid="linkedin-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Footer - Linkedin',
                'Acessar linkedin',
                location.pathname,
              );
            }}
          >
            <LinkedinLogo weight="fill" />
          </Link>

          <Link
            to="https://www.instagram.com/4.intelligence.ai/"
            target="_blank"
            rel="noreferrer"
            aria-label={translate('footerInstagramLabel') ?? ''}
            data-cy="instagram-button"
            data-testid="instagram-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Footer - Instagram',
                'Acessar instagram',
                location.pathname,
              );
            }}
          >
            <InstagramLogo weight="fill" />
          </Link>

          <Link
            to="https://www.youtube.com/@4intelligenceltda/"
            target="_blank"
            rel="noreferrer"
            aria-label={translate('footerYoutubeLabel') ?? ''}
            data-cy="youtube-button"
            data-testid="youtube-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Footer - YouTube',
                'Acessar YouTube',
                location.pathname,
              );
            }}
          >
            <YoutubeLogo weight="fill" />
          </Link>
        </SocialLinks>
      </Column>
    </Container>
  );
}
