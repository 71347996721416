import React from 'react';

import { LabelStyle } from './styles';
import { LabelProps } from './types';

export function Label({ text, ...props }: LabelProps) {
  return (
    <LabelStyle
      data-testid={`label-${text.toLowerCase().replaceAll(' ', '-')}`}
      data-cy={`label-${text.toLowerCase().replaceAll(' ', '-')}`}
      {...props}
    >
      {text}
    </LabelStyle>
  );
}
