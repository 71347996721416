import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 5rem 7rem 0 7rem;

  > div {
    width: 70%;
  }

  > div:first-child {
    width: 100%;

    align-items: center;
    text-align: center;
  }

  > div:last-child {
    margin-top: 8rem;
  }

  > img {
    width: 70%;
    height: 20rem;

    margin-top: 2rem;
    border-radius: 4px;
  }

  @media (max-width: 1700px) {
    > div,
    > img {
      width: 100%;
    }
  }

  @media (max-width: 1366px) {
    img {
      height: 18rem;
    }
  }

  @media (max-width: 500px) {
    padding: 2rem;

    img {
      height: 10rem;
    }
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5rem;

  margin-top: 5rem;

  @media (max-width: 900px) {
    flex-direction: column;

    gap: 3rem;
    margin-top: 3rem;
  }

  @media (max-width: 500px) {
    padding: 0 1.5rem;
  }
`;

export const Image = styled.div`
  width: 40%;
  height: 20rem;

  display: flex;
  flex-direction: column;

  img {
    border-radius: 8px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 60%;

  h2 {
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 700;

    color: ${({ theme }) => theme.colors.gray7};

    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.125rem;
    line-height: 150%;
    font-weight: 400;

    color: ${({ theme }) => theme.colors.gray5};
  }

  p + p {
    margin-top: 1rem;
  }

  .numeric-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 1rem;

    &:first-of-type {
      margin-top: 1rem;
      margin-bottom: 0.75rem;
    }

    p {
      display: flex;

      flex: 1;

      color: ${({ theme }) => theme.colors.primary};
      font-weight: 500;
    }

    > div {
      width: 2.5rem;
      height: 2.5rem;

      display: flex;
      align-items: center;
      justify-content: center;

      color: ${({ theme }) => theme.colors.primary};
      font-weight: 500;
      font-size: 1.25rem;

      background: ${({ theme }) => `${theme.colors.primary}3F`};
      border-radius: 100%;
    }
  }

  @media (max-width: 900px) {
    width: 100% !important;

    text-align: start !important;
  }
`;

export const ChallengeContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      font-size: 1rem;
      line-height: 150%;
      font-weight: 400;

      color: ${({ theme }) => theme.colors.gray5};
    }

    h3 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 600;

      color: ${({ theme }) => theme.colors.gray7};

      margin-bottom: 0.5rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 3rem;
      width: 3rem;

      background: ${({ theme }) => theme.colors.primary};
      border-radius: 100%;

      padding: 0.5rem;

      margin-bottom: 1rem;
    }

    svg {
      width: 2rem;
      height: 2rem;

      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
