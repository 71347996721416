import React, { useEffect } from 'react';

import ReactMarkdown from 'react-markdown';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Head } from 'src/components/Head';
import { Skeleton } from 'src/components/Skeleton';
import { NotFound } from 'src/pages/NotFound';
import { RootState } from 'src/redux/store';
import { githubApi } from 'src/service/githubApi';
import { TrackGoogleAnalyticsPageView } from 'src/utils/google-analytics';

import { Container, Content, DocContent } from './styles';
import { DocumentationDataProps, MenuProps } from '../../types';
import { MenuMobile } from '../MenuMobile';
import { Sidebar } from '../Sidebar';

export function DocumentationContent() {
  const { language } = useSelector((state: RootState) => state.config);
  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(
      location.pathname,
      'Tela do Artigo da Central de Ajuda',
    );
  }, [location.pathname]);

  const currentPath = location.pathname
    .replace('/en/help-center/', '')
    .replace('/help-center/', '');

  const branch = process.env.REACT_APP_GITHUB_BRANCH;

  const {
    data: menuData,
    isLoading: menuLoading,
    isFetching: menuFetching,
  } = useQuery(
    'documentation menu',
    async () => {
      const { data: response } = await githubApi.get<DocumentationDataProps>(
        `/4intelligence/documentation/contents/menu.json?ref=${branch}`,
      );

      const decoded = decodeURIComponent(
        atob(response.content)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      );

      return (JSON.parse(decoded) as unknown as MenuProps)?.menu;
    },
    {
      staleTime: 1000 * 60 * 60,
    },
  );

  const { data, isLoading, isFetching } = useQuery(
    ['documentation content', currentPath, language],
    async () => {
      const { data: response } = await githubApi.get<DocumentationDataProps>(
        `/4intelligence/documentation/contents/${language}/${currentPath}?ref=${branch}`,
      );

      return decodeURIComponent(
        atob(response.content)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      );
    },
    {
      staleTime: 1000 * 60 * 60,
      enabled: currentPath.endsWith('.md'),
    },
  );

  return (
    <Container notFound={!isLoading && !isFetching && !data && !!menuData}>
      <Head
        title="Central de Ajuda | 4intelligence"
        description="Tire suas dúvidas sobre nossas funcionalidades"
      />

      {menuLoading || menuFetching || !menuData ? (
        <Skeleton style={{ height: 'calc(100vh - 6rem)' }} />
      ) : !isLoading && !isFetching && !data && menuData ? (
        <NotFound />
      ) : (
        <Content>
          <Sidebar menuData={menuData} currentPath={currentPath} />
          <MenuMobile menuData={menuData} currentPath={currentPath} />

          <DocContent>
            {isLoading || isFetching || !data ? (
              <Skeleton style={{ height: 'calc(100vh - 9rem)' }} />
            ) : (
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw as any]}
              >
                {data}
              </ReactMarkdown>
            )}
          </DocContent>
        </Content>
      )}
    </Container>
  );
}
