export const LandingPageRoiContentPTBRTranslations = {
  landingPageRoiImpactSubtitle: 'IMPACTO FINANCEIRO - ROI',
  landingPageRoiImpactValue1Title: 'de aumento nas entregas',
  landingPageRoiImpactValue1Description:
    'por meio de previsões de estoque aprimorada',
  landingPageRoiImpactValue2Title: 'de aumento na receita',
  landingPageRoiImpactValue2Description:
    'obtido por meio de análises de custos e otimização de preços',
  landingPageRoiImpactValue3Title: 'de aumento nas vendas',
  landingPageRoiImpactValue3Description:
    'obtido por meio de previsões precisas e produção otimizada',
  landingPageRoiImpactSmallMessage:
    '*Resultados alcançados pelos nossos clientes',
};
