import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  max-width: calc(${({ theme }) => theme.maxWidth} + 14rem);

  align-self: center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3rem;

  padding: 3rem 7rem;

  @media (max-width: 1200px) {
    padding: 3.5rem 3rem;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    padding: 1.5rem 2rem;
  }
`;

export const LogoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 2rem;

  img {
    height: 2rem;
  }

  p,
  span {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 400;
  }

  p {
    color: ${({ theme }) => theme.colors.gray6};
  }

  small {
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const Column = styled.div`
  h3 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.08em;

    color: ${({ theme }) => theme.colors.primary};

    margin-bottom: 1.25rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;

    color: ${({ theme }) => theme.colors.gray4};

    cursor: pointer;
  }

  p + p,
  p + a,
  a + p,
  > a + a {
    display: block;

    margin-top: 1rem;
  }

  a {
    font-size: 1rem;
    line-height: 1.5rem;

    color: ${({ theme }) => theme.colors.gray6};

    transition: color 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (max-width: 500px) {
    order: -1;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  svg {
    width: 2rem;
    height: 2rem;

    color: ${({ theme }) => theme.colors.gray4};

    transition: color 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (max-width: 500px) {
    order: 2;
  }
`;
