export const CasesPagePTBRTranslations = {
  casesPageHeadDescription:
    '4intelligence, a grande aliada da sua companhia quanto o assunto é tomar decisões precisas',

  casesPageAutomobilesHeadDescription:
    'Descubra como a 4intelligence ajudou uma indústria de automóveis a aumentar 45,7% as vendas de caminhões',

  casesPageFoodHeadDescription:
    'Descubra como a 4intelligence ajudou uma indústria de alimentos a aumentar 26% a receita da companhia',

  casesPageHygieneHeadDescription:
    'Descubra como a 4intelligence ajudou uma indústria de bens de consumo a desburocratizar o S&OP',

  casesPageLogisticsHeadDescription:
    'Descubra como a 4intelligence ajudou uma indústria de logística a aumento 10% no total de cargas transportadas',

  casesPageRetailHeadDescription:
    'Descubra como a 4intelligence ajudou um grande varejo a expandir a rede de lojas pelo Brasil',
};
