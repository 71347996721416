import React from 'react';

import {
  ForkKnife,
  Leaf,
  Truck,
  ChartLineUp,
  Bathtub,
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import agribusinessImage from 'src/assets/departments/agribusiness.png';
import financialImage from 'src/assets/departments/financial.png';
import foodImage from 'src/assets/departments/food.png';
import logisticsImage from 'src/assets/departments/logistics.png';
import personalCareImage from 'src/assets/departments/personal-care.png';
import { Title } from 'src/components/Title/styles';
import light from 'src/styles/themes/light';

import { CarouselTab } from './components/CarouselTab';
import { Container, Content, TabContent } from './styles';

export function Departments() {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Title>
        <h4>{translate('landingPageDepartmentSubtitle')}</h4>
        <h3>{translate('landingPageDepartmentTitle')}</h3>
      </Title>

      <Content>
        <CarouselTab
          data={[
            {
              id: 'food-tab',
              icon: <ForkKnife weight="duotone" />,
              color: light.colors.secondary,
              name: translate('landingPageDepartmentFoodTitle'),
              render: () => (
                <TabContent color={light.colors.secondary}>
                  <img
                    src={foodImage}
                    alt={translate('landingPageDepartmentFoodImageAlt') ?? ''}
                  />
                  <div>
                    <h1>{translate('landingPageDepartmentFoodTitle')}</h1>
                    <p>{translate('landingPageDepartmentDescription')}</p>

                    <ul>
                      <li>{translate('landingPageDepartmentFoodList1')}</li>
                      <li>{translate('landingPageDepartmentFoodList2')}</li>
                      <li>{translate('landingPageDepartmentFoodList3')}</li>
                      <li>{translate('landingPageDepartmentFoodList4')}</li>
                      <li>{translate('landingPageDepartmentFoodList5')}</li>
                    </ul>
                  </div>
                </TabContent>
              ),
            },
            {
              id: 'agribusiness-tab',
              icon: <Leaf weight="duotone" />,
              color: light.colors.green4,
              name: translate('landingPageDepartmentAgribusinessTitle'),
              render: () => (
                <TabContent color={light.colors.green4}>
                  <img
                    src={agribusinessImage}
                    alt={
                      translate('landingPageDepartmentAgribusinessImageAlt') ??
                      ''
                    }
                  />
                  <div>
                    <h1>
                      {translate('landingPageDepartmentAgribusinessTitle')}
                    </h1>
                    <p>{translate('landingPageDepartmentDescription')}</p>

                    <ul>
                      <li>
                        {translate('landingPageDepartmentAgribusinessList1')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentAgribusinessList2')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentAgribusinessList3')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentAgribusinessList4')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentAgribusinessList5')}
                      </li>
                    </ul>
                  </div>
                </TabContent>
              ),
            },
            {
              id: 'logistics-tab',
              icon: <Truck weight="duotone" />,
              color: light.colors.purple4,
              name: translate('landingPageDepartmentLogisticsTitle'),
              render: () => (
                <TabContent color={light.colors.purple4}>
                  <img
                    src={logisticsImage}
                    alt={
                      translate('landingPageDepartmentLogisticsImageAlt') ?? ''
                    }
                  />
                  <div>
                    <h1>{translate('landingPageDepartmentLogisticsTitle')}</h1>
                    <p>{translate('landingPageDepartmentDescription')}</p>

                    <ul>
                      <li>
                        {translate('landingPageDepartmentLogisticsList1')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentLogisticsList2')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentLogisticsList3')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentLogisticsList4')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentLogisticsList5')}
                      </li>
                    </ul>
                  </div>
                </TabContent>
              ),
            },
            {
              id: 'financial-tab',
              icon: <ChartLineUp weight="duotone" />,
              color: light.colors.yellow4,
              name: translate('landingPageDepartmentFinancialTitle'),
              render: () => (
                <TabContent color={light.colors.yellow4}>
                  <img
                    src={financialImage}
                    alt={
                      translate('landingPageDepartmentFinancialImageAlt') ?? ''
                    }
                  />
                  <div>
                    <h1>{translate('landingPageDepartmentFinancialTitle')}</h1>
                    <p>{translate('landingPageDepartmentDescription')}</p>

                    <ul>
                      <li>
                        {translate('landingPageDepartmentFinancialList1')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentFinancialList2')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentFinancialList3')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentFinancialList4')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentFinancialList5')}
                      </li>
                    </ul>
                  </div>
                </TabContent>
              ),
            },
            {
              id: 'personal-care-tab',
              icon: <Bathtub weight="duotone" />,
              color: light.colors.primary,
              name: translate('landingPageDepartmentPersonalCareTitle'),
              render: () => (
                <TabContent color={light.colors.primary}>
                  <img
                    src={personalCareImage}
                    alt={
                      translate('landingPageDepartmentPersonalCareImageAlt') ??
                      ''
                    }
                  />
                  <div>
                    <h1>
                      {translate('landingPageDepartmentPersonalCareTitle')}
                    </h1>
                    <p>{translate('landingPageDepartmentDescription')}</p>

                    <ul>
                      <li>
                        {translate('landingPageDepartmentPersonalCareList1')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentPersonalCareList2')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentPersonalCareList3')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentPersonalCareList4')}
                      </li>
                      <li>
                        {translate('landingPageDepartmentPersonalCareList5')}
                      </li>
                    </ul>
                  </div>
                </TabContent>
              ),
            },
          ]}
        />
      </Content>
    </Container>
  );
}
