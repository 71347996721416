import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1100px !important;

  margin-left: auto;
  margin-right: auto;
`;

export const CasesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 4rem;

  @media (max-width: 500px) {
    margin-top: 3rem;
  }
`;

export const Tabs = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

type CasesTabProps = {
  selected: boolean;
};

export const CasesTab = styled.button<CasesTabProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 1.25rem;
  font-weight: 400;

  border-bottom: 2px solid ${({ theme }) => theme.colors.primaryLight};
  border-radius: 16px 16px 0 0;

  padding: 1.25rem 0;

  p {
    color: ${({ theme }) => theme.colors.gray5};
  }

  ${({ selected, theme }) =>
    selected &&
    css`
      background: ${theme.colors.primaryLight};

      p {
        color: ${theme.colors.white};
        font-weight: 500;
      }
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    display: none;
  }

  @media (max-width: 1000px) {
    svg {
      display: flex;

      width: 1.75rem;
      height: 1.75rem;

      color: ${({ selected, theme }) =>
        selected ? theme.colors.white : theme.colors.primary};
    }

    p {
      display: none;
    }
  }

  @media (max-width: 900px) {
    padding: 1rem 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 28rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 3.5rem 3.5rem;

  border: 2px solid ${({ theme }) => theme.colors.primaryLight};
  border-radius: 0 0 16px 16px;
  border-top: none;

  > div:first-of-type {
    width: 55%;
  }
  > div:last-of-type {
    width: 45%;
  }

  h1 {
    font-size: 2rem;
    line-height: 150%;
    font-weight: 600;

    color: ${({ theme }) => theme.colors.gray7};

    margin-bottom: 1rem;
  }

  p {
    font-size: 1.25rem;
    line-height: 150%;
    font-weight: 400;

    color: ${({ theme }) => theme.colors.gray5};
  }

  button {
    padding: 0;
    margin-top: 2rem;

    font-size: 1.25rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  h3 {
    display: none;
  }

  > div:last-of-type {
    align-self: center;
    text-align: end;

    img {
      height: 20rem;
    }
  }

  @media (max-width: 1400px) {
    height: 26rem;

    h1 {
      font-size: 2rem;
      line-height: 3rem;
    }

    p {
      font-size: 1.125rem;
      line-height: 2rem;
    }

    button {
      margin-top: 1rem;

      font-size: 1rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  @media (max-width: 1100px) {
    height: 28rem;
  }

  @media (max-width: 1000px) {
    height: 32rem;

    h1 {
      font-size: 1.75rem;
      line-height: 2.75rem;
    }

    h3 {
      display: flex;

      font-size: 1rem;
      line-height: 2rem;
      font-weight: 600;

      text-transform: uppercase;
      letter-spacing: 0.08em;

      color: ${({ theme }) => theme.colors.primary};

      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    height: fit-content;

    > div {
      width: 100% !important;
    }

    > div:last-of-type {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 2rem;

    p {
      font-size: 1rem;
      line-height: 1.75rem;
    }

    h3 {
      font-size: 0.875rem;
      line-height: 1.75rem;
    }
  }
`;
