import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 3rem !important;
`;

export const Content = styled.div`
  position: relative;
  background: white;

  display: flex;
  align-items: center;
  white-space: nowrap;

  overflow: hidden;

  > div {
    display: flex;
    align-items: center;
    animation: 48s slide infinite linear;

    @keyframes slide {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;

    width: 2rem;
    height: 100%;

    z-index: 2;

    content: '';
  }

  &:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }

  &:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }

  &:hover {
    > div {
      animation-play-state: paused;
    }
  }
`;
