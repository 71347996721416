export const LandingPageModulesContentPTBRTranslations = {
  landingPageModulesEconomicDataTitle:
    'Todos os dados que você precisa em um só lugar',
  landingPageModulesEconomicDataDescription:
    'Os indicadores e séries atualizados da Feature Store estão prontos para serem utilizados em suas atividades de planejamento e tomada de decisão.',
  landingPageModulesEconomicDataQuote:
    'Além da facilidade de uso, escolhemos a 4intelligence devido à qualidade e ampla funcionalidade da Feature Store.',
  landingPageModulesEconomicDataAuthor:
    'Gabriel Leite, Risk Coordinator, HDI Insurance',
  landingPageModulesEconomicDataImgAlt:
    'Exemplo de imagem para Dados Econômicos',

  landingPageModulesAiModelsTitle:
    'Preveja o futuro do seu negócio com precisão',
  landingPageModulesAiModelsDescription:
    'Gere insights acionáveis com modelagem preditiva impulsionada por IA permitindo mapear riscos e antecipar cenários futuros com precisão.',
  landingPageModulesAiModelsQuote:
    'A plataforma da 4intelligence nos oferece níveis de precisão superiores aos de outros provedores.',
  landingPageModulesAiModelsAuthor: 'Hélio Vinicius, Risk Modelling, Bradesco',
  landingPageModulesAiModelsImgAlt: 'Exemplo de imagem para Models',

  landingPageModulesPlanningTitle: 'Decisões colaborativas e rastreamento',
  landingPageModulesPlanningDescription:
    'Colabore entre equipes, acompanhe os resultados em relação às previsões e capture e registre todos os detalhes do processo de tomada de decisão em um único lugar.',
  landingPageModulesPlanningQuote:
    'O Workspace permite que nossas equipes trabalhem juntas durante o ciclo de planejamento.',
  landingPageModulesPlanningAuthor:
    'Giuliano Suetugo, Strategy & Market Intelligence, Volvo',
  landingPageModulesPlanningImgAlt:
    'Exemplo de imagem para Planejamento Integrado',
};
