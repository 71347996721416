import React, { useEffect } from 'react';

import { ChartLineDown, ProjectorScreenChart } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SolutionCase from 'src/assets/food-case-solution.jpg';
import Thumb from 'src/assets/food-case.jpg';
import { ContactSales } from 'src/components/ContactSales';
import { Description } from 'src/components/Description/styles';
import { Head } from 'src/components/Head';
import { LanguageWarning } from 'src/components/LanguageWarning';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsPageView } from 'src/utils/google-analytics';

import {
  ChallengeContent,
  Container,
  Content,
  Image,
  SectionContainer,
} from '../components/Case/styles';

export function FoodCase() {
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(
      location.pathname,
      'Tela de Cases - Alimentos',
    );
  }, [location.pathname]);

  return (
    <Container>
      <Head
        title={`Case | ${translate('casesFood')} | 4intelligence`}
        description={translate('casesPageFoodHeadDescription') ?? ''}
      />

      <Title>
        <h4>Case multinacional de alimentos</h4>
        <h2>Prevendo aumento de custos e otimizando o pricing</h2>
      </Title>

      {urlPrefix === '/en' && <LanguageWarning />}

      <Description>
        <p>
          Veja como multinacional de alimentos utilizou a 4intelligence para
          fazer análises de sensibilidade, projetando impactos em suas vendas e
          otimizando o pricing de seus produtos.
        </p>
      </Description>

      <img src={Thumb} alt="grain case" />

      <SectionContainer className="case-challenge">
        <Content>
          <h2>Custos aumentando, margens caindo</h2>
          <p>
            Por causa da escassez de matérias primas causada pela pandemia, a
            companhia viu um grande aumento no custo de seus insumos e, por
            causa disso, uma redução em suas margens.
          </p>
        </Content>
        <ChallengeContent>
          <div>
            <div>
              <ChartLineDown />
            </div>
            <h3>Resultados prejudicados</h3>
            <p>
              Com o aumento do preço dos insumos, empresas veem suas margens
              caírem e seus resultados serem afetados. Nesse cenário, algo deve
              ser feito ou as consequências podem ser muito graves.
            </p>
          </div>

          <div>
            <div>
              <ProjectorScreenChart />
            </div>
            <h3>Qual decisão tomar?</h3>
            <p>
              O aumento no custo de produção força empresas a fazer algo e,
              repassar esse aumento para o consumidor pode fazer com que as
              vendas caiam consideravelmente.
            </p>
          </div>
        </ChallengeContent>
      </SectionContainer>

      <SectionContainer>
        <Image>
          <img
            src={SolutionCase}
            alt="Represents grain case"
            style={{ height: '100%' }}
          />
        </Image>

        <Content>
          <h2>Solução</h2>
          <p>
            Com a 4intelligence, a multinacional conseguiu fazer a previsão das
            vendas de seus itens e do custo de produção.
          </p>
          <p>
            Com o aumento do valor dos insumos, foi feito uma análise de
            sensibilidade para entender quais itens teriam suas vendas pouco ou
            muito afetadas caso seu preço final aumentasse.
          </p>
          <p>
            Sendo assim, foi possível otimizar o pricing da companhia como um
            todo para alcançar melhores resultados financeiros.
          </p>
        </Content>
      </SectionContainer>

      <SectionContainer>
        <Content style={{ width: '80%', textAlign: 'center' }}>
          <h2>Resultados</h2>
          <p>
            A multinacional de alimentos teve um excelente desempenho, puxado
            pela receita recorde, que somou R$ 2,49 bilhões, 26% acima do
            segundo trimestre de 2021.
          </p>
        </Content>
      </SectionContainer>

      <ContactSales />
    </Container>
  );
}
