import React from 'react';

import { ArrowRight } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import aiModelsImageEN from 'src/assets/product/home/ai-models-en.png';
import aiModelsImagePT from 'src/assets/product/home/ai-models-pt.png';
import economicDataImageEN from 'src/assets/product/home/economic-data-en.png';
import economicDataImagePT from 'src/assets/product/home/economic-data-pt.png';
import planningImageEN from 'src/assets/product/home/planning-en.png';
import planningImagePT from 'src/assets/product/home/planning-pt.png';
import { Button } from 'src/components/Button';
import { Quotes } from 'src/components/Quotes';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';

import { Container, Module, Quote } from './styles';

export function Modules() {
  const { t: translate } = useTranslation();
  const { urlPrefix, language } = useSelector(
    (state: RootState) => state.config,
  );
  const navigate = useNavigate();

  function languageNavigate(path: string) {
    navigate(urlPrefix.startsWith('/en') ? `/en${path}` : path);
  }

  return (
    <Container>
      <Module index={0}>
        <div>
          <Title>
            <h4>{translate('productData')}</h4>
            <h3>{translate('landingPageModulesEconomicDataTitle')}</h3>
          </Title>

          <p>{translate('landingPageModulesEconomicDataDescription')}</p>

          <Button
            buttonType="secondary"
            onClick={() => languageNavigate('/product/economic-data')}
          >
            Explore {translate('productData')}
            <ArrowRight />
          </Button>

          <Quote index={0}>
            <Quotes />
            <p>{translate('landingPageModulesEconomicDataQuote')}</p>
            <small>{translate('landingPageModulesEconomicDataAuthor')}</small>
          </Quote>
        </div>

        <aside>
          <img
            src={
              language === 'pt-br' ? economicDataImagePT : economicDataImageEN
            }
            alt={translate('landingPageModulesEconomicDataImgAlt') ?? ''}
          />
        </aside>
      </Module>

      <Module index={1}>
        <aside>
          <img
            src={language === 'pt-br' ? aiModelsImagePT : aiModelsImageEN}
            alt={translate('landingPageModulesAiModelsImgAlt') ?? ''}
          />
        </aside>

        <div>
          <Title>
            <h4>{translate('productModels')}</h4>
            <h3>{translate('landingPageModulesAiModelsTitle')}</h3>
          </Title>

          <p>{translate('landingPageModulesAiModelsDescription')}</p>

          <Button
            buttonType="secondary"
            onClick={() => languageNavigate('/product/ai-models')}
          >
            Explore {translate('productModels')}
            <ArrowRight />
          </Button>

          <Quote index={1}>
            <Quotes />
            <p>{translate('landingPageModulesAiModelsQuote')}</p>
            <small>{translate('landingPageModulesAiModelsAuthor')}</small>
          </Quote>
        </div>
      </Module>

      <Module index={2}>
        <div>
          <Title>
            <h4>{translate('productPlanning')}</h4>
            <h3>{translate('landingPageModulesPlanningTitle')}</h3>
          </Title>

          <p>{translate('landingPageModulesPlanningDescription')}</p>

          <Button
            buttonType="secondary"
            onClick={() => languageNavigate('/product/integrated-planning')}
          >
            Explore {translate('productPlanning')}
            <ArrowRight />
          </Button>

          <Quote index={2}>
            <Quotes />
            <p>{translate('landingPageModulesPlanningQuote')}</p>
            <small>{translate('landingPageModulesPlanningAuthor')}</small>
          </Quote>
        </div>

        <aside>
          <img
            src={language === 'pt-br' ? planningImagePT : planningImageEN}
            alt={translate('landingPageModulesPlanningImgAlt') ?? ''}
          />
        </aside>
      </Module>
    </Container>
  );
}
