export const LandingPageRoiContentENUSTranslations = {
  landingPageRoiImpactSubtitle: 'ROI Impact',
  landingPageRoiImpactValue1Title: 'increase in deliveries',
  landingPageRoiImpactValue1Description:
    'through improved inventory forecasting',
  landingPageRoiImpactValue2Title: 'increase in revenues',
  landingPageRoiImpactValue2Description:
    'through cost analysis and price optimization',
  landingPageRoiImpactValue3Title: 'increase in sales',
  landingPageRoiImpactValue3Description:
    'through accurate forecasting and optimized production',
  landingPageRoiImpactSmallMessage: '*Results achieved by our clients',
};
