import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin-top: 4rem;

  @media (max-width: 500px) {
    margin-top: 2rem;
  }
`;

type TabContentProps = {
  color: string;
};

export const TabContent = styled.div<TabContentProps>`
  width: 100%;
  height: fit-content;

  display: flex;
  justify-content: space-between;
  gap: 4rem;

  border: 1px solid ${({ color }) => color};
  border-radius: 0.5rem;

  padding-right: 2.5rem;

  > div {
    width: 50%;
    margin: 2.5rem 0;
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: normal;

    color: ${({ theme }) => theme.colors.gray7};

    margin-bottom: 1rem;
  }

  p,
  li {
    font-size: 1.25rem;
    line-height: 150%;
    font-weight: 400;

    color: ${({ theme }) => theme.colors.gray5};
  }

  p {
    margin-bottom: 0.5rem;
  }

  li {
    list-style: disc;
    list-style-position: outside;

    margin-left: 1.3rem;
    margin-top: 1rem;
  }

  h3 {
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 600;

    text-transform: uppercase;
    letter-spacing: 0.08em;

    color: ${({ theme }) => theme.colors.primary};
  }

  span {
    color: ${({ color }) => color};
  }

  img {
    width: 50%;
    height: 33rem;

    object-fit: cover;

    border-radius: 0.5rem 0 0 0.5rem;
  }

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    gap: 0;

    padding: 0;

    > div {
      width: 100%;
      padding: 2rem;
      margin: 0;
    }

    p,
    li {
      font-size: 1rem;
      line-height: 1.75rem;
    }

    h3 {
      font-size: 0.875rem;
      line-height: 1.75rem;
    }

    img {
      width: 100% !important;
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }
`;
