import styled, { css } from 'styled-components';

type ContainerProps = {
  notFound: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;

  padding: 3rem 7rem 5rem 7rem;

  p {
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 400;

    color: ${({ theme }) => theme.colors.gray5};
  }

  @media (max-width: 1919px) {
    padding: 2rem 5rem 5rem 5rem;
  }

  @media (max-width: 1200px) {
    padding: 2rem 3rem 5rem 3rem;
  }

  @media (max-width: 900px) {
    padding: 1rem 2rem 3rem 2rem;
  }

  ${({ notFound }) =>
    notFound &&
    css`
      padding: 0 !important;
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const DocContent = styled.div`
  width: 100%;

  padding-left: 5rem;

  margin-top: -1rem;

  h1 {
    font-size: 3rem;
    line-height: 4.8rem;
    font-weight: 700;

    color: ${({ theme }) => theme.colors.gray7};

    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.75rem;
    line-height: 3.5rem;
    font-weight: 600;

    color: ${({ theme }) => theme.colors.gray7};

    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 600;

    text-transform: uppercase;
    letter-spacing: 0.08em;

    color: ${({ theme }) => theme.colors.primary};

    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  a {
    text-decoration: underline;
    text-underline-offset: 0.25rem;

    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  img {
    margin: 2rem 0;

    max-width: 100%;
  }

  ul {
    list-style: initial;

    margin: 1rem 0;
  }

  li {
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 400;

    color: ${({ theme }) => theme.colors.gray5};

    list-style-position: inside;

    margin-top: 0.25rem;
  }

  li > p {
    display: inline;
  }

  table {
    margin: 2rem 0;
    margin-left: auto;
    margin-right: auto;

    border-spacing: 0;
    border-collapse: collapse;

    border: 1px solid ${({ theme }) => theme.colors.gray2};

    th,
    td {
      font-size: 0.875rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray6};

      padding: 1rem;

      border-right: 1px solid ${({ theme }) => theme.colors.gray2};
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    }

    td {
      font-weight: 400;
    }
  }

  p + p {
    margin-top: 1rem;
  }

  style + p {
    margin-top: 1rem;
  }

  .question-box,
  .question-box-blue,
  .question-box-pink {
    border-radius: 4px;

    padding: 0.75rem 1rem;

    margin: 1rem 0;

    > p:first-of-type {
      > strong {
        color: ${({ theme }) => theme.colors.gray6};
        font-size: 1.125rem;
      }
    }

    > p:nth-child(2) {
      margin-top: 0.25rem;
    }
  }

  .question-box-blue,
  .question-box {
    border-left: 5px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => `${theme.colors.primary}14`};
  }

  .question-box-pink {
    border-left: 5px solid ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => `${theme.colors.secondary}14`};
  }

  @media (max-width: 900px) {
    padding: 0;
    margin-top: 0;
  }
`;
