export const LandingPagePTBRTranslations = {
  landingPageHeadTitle: '4intelligence',
  landingPageHeadDescription:
    'Entenda como a Inteligência Artificial da 4intelligence pode ajudar sua empresa a construir projeções precisas e tomar melhores decisões, baseando-se em dados. Conheça nossas soluções!',
  landingPageModelingSubtitle: 'Plataforma de projeções',
  landingPageModelingTitle:
    'Para qualquer tipo de previsão, a 4intelligence tem a solução!',
  landingPageModelingDescription:
    'Nossa plataforma consegue solucionar qualquer desafio de negócio.',
  landingPageModelingTimeSubtitle: 'Série de tempo',
  landingPageModelingTimeTitle: 'Projeções',
  landingPageModelingTimeDescription:
    'Usufrua da nossa inteligência artificial para analisar um grande volume de dados a fim de impulsionar o futuro do seu negócio.',
  landingPageModelingTimeImageAlt: 'Gráfico de séries temporais',
  landingPageModelingClassificationSubtitle: 'Classificação',
  landingPageModelingClassificationTitle: 'Probabilidades',
  landingPageModelingClassificationDescription:
    'Com nossa inteligência de dados é possível resolver diferentes tipos de problemas de probabilidades de forma clara, precisa e interpretável.',
  landingPageModelingClassificationImageAlt:
    'Gráfico do método classificação de aprendizado de máquina',
  landingPageModelingLocationSubtitle: 'Localização',
  landingPageModelingLocationTitle: 'Geolocalização',
  landingPageModelingLocationDescription:
    'Utilize nossa inteligência artificial de classificação para solucionar os desafios nos quais a localização é um indicador relevante.',
  landingPageModelingLocationImageAlt: 'Mapa de geolocalização',
  landingPageAboutSubtitle: 'O que fazemos',
  landingPageAboutTitle: 'Solução de inteligência de dados',
  landingPageAboutDescription:
    'Oferecemos uma solução que une ciência de dados e inteligência artificial voltada para resolver desafios que envolvem previsões e tomadas de decisões estratégicas.',
  landingPageAboutDatabaseTitle:
    'Informações econômicas prontas para o seu negócio',
  landingPageAboutDatabaseDescription:
    'Tenha acesso ao nosso banco de dados completo e garanta a explicabilidade das suas projeções.',
  landingPageAboutApplicationTitle:
    'Plataforma inovadora, completa e  intuitiva',
  landingPageAboutApplicationDescription:
    'Conte com a ajuda de especialistas em inteligência econômica garantindo sempre a melhor experiência.',
  landingPageAboutAgilityTitle:
    'Simplicidade e agilidade para tomadas de decisão',
  landingPageAboutAgilityDescription:
    'Tenha acesso a resultados instantâneos, traduzidos em insights objetivos, com apenas um clique.',
  landingPageStartSubtitle: 'Comece aqui',
  landingPageStartTitle:
    'A melhor solução para o seu negócio, em uma única plataforma',
  landingPageStartDescription:
    'Descubra como nossa solução de inteligência de dados de ponta-a-ponta ajuda grandes empresas a tomarem decisões precisas',
  landingPageStartVideoThumbImageAlt:
    'Tela do nosso produto com duas seções: models e feature store',
  landingPageContactTitle: 'Qual decisão você precisa tomar hoje?',
  landingPageContactDescription:
    'Nossa plataforma irá te ajudar a mapear suas principais alavancas de crescimento e redução de risco',
};
