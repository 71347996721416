export const LandingPagePlansContentENUSTranslations = {
  landingPagePlansCallout: 'OFFERINGS',
  landingPagePlansTitle: 'Tailored plans to fit your needs',

  landingPagePlansStarterTitle: 'Data Starter',
  landingPagePlansStarterDescription:
    'Up–to–date economic data and projections from many public and private sources, easy to use and prepared for you, all in one place.',
  landingPagePlansStarterBenefits: 'Individuals',

  landingPagePlansEssentialTitle: 'Essential',
  landingPagePlansEssentialDescription:
    'As well as accessing data, you can deliver fast, accurate predictive models and AI–driven forecasting in–house, perfect for coders and no-coders.',
  landingPagePlansEssentialBenefits: 'Individuals',

  landingPagePlansBusinessTitle: 'Business Critical',
  landingPagePlansBusinessDescription:
    'All–in–one data and modelling platform giving teams a single location to collaborate, govern and record the entire decision–making process.',
  landingPagePlansBusinessBenefits: 'Teams',

  landingPagePlansIdealFor: 'Ideal for:',
  landingPagePlansButton: 'See the plan',
};
