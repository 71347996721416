import styled, { keyframes } from 'styled-components';

type ContainerProps = {
  visible: boolean;
};

const start = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity:1;
  }
`;

export const Container = styled.div<ContainerProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')} !important;
  flex-direction: column;
  text-align: start;

  margin-top: 1.5rem;

  animation: ${start} 0.5s;

  background: ${({ theme }) => theme.colors.white};

  cursor: auto;
`;

export const MenuContent = styled.div`
  a + a {
    margin-top: 1.5rem;
  }
`;

export const Item = styled.a`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  align-items: center;

  :hover {
    h2 {
      color: ${({ theme }) => theme.colors.gray9};
    }

    p {
      color: ${({ theme }) => theme.colors.gray7};
    }
  }
`;

type IconProps = {
  color: string;
};

export const Icon = styled.div<IconProps>`
  width: 3.375rem;
  height: 3.375rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ color }) => `${color}1F`};
  border-radius: 8px;

  svg {
    width: 1.75rem;
    height: 1.75rem;

    color: ${({ color }) => color};
  }
`;

export const Information = styled.div`
  text-align: start;
  flex: 1;

  h2 {
    color: ${({ theme }) => theme.colors.gray7};

    font-size: 1.125rem;
    font-weight: 600;

    line-height: 1.6875rem;
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};

    font-size: 0.875rem;
    font-weight: 400;

    line-height: 1.6875rem;
  }
`;
