import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  justify-content: center;

  padding: 5rem 7rem;
  margin-bottom: 0 !important;

  @media (max-width: 500px) {
    padding: 5rem 2rem;
  }
`;

export const Content = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8rem;

  > div {
    width: 42%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 1rem;

    > div {
      width: 100%;
    }
  }
`;

export const LeftContent = styled.div`
  height: fit-content;

  h2 {
    width: 100%;

    font-size: 2.5rem;
    font-weight: 600;
    line-height: 150%;

    margin-bottom: 1.5rem;
  }

  p {
    width: 80%;

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
  }

  h2,
  p {
    color: ${({ theme }) => theme.colors.white};
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    width: fit-content;
    height: fit-content;

    padding: 1rem 2rem;

    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.white};

    color: ${({ theme }) => theme.colors.white};

    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6875rem;

    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    transition: all 0.2s;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.primaryDark};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.primaryDark};
        }
      }
    }
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 36rem;

  background-color: ${({ theme }) => theme.colors.primary};

  z-index: -1;
`;
