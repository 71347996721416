import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { ContactSales } from 'src/components/ContactSales';
import { Head } from 'src/components/Head';
import { LanguageWarning } from 'src/components/LanguageWarning';
import { Skeleton } from 'src/components/Skeleton';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { githubApi } from 'src/service/githubApi';
import { getPhosphorIcons } from 'src/utils/getPhosphorIcons';
import {
  TrackGoogleAnalyticsEvent,
  TrackGoogleAnalyticsPageView,
} from 'src/utils/google-analytics';

import { Card, Container, Content } from './styles';
import { DocumentationDataProps, ItemProps, MenuProps } from './types';

export function DocumentationMenu() {
  const navigate = useNavigate();
  const location = useLocation();

  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);
  const language = 'pt-br';

  useEffect(() => {
    TrackGoogleAnalyticsPageView(location.pathname, 'Tela da Central de Ajuda');
  }, [location.pathname]);

  const branch = process.env.REACT_APP_GITHUB_BRANCH;

  const { data, isLoading, isFetching } = useQuery(
    'documentation menu',
    async () => {
      const { data: response } = await githubApi.get<DocumentationDataProps>(
        `/4intelligence/documentation/contents/menu.json?ref=${branch}`,
      );

      const decoded = decodeURIComponent(
        atob(response.content)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      );

      return (JSON.parse(decoded) as unknown as MenuProps)?.menu;
    },
    {
      staleTime: 1000 * 60 * 60,
    },
  );

  function handleNavigate(menu: ItemProps) {
    let path = `${menu.path}`;

    let submenu: ItemProps[] | undefined = menu.submenu;

    while (submenu?.length) {
      path += `/${submenu?.[0].path}`;
      submenu = submenu[0].submenu;
    }

    navigate(path);
  }

  return (
    <Container>
      <Head
        title={`${translate('helpCenter')} | 4intelligence`}
        description={translate('documentationPageHeadDescription') ?? ''}
      />

      <Title>
        <h4>{translate('documentationPageSubtitle')}</h4>
        <h2>{translate('documentationPageTitle')}</h2>
      </Title>

      {urlPrefix === '/en' && <LanguageWarning />}

      {isLoading || isFetching ? (
        <Skeleton data-testid="loading-documentation-menu" />
      ) : (
        <Content>
          {data?.map((item) => (
            <Card key={item.path} data-testid={`documentation-${item.path}`}>
              <div>
                {getPhosphorIcons(item.icon, 'duotone')}
                <h2 data-testid={`${item.path}-name`}>{item.name[language]}</h2>
              </div>
              <p data-testid={`${item.path}-description`}>
                {item.description[language]}
              </p>

              <Button
                buttonType="naked"
                iconInvert
                onClick={() => {
                  TrackGoogleAnalyticsEvent(
                    item.name[language],
                    `Acessar documentação ${item.name[language]}`,
                    location.pathname,
                  );
                  handleNavigate(item);
                }}
                data-testid={`${item.path}-button`}
              >
                Acessar
              </Button>
            </Card>
          ))}
        </Content>
      )}

      <ContactSales />
    </Container>
  );
}
