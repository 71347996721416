export const LandingPageCasesContentENUSTranslations = {
  landingPageCasesSubtitle: 'Case studies',
  landingPageCasesTitle:
    'How we’ve improved decision-making in leading companies',

  landingPageCasesPersonalCareTitle:
    'Market projection to optimize S&OP process',
  landingPageCasesPersonalCareDescription:
    'Personal care and beauty multinational reduced bureaucracy in their sales and operations planning (S&OP) through more precise, accurate and agile projections.',

  landingPageCasesAutomotiveTitle:
    'Forecasting sales to optimize truck production',
  landingPageCasesAutomotiveDescription:
    'We helped an automobile multinational to predict future sales and achieve a 45.7% increase in truck sales.',

  landingPageCasesFoodTitle:
    'Predicting increased costs and optimizing pricing',
  landingPageCasesFoodDescription:
    'We helped a food multinational to complete sensitivity analysis and achieve a 26% increase in revenues.',

  landingPageCasesLogisticsTitle:
    'Production, export, consumption and inventory forecasting',
  landingPageCasesLogisticsDescription:
    'Logistics company improved production, export, consumption and inventory projections to achieve a 10% increase in total cargo transported.',
};
