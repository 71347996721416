import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';

import { CaretDown, Globe } from '@phosphor-icons/react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import brazilFlag from 'src/assets/brazil-flag.svg';
import usaFlag from 'src/assets/usa-flag.svg';
import { changeLanguage, Language } from 'src/redux/reducers/Config';
import { RootState } from 'src/redux/store';

import { Menu, MenuButton, SelectLanguage } from './styles';

export function LanguageMenu({ ...props }: HTMLAttributes<HTMLDivElement>) {
  const [languageMenuVisible, setLanguageMenuVisible] = useState(false);

  const navigate = useNavigate();

  const { t: translate } = useTranslation();

  const { language } = useSelector((state: RootState) => state.config);

  const dispatch = useDispatch();

  const menuRef = useRef<HTMLDivElement>(null);

  const handleChangeLanguage = (selectedLanguage: Language) => {
    i18next.changeLanguage(selectedLanguage);
    dispatch(changeLanguage(selectedLanguage));

    const currentPath = window.location.pathname;
    let newPath = currentPath;

    if (selectedLanguage === 'en-us') {
      newPath = currentPath.startsWith('/en')
        ? currentPath
        : `/en${currentPath}`;
    } else {
      newPath = currentPath.startsWith('/en')
        ? currentPath.replace(/^\/en/, '')
        : currentPath;
    }

    navigate(newPath);

    setLanguageMenuVisible(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (!menuRef?.current?.contains(e?.target as Node)) {
        setLanguageMenuVisible(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  return (
    <SelectLanguage
      ref={menuRef}
      openModal={languageMenuVisible}
      onClick={() => setLanguageMenuVisible(!languageMenuVisible)}
      className="language-button"
      role="button"
      aria-label={translate('languageMenuSelectButtonLabel') ?? ''}
      data-testid="language-button"
      {...props}
    >
      <Globe />

      <CaretDown />

      {languageMenuVisible && (
        <Menu visible>
          <MenuButton
            position="start"
            type="button"
            onClick={() => handleChangeLanguage('pt-br')}
            selected={language === 'pt-br'}
            data-testid="language-option-pt"
            aria-label={translate('languageMenuPortugueseButtonLabel') ?? ''}
          >
            <img
              src={brazilFlag}
              alt={translate('languageMenuBrasilFlagImageAlt') ?? ''}
            />

            <p>{translate('portuguese')}</p>
          </MenuButton>

          <MenuButton
            position="end"
            type="button"
            onClick={() => handleChangeLanguage('en-us')}
            selected={language === 'en-us'}
            data-testid="language-option-en"
            aria-label={translate('languageMenuEnglishButtonLabel') ?? ''}
          >
            <img
              src={usaFlag}
              alt={translate('languageMenuUSAFlagImageAlt') ?? ''}
            />

            <p>{translate('english')}</p>
          </MenuButton>
        </Menu>
      )}
    </SelectLanguage>
  );
}
