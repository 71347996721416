export const FormENUSTranslations = {
  formSubmittedImgAlt: 'Mailbox signaling that you will receive an e-mail',
  formSubmittedTitle: 'Thank you for your interest!',
  formSubmittedDescription: 'We will contact you shortly.',
  formPrivacyPolicyInfo:
    'By clicking on the button, I consent to the processing of personal data in accordance with the ',
  formPrivacyPolicy: 'Privacy Policy',

  formComponentTitle: '',
  formComponentDescription: '',
};
