export const LandingPagePlansContentPTBRTranslations = {
  landingPagePlansCallout: 'OFERTAS',
  landingPagePlansTitle:
    'Planos personalizados para atender às suas necessidades',

  landingPagePlansStarterTitle: 'Data Starter',
  landingPagePlansStarterDescription:
    'Dados econômicos e projeções atualizadas de diversas fontes públicas e privadas, fáceis de usar e preparadas para você, tudo em um só lugar.',
  landingPagePlansStarterBenefits: 'Indivíduos',

  landingPagePlansEssentialTitle: 'Essential',
  landingPagePlansEssentialDescription:
    'Além de acessar dados, você pode rapidamente construir modelos preditivos precisos, baseados em IA, perfeitos para pessoas técnicas e não técnicas.',
  landingPagePlansEssentialBenefits: 'Indivíduos',

  landingPagePlansBusinessTitle: 'Business Critical',
  landingPagePlansBusinessDescription:
    'Plataforma completa de dados e modelagem que oferece às equipes um único local para colaborar, governar e registrar todo o processo de tomada de decisão.',
  landingPagePlansBusinessBenefits: 'Equipes',

  landingPagePlansIdealFor: 'Ideal para:',
  landingPagePlansButton: 'Saiba mais',
};
