import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Container, Content, Tab, Tabs } from './styles';
import { CarouselProps } from './types';

export function CarouselTab({ data }: CarouselProps) {
  const [page, setPage] = useState(0);
  const [pageIsClicked, setPageIsClicked] = useState(false);

  const { t: translate } = useTranslation();

  useEffect(() => {
    const interval = setInterval(
      () => {
        const nextPage = page === data.length - 1 ? 0 : page + 1;

        setPage(nextPage);
        setPageIsClicked(false);
      },
      pageIsClicked ? 20000 : 12000,
    );

    return () => clearInterval(interval);
  }, [page, pageIsClicked, data]);

  return (
    <Container>
      <Tabs>
        {data.map((content, index) => (
          <Tab
            key={content.id}
            onClick={() => {
              setPageIsClicked(true);
              setPage(index);
            }}
            selected={page === index}
            color={content.color}
            aria-label={`${translate('carouselTabButtonLabel')} (${
              content.id
            })`}
          >
            {content.icon}
            <p>{content.name}</p>
          </Tab>
        ))}
      </Tabs>

      <Content>{data[page].render()}</Content>
    </Container>
  );
}
