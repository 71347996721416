import React, { useEffect } from 'react';

import { HeadProps } from './types';

export function Head({ title, description }: HeadProps) {
  useEffect(() => {
    const metaDescription = document.querySelector('meta[name=description');

    document.title = title;
    metaDescription?.setAttribute('content', description || '');
  }, [title, description]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
