import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Form } from 'src/components/Form';
import { Head } from 'src/components/Head';
import { WhatsappLogo } from 'src/components/Icons/Whatsapp';
import { RootState } from 'src/redux/store';
import {
  TrackGoogleAnalyticsEvent,
  TrackGoogleAnalyticsPageView,
} from 'src/utils/google-analytics';

import { Container, Content, LeftContent, Background } from './styles';

export function ContactSales() {
  const { t: translate } = useTranslation();
  const { language } = useSelector((state: RootState) => state.config);

  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(location.pathname, 'Tela de Falar com Vendas');
  }, [location.pathname]);

  return (
    <Container>
      <Head
        title={`${translate('bookDemo')} | 4intelligence`}
        description={translate('contactSalesPageHeadDescription') ?? ''}
      />

      <Background />

      <Content>
        <LeftContent>
          <h2>{translate('contactSalesPageTitle')}</h2>
          <p>{translate('contactSalesPageDescription')}</p>

          <Link
            to="https://api.whatsapp.com/send?phone=5531987888326&text=Olá, tenho interesse em conversar sobre a 4intelligence"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Falar pelo WhatsApp',
                'Falar pelo WhatsApp',
                location.pathname,
              );
            }}
          >
            <WhatsappLogo />
            {translate('contactSalesPageWhatsAppButton')}
          </Link>
        </LeftContent>

        <Form
          hubspotFormId={
            language === 'pt-br'
              ? process.env.REACT_APP_HUBSPOT_FORM_ID_PT || ''
              : process.env.REACT_APP_HUBSPOT_FORM_ID_EN || ''
          }
          hubspotPortalId={
            language === 'pt-br'
              ? process.env.REACT_APP_HUBSPOT_PORTAL_ID_PT || ''
              : process.env.REACT_APP_HUBSPOT_PORTAL_ID_EN || ''
          }
          privacyPolicy={process.env.REACT_APP_PRIVACY_POLICY ?? ''}
        />
      </Content>
    </Container>
  );
}
