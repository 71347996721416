import styled from 'styled-components';

interface Container {
  disabled?: boolean;
}

export const Container = styled.label<Container>`
  display: flex;
  align-items: center;
  padding-left: 1.75rem;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  p {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 400;

    display: inline;
  }

  input {
    display: none;
  }

  span {
    display: inline-block;
    width: 1.125rem;
    height: 1.125rem;
    background: white;
    position: absolute;
    left: 0;
    border: ${({ theme }) => `2px solid ${theme.colors.gray4}`};
    border-radius: 4px;
  }

  input:checked + span {
    background-color: ${({ theme }) => theme.colors.primary};
    border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
    border-radius: 4px;
  }

  input:indeterminate + span {
    background-color: #1390e5;
    border: ${({ theme }) => `2px solid ${theme.colors.gray8}`};
    border-radius: 4px;
  }

  input:checked + span:after {
    content: '';
    position: absolute;
    height: 0.313rem;
    width: 0.5rem;
    border-left: ${({ theme }) => `3px solid ${theme.colors.white}`};
    border-bottom: ${({ theme }) => `3px solid ${theme.colors.white}`};
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  input:checked:disabled + span {
    border: 1px solid grey;
    background-color: grey;
  }

  input:disabled + span {
    border: 1px solid grey;
  }

  input:indeterminate + span:after {
    content: '';
    position: absolute;
    height: 0px;
    width: 0.688rem;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
  }
`;
