import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AutomobilesThumb from 'src/assets/automobiles-case.jpg';
import FoodThumb from 'src/assets/food-case.jpg';
import HygieneThumb from 'src/assets/hygiene-case.png';
import LogisticsThumb from 'src/assets/logistics-case.jpg';
// import RetailThumb from 'src/assets/retail-case.jpg';
import { ContactSales } from 'src/components/ContactSales';
import { Head } from 'src/components/Head';
import { LanguageWarning } from 'src/components/LanguageWarning';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import {
  TrackGoogleAnalyticsEvent,
  TrackGoogleAnalyticsPageView,
} from 'src/utils/google-analytics';

import { CaseContainer, CaseContent, Container } from './styles';

export function Cases() {
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(location.pathname, 'Tela de Cases');
  }, [location.pathname]);

  return (
    <Container>
      <Head
        title={`${translate('cases')} | 4intelligence`}
        description={translate('casesPageHeadDescription') ?? ''}
      />

      <Title>
        <h4>Cases</h4>
        <h2>Entenda mais sobre os casos de uso da nossa plataforma</h2>
      </Title>

      {urlPrefix === '/en' && <LanguageWarning />}

      <CaseContainer>
        <CaseContent>
          <div>
            <img src={FoodThumb} alt="food thumb" />

            <h3>Case multinacional de alimentos</h3>
            <h2>Prevendo aumento nos custos e otimizando o pricing</h2>
          </div>

          <Link
            to="/cases/food"
            data-testid="food-case-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Ver o case - Alimentos',
                'Acessar o case de alimentos',
                location.pathname,
              );
            }}
          >
            Ver o case
          </Link>
        </CaseContent>

        <CaseContent>
          <div>
            <img src={AutomobilesThumb} alt="automobiles thumb" />

            <h3>Case multinacional de automóveis</h3>
            <h2>Projeção de vendas para otimizar a produção de caminhões</h2>
          </div>

          <Link
            to="/cases/automobiles"
            data-testid="automobiles-case-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Ver o case - Automóveis',
                'Acessar o case de automóveis',
                location.pathname,
              );
            }}
          >
            Ver o case
          </Link>
        </CaseContent>

        <CaseContent>
          <div>
            <img src={HygieneThumb} alt="hygiene thumb" />

            <h3>Case multinacional de higiene e beleza</h3>
            <h2>Projeção de mercado para otimizar o processo de S&OP</h2>
          </div>

          <Link
            to="/cases/hygiene-beauty"
            data-testid="hygiene-beauty-case-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Ver o case - Higiene e Beleza',
                'Acessar o case de higiene e beleza',
                location.pathname,
              );
            }}
          >
            Ver o case
          </Link>
        </CaseContent>

        <CaseContent>
          <div>
            <img src={LogisticsThumb} alt="logistics thumb" />

            <h3>Case empresa de logística</h3>
            <h2>Projeção de produção, exportação, consumo e estoque</h2>
          </div>

          <Link
            to="/cases/logistics"
            data-testid="logistics-case-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Ver o case - Logística',
                'Acessar o case de logística',
                location.pathname,
              );
            }}
          >
            Ver o case
          </Link>
        </CaseContent>

        {/* <CaseContent>
          <img src={RetailThumb} alt="retail thumb" />

          <h3>Case varejo</h3>
          <h2>Expansão de rede de lojas de vestuário pelo Brasil</h2>

          <Link to="/cases/retail" data-testid="retail-case-button">
            Ver o case
          </Link>
        </CaseContent> */}
      </CaseContainer>

      <ContactSales />
    </Container>
  );
}
