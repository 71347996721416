export const FreemiumSignUpPTBRTranslations = {
  freemiumSignUpPageHeadTitle: 'Cadastro Freemium | 4intelligence',
  freemiumSignUpPageHeadDescription:
    'Crie sua conta para começar a usar nossa plataforma freemium',

  freemiumSignUpPageTitle:
    'Crie sua conta para começar a usar nossa plataforma',
  freemiumSignUpPageDescription:
    'Preencha os campos abaixo para iniciar seu cadastro de forma gratuita.',
  freemiumSignUpPageCreateAccount: 'Criar conta',

  freemiumSignUpPageNameFieldLabel: 'Nome',
  freemiumSignUpPageNameFieldPlaceholder: 'Digite seu nome completo',

  freemiumSignUpPageCompanyFieldLabel: 'Empresa',
  freemiumSignUpPageCompanyFieldPlaceholder:
    'Digite a empresa que você trabalha',

  freemiumSignUpPageSectorFieldLabel: 'Setor',
  freemiumSignUpPageSectorFieldPlaceholder: 'Escolha o setor da sua empresa',

  freemiumSignUpPageDepartmentFieldLabel: 'Departamento',
  freemiumSignUpPageDepartmentFieldPlaceholder:
    'Escolha seu departamento na empresa',

  freemiumSignUpPagePositionFieldLabel: 'Cargo',
  freemiumSignUpPagePositionFieldPlaceholder: 'Digite seu cargo na empresa',

  freemiumSignUpPageEmailFieldLabel: 'E-mail',
  freemiumSignUpPageEmailFieldPlaceholder: 'Digite seu e-mail corporativo',

  freemiumSignUpPageConfirmEmailFieldLabel: 'Confirme seu e-mail',
  freemiumSignUpPageConfirmEmailFieldPlaceholder: 'Digite seu e-mail novamente',

  freemiumSignUpPagePhoneFieldLabel: 'Telefone (opcional)',
  freemiumSignUpPagePhoneFieldPlaceholder: 'Digite seu telefone de contato',

  freemiumSignUpPageSector1: 'Agricultura e Agronegócio',
  freemiumSignUpPageSector2: 'Consultoria e Serviços Profissionais',
  freemiumSignUpPageSector3: 'Energia e Recursos Naturais',
  freemiumSignUpPageSector4: 'Finanças e Serviços Financeiros',
  freemiumSignUpPageSector5: 'Governo e Setor Público',
  freemiumSignUpPageSector6: 'Infraestrutura',
  freemiumSignUpPageSector7: 'Manufatura e Produção - Alimentos e Bebidas',
  freemiumSignUpPageSector8: 'Manufatura e Produção - Automotiva',
  freemiumSignUpPageSector9:
    'Manufatura e Produção - Eletrônicos e Eletrodomésticos',
  freemiumSignUpPageSector10: 'Manufatura e Produção - Higiene e Beleza',
  freemiumSignUpPageSector11: 'Manufatura e Produção - Químicos e Materiais',
  freemiumSignUpPageSector12: 'Manufatura e Produção - Vestuário e Têxtil',
  freemiumSignUpPageSector13: 'Manufatura e Produção - Outros',
  freemiumSignUpPageSector14: 'Transporte e Logística',
  freemiumSignUpPageSector15: 'Varejo',
  freemiumSignUpPageSector16: 'Outros',

  freemiumSignUpPageDepartment1: 'Analytics',
  freemiumSignUpPageDepartment2: 'Compras / Supply',
  freemiumSignUpPageDepartment3: 'Financeiro',
  freemiumSignUpPageDepartment4: 'Marketing',
  freemiumSignUpPageDepartment5: 'Planejamento',
  freemiumSignUpPageDepartment6: 'S&OP',
  freemiumSignUpPageDepartment7: 'Vendas',
  freemiumSignUpPageDepartment8: 'Outros',

  freemiumSignUpPageAgreement1: 'Eu li e concordo com os ',
  freemiumSignUpPageAgreement2: 'Termos de Uso',
  freemiumSignUpPageAgreement3: '. Leia nossa ',
  freemiumSignUpPageAgreement4: 'Política de Privacidade.',

  freemiumSignUpPageFirstCarouselTitle:
    'Explore mais de 60 indicadores prontos para uso',
  freemiumSignUpPageFirstCarouselDescription:
    'Acesse dados de diversas fontes com inteligência de ponta-a-ponta através de uma única plataforma. Tenha informações prontas para uso sobre setores vitais da economia, como atividade econômica, preços, juros, commodities e mais.',
  freemiumSignUpPageFirstCarouselPageButton:
    'Botão da primeira página do carrossel',

  freemiumSignUpPageFirstCarouselIndicator1:
    'Índice de Preços ao Consumidor Amplo - IPCA',
  freemiumSignUpPageFirstCarouselIndicator2:
    'Temperatura Máxima do Ar (capitais)',
  freemiumSignUpPageFirstCarouselIndicator3:
    'Arrecadação Tributária e Previdenciária',
  freemiumSignUpPageFirstCarouselIndicator4:
    'Taxa de Câmbio - BRL/USD - Venda - Ptax',
  freemiumSignUpPageFirstCarouselIndicator5: 'Taxa Selic - Meta',

  freemiumSignUpPageSecondCarouselTitle:
    'Antecipe-se ao futuro com projeções feitas por algoritmos preditivos de AI',
  freemiumSignUpPageSecondCarouselDescription:
    'Utilize nossas previsões precisas, impulsionadas por tecnologia de ponta e validadas por especialistas, para transformar incertezas em oportunidades. Conte sempre com projeções atualizadas frequentemente.',
  freemiumSignUpPageSecondCarouselPageButton:
    'Botão da segunda página do carrossel',

  freemiumSignUpPageThirdCarouselTitle:
    'Simplifique informações complexas através de conversas produtivas e fáceis',
  freemiumSignUpPageThirdCarouselDescription:
    'Utilize o Chat 4i, o chatGPT da 4intelligence, para explorar a conexão entre os dados e seu negócio de forma intuitiva e dinâmica, democratizando o uso de dados no dia a dia.',
  freemiumSignUpPageThirdCarouselPageButton:
    'Botão da terceira página do carrossel',

  freemiumSignUpPageRequiredField:
    'Por favor, preencha todos os campos obrigatórios antes de confirmar.',
  freemiumSignUpPageInvalidEmail: 'O e-mail deve ser formatado corretamente.',
  freemiumSignUpPageIsNotCompanyEmail: 'Use um e-mail corporativo.',
  freemiumSignUpPageDifferentEmail:
    'E-mails não coincidem. Por favor, verifique.',
  freemiumSignUpPageEmailAlreadyExists:
    'Esse e-mail já está vinculado a uma conta. <a href="https://app.4intelligence.ai/" target="_blank" rel="noreferrer">Faça login</a>.',

  nameErrorNoSpecialCharacters:
    'Caracteres especiais e números não são permitidos.',
  charactersLengthError: 'Não deve conter mais do que 100 caracteres.',
  invalidPhoneNumber: 'Insira um número de telefone válido.',

  freemiumSignUpPageCreatedTitle: 'Enviamos um e-mail para o endereço ',
  freemiumSignUpPageCreatedDescription:
    'Acesse para criar a sua senha e fazer o primeiro login na nossa plataforma.',
  freemiumSignUpPageCreatedButton: 'Esse não é meu e-mail',
};
