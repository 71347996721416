import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 2.25rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;

  box-shadow: 0px 4px 64px 0px rgba(113, 128, 150, 0.16);

  @media (max-width: 1100px) {
    padding: 3rem;
  }

  @media (max-width: 500px) {
    padding: 2rem;
  }
`;

export const FormContent = styled.div`
  width: 100%;

  font: 400 0.875rem Inter, sans-serif;

  form,
  input {
    width: 100%;
  }

  label {
    display: block;

    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 500;

    margin-bottom: 0.5rem !important;

    span + span {
      margin-left: 0.25rem;
    }
  }

  fieldset + fieldset {
    margin-top: 1.5rem;
  }

  textarea {
    height: 10rem;
  }

  input,
  textarea {
    background: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.gray2};

    font-size: 0.875rem !important;
    color: ${({ theme }) => theme.colors.gray6};

    padding: 0.75rem 1rem !important;

    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.colors.gray5};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray4};
    }
  }

  input[type='submit'],
  textarea[type='submit'] {
    width: 100%;

    background: ${({ theme }) => theme.colors.primary};
    border: none;
    border-radius: 0.5rem;

    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};

    padding: 1rem 1.5rem;
    margin-top: 2.5rem;

    transition: all 0.2s;

    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  .hs-form-field + .hs-form-field {
    margin-top: 1.5rem;
  }

  .form-columns-1,
  .form-columns-2 {
    > div {
      width: 100% !important;

      > div {
        margin: 0 !important;
      }
    }
  }

  .form-columns-2 {
    display: flex;
    flex-direction: column;

    > div + div {
      margin-top: 1.5rem;
    }
  }

  .hs-error-msgs {
    margin-top: 0.5rem;

    label {
      font-weight: 400;
      color: ${({ theme }) => theme.colors.red2} !important;
    }
  }

  .hs_error_rollup {
    margin-bottom: -2rem;
  }
`;

export const Informations = styled.div`
  text-align: center;
  margin-top: 1.5rem;

  p,
  a {
    width: 100%;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;

    color: ${({ theme }) => theme.colors.gray5};
  }

  a {
    cursor: pointer;

    text-decoration-line: underline;
    text-underline-offset: 0.25rem;

    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  @media (max-width: 500px) {
    margin-top: 1rem;

    p,
    a {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
`;

export const SubmittedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  img {
    width: 13rem;
  }

  h2 {
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray7};

    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }

  p {
    width: 75%;

    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6875rem;

    color: ${({ theme }) => theme.colors.gray5};
  }

  @media (max-width: 1366px) {
    img {
      width: 10rem;
    }

    h2 {
      font-size: 1.5rem;

      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      line-height: 150%;
    }
  }

  @media (max-width: 500px) {
    img {
      width: 8rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    p {
      width: 80%;
      font-size: 1rem;
      line-height: 150%;
    }
  }
`;
