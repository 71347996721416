import React from 'react';

import { useTranslation } from 'react-i18next';
import roiBackground from 'src/assets/roi-background.svg';
import { Title } from 'src/components/Title/styles';

import { Counter } from './components/Counter';
import { Container, Content, Value } from './styles';

export function ROI() {
  const { t: translate } = useTranslation();

  return (
    <Container style={{ backgroundImage: `url(${roiBackground})` }}>
      <Title>
        <h4>{translate('landingPageRoiImpactSubtitle')}</h4>
      </Title>

      <Content>
        <Value>
          <Counter finalValue={10} />
          <h3>{translate('landingPageRoiImpactValue1Title')}</h3>
          <p>{translate('landingPageRoiImpactValue1Description')}</p>
        </Value>

        <Value>
          <Counter finalValue={26} />
          <h3>{translate('landingPageRoiImpactValue2Title')}</h3>
          <p>{translate('landingPageRoiImpactValue2Description')}</p>
        </Value>

        <Value>
          <Counter finalValue={45} />
          <h3>{translate('landingPageRoiImpactValue3Title')}</h3>
          <p>{translate('landingPageRoiImpactValue3Description')}</p>
        </Value>
      </Content>

      <small>{translate('landingPageRoiImpactSmallMessage')}</small>
    </Container>
  );
}
