import React from 'react';

import { Database, Graph, UsersThree, ArrowDown } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';

import { Container, Content, Card, Benefits } from './styles';

export function Plans() {
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const location = useLocation();

  return (
    <Container>
      <Title>
        <h4>{translate('landingPagePlansCallout')}</h4>
        <h3>{translate('landingPagePlansTitle')}</h3>
      </Title>

      <Content>
        <Card index={0}>
          <div className="icon">
            <Database />
          </div>

          <h2>{translate('landingPagePlansStarterTitle')}</h2>
          <p>{translate('landingPagePlansStarterDescription')}</p>

          <Benefits index={0}>
            <div className="idealIcon">
              <ArrowDown weight="bold" />
            </div>

            <Title>
              <span>{translate('landingPagePlansIdealFor')}</span>
            </Title>

            <p>{translate('landingPagePlansStarterBenefits')}</p>
          </Benefits>

          <Link
            to={`${urlPrefix}/plans`}
            data-cy="plans-button"
            data-testid="plans-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Home - Planos - Starter',
                'Acessar planos',
                location.pathname,
              );
            }}
          >
            {translate('landingPagePlansButton')}
          </Link>
        </Card>

        <Card index={1}>
          <div className="icon">
            <Graph />
          </div>

          <h2>{translate('landingPagePlansEssentialTitle')}</h2>
          <p>{translate('landingPagePlansEssentialDescription')}</p>

          <Benefits index={1}>
            <div className="idealIcon">
              <ArrowDown weight="bold" />
            </div>

            <Title>
              <span>{translate('landingPagePlansIdealFor')}</span>
            </Title>

            <p>{translate('landingPagePlansEssentialBenefits')}</p>
          </Benefits>

          <Link
            to={`${urlPrefix}/plans`}
            data-cy="plans-button"
            data-testid="plans-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Home - Planos - Essential',
                'Acessar planos',
                location.pathname,
              );
            }}
          >
            {translate('landingPagePlansButton')}
          </Link>
        </Card>

        <Card index={2}>
          <div className="icon">
            <UsersThree />
          </div>

          <h2>{translate('landingPagePlansBusinessTitle')}</h2>
          <p>{translate('landingPagePlansBusinessDescription')}</p>

          <Benefits index={2}>
            <div className="idealIcon">
              <ArrowDown weight="bold" />
            </div>

            <Title>
              <span>{translate('landingPagePlansIdealFor')}</span>
            </Title>

            <p>{translate('landingPagePlansBusinessBenefits')}</p>
          </Benefits>

          <Link
            to={`${urlPrefix}/plans`}
            data-cy="plans-button"
            data-testid="plans-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Home - Planos - Business',
                'Acessar planos',
                location.pathname,
              );
            }}
          >
            {translate('landingPagePlansButton')}
          </Link>
        </Card>
      </Content>
    </Container>
  );
}
