/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import clientPlaceholderImage from 'src/assets/client-placeholder.svg';
import section1ImageENUS from 'src/assets/product/data/1-en.svg';
import section1ImagePTBR from 'src/assets/product/data/1-pt.svg';
import section2Background from 'src/assets/product/data/2-background.svg';
import section2ImageENUS from 'src/assets/product/data/2-en.svg';
import section2ImagePTBR from 'src/assets/product/data/2-pt.svg';
import section3VectorENUS from 'src/assets/product/data/3-en.svg';
import section3Image from 'src/assets/product/data/3-image.png';
import section3VectorPTBR from 'src/assets/product/data/3-pt.svg';
import section4ImageENUS from 'src/assets/product/data/4-en.svg';
import section4ImagePTBR from 'src/assets/product/data/4-pt.svg';
import section5Image from 'src/assets/product/data/5.svg';
import heroVideoENUS from 'src/assets/product/data/hero-en.mp4';
import heroVideoPTBR from 'src/assets/product/data/hero-pt.mp4';
import client1Image from 'src/assets/product/data/testimonials/client1.png';
import client2Image from 'src/assets/product/data/testimonials/client2.png';
import { ContactSales } from 'src/components/ContactSales';
import { Head } from 'src/components/Head';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';
import { useTheme } from 'styled-components';

import {
  Container,
  Content,
  Hero,
  HeroContent,
  HeroButtons,
  Column,
  Row,
  BackgroundSection,
  BackgroundImageSection,
} from '../components/Module/styles';
import { Testimonials } from '../components/Testimonials';
import { VectorImage } from '../components/VectorImage';

export function ProductEconomicData() {
  const { t: translate } = useTranslation();
  const { urlPrefix, language } = useSelector(
    (state: RootState) => state.config,
  );

  const location = useLocation();
  const theme = useTheme();

  const [heroVideoSource, setHeroVideoSource] = useState('');
  const heroVideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setHeroVideoSource(language === 'pt-br' ? heroVideoPTBR : heroVideoENUS);
    heroVideoRef.current?.load();
  }, [heroVideoSource, language]);

  return (
    <Container>
      <Head
        title={`${translate('productData')} | 4intelligence`}
        description={translate('productDataHeroDescription') ?? ''}
      />

      <Content>
        <Hero backgroundType="color">
          <HeroContent>
            <div>
              <Title>
                <h2>{translate('productDataHeroTitle')}</h2>
              </Title>

              <p>{translate('productDataHeroDescription')}</p>

              <HeroButtons
                color={theme.colors.white}
                hover={theme.colors.white}
              >
                <Link
                  to={`${urlPrefix}/contact-sales`}
                  onClick={() => {
                    TrackGoogleAnalyticsEvent(
                      'Falar com vendas',
                      'Ir para tela Falar com Vendas',
                      location.pathname,
                    );
                  }}
                >
                  {translate('productDataHeroButtonPrimary')}
                </Link>
                <Link
                  to={`${urlPrefix}/plans`}
                  onClick={() => {
                    TrackGoogleAnalyticsEvent(
                      'Ver planos',
                      'Ir para tela de planos',
                      location.pathname,
                    );
                  }}
                >
                  {translate('productDataHeroButtonSecondary')}
                </Link>
              </HeroButtons>
            </div>

            <video
              ref={heroVideoRef}
              autoPlay
              loop
              style={{ pointerEvents: 'none' }}
              muted
            >
              <source src={heroVideoSource} />
            </video>
          </HeroContent>
        </Hero>

        <Column>
          <div>
            <Title>
              <h2>{translate('productDataSection1Title')}</h2>
            </Title>
            <p>{translate('productDataSection1Description')}</p>
          </div>

          <img
            src={language === 'pt-br' ? section1ImagePTBR : section1ImageENUS}
            alt={translate('productDataSection1ImageAlt') ?? ''}
          />

          {/* <video autoPlay loop>
            <source
              src={urlPrefix === '/en' ? section1VideoENUS : section1VideoPTBR}
            />
            <track
              src="captions_en.vtt"
              kind="captions"
              srcLang="en"
              label="english_captions"
            />
          </video> */}
        </Column>

        <BackgroundImageSection
          color={theme.colors.primary}
          style={{ backgroundImage: `url(${section2Background})` }}
        >
          <Row>
            <div>
              <Title>
                <h2>{translate('productDataSection2Title')}</h2>
              </Title>
              <p>{translate('productDataSection2Description')}</p>
            </div>

            <img
              src={language === 'pt-br' ? section2ImagePTBR : section2ImageENUS}
              alt={translate('productDataSection2ImageAlt') ?? ''}
            />
          </Row>
        </BackgroundImageSection>

        <Row>
          {/* <video autoPlay loop>
            <source
              src={language === 'pt-br' ? section3VideoPTBR : section3VideoENUS}
            />
            <track
              src="captions_en.vtt"
              kind="captions"
              srcLang="en"
              label="english_captions"
            />
          </video> */}

          <VectorImage
            under={section3Image}
            over={
              language === 'pt-br' ? section3VectorPTBR : section3VectorENUS
            }
            alt={translate('productDataSection3ImageAlt') ?? ''}
          />

          <div>
            <Title>
              <h2>{translate('productDataSection3Title')}</h2>
            </Title>
            <p>{translate('productDataSection3Description')}</p>
          </div>
        </Row>

        <Row>
          <div>
            <Title>
              <h2>{translate('productDataSection4Title')}</h2>
            </Title>
            <p>{translate('productDataSection4Description')}</p>
          </div>

          <img
            src={language === 'pt-br' ? section4ImagePTBR : section4ImageENUS}
            alt={translate('productDataSection4ImageAlt') ?? ''}
          />
        </Row>

        <BackgroundSection color={theme.colors.primary}>
          <Row>
            <img
              src={section5Image}
              alt={translate('productDataSection5ImageAlt') ?? ''}
            />

            <div>
              <Title>
                <h2>{translate('productDataSection5Title')}</h2>
              </Title>
              <p>{translate('productDataSection5Description')}</p>
            </div>
          </Row>
        </BackgroundSection>

        <Testimonials
          dotsColor={theme.colors.primary}
          testimonials={[
            {
              name: translate('productDataTestimonialsClient1Name'),
              jobDescription: translate(
                'productDataTestimonialsClient1JobDescription',
              ),
              company: translate('productDataTestimonialsClient1Company'),
              image: client1Image,
              quote: translate('productDataTestimonialsClient1Quote'),
            },
            {
              name: translate('productDataTestimonialsClient2Name'),
              jobDescription: translate(
                'productDataTestimonialsClient2JobDescription',
              ),
              company: translate('productDataTestimonialsClient2Company'),
              image: client2Image,
              quote: translate('productDataTestimonialsClient2Quote'),
            },
            {
              name: translate('productDataTestimonialsClient3Name'),
              jobDescription: translate(
                'productDataTestimonialsClient3JobDescription',
              ),
              company: translate('productDataTestimonialsClient3Company'),
              image: clientPlaceholderImage,
              quote: translate('productDataTestimonialsClient3Quote'),
            },
          ]}
        />
      </Content>

      <ContactSales
        content={{
          title: translate('productDataContactSalesTitle') ?? '',
          button: translate('productDataContactSalesButton') ?? '',
        }}
        color={{
          normal: theme.colors.primary,
          hover: theme.colors.primaryDark,
        }}
      />
    </Container>
  );
}
