import React from 'react';

import parseHTML from 'html-react-parser';

import { Container } from './styles';
import { CheckBoxProps } from './types';

export function CheckBox({
  label,
  style,
  className,
  disabled = false,
  ...rest
}: CheckBoxProps) {
  return (
    <Container style={style} className={className} disabled={disabled}>
      <p>{parseHTML(label ?? '')}</p>
      <input type="checkbox" disabled={disabled} {...rest} />
      <span />
    </Container>
  );
}
