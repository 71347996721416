export const CommonTextsENUSTranslations = {
  contactButton: 'Book a Demo',
  bookDemo: 'Book a Demo',
  plans: 'Plans',
  product: 'Product',
  cases: 'Cases',
  resource: 'Resources',
  accessButton: 'Sign In',
  requiredField: 'Required field',
  privacyPolicy: 'Privacy Policy',
  termsOfUse: 'Terms of Use',
  english: 'English',
  portuguese: 'Portuguese',

  productData: 'Economic Data',
  productModels: 'AI Models',
  productPlanning: 'Integrated Planning',

  casesFood: 'Food & Beverage',
  casesAutomotive: 'Automotive',
  casesLogistics: 'Logistics',
  casesPersonalCare: 'Personal Care & Beauty',

  helpCenter: 'Help Center',
  about: 'About',

  learnMore: 'Learn more',

  blog: 'Blog',
};

export const CommonTextsPTBRTranslations = {
  contactButton: 'Fale conosco',
  bookDemo: 'Agende uma demonstração',
  plans: 'Planos',
  product: 'Produto',
  cases: 'Cases',
  resource: 'Recursos',
  accessButton: 'Acesse a plataforma',
  requiredField: 'Campo obrigatório',
  privacyPolicy: 'Política de privacidade',
  termsOfUse: 'Termos de uso',
  english: 'Inglês',
  portuguese: 'Português',

  productData: 'Economic Data',
  productModels: 'AI Models',
  productPlanning: 'Integrated Planning',

  casesFood: 'Alimentos e bebidas',
  casesAutomotive: 'Automotivo',
  casesLogistics: 'Logística',
  casesPersonalCare: 'Higiene e beleza',

  helpCenter: 'Central de ajuda',
  about: 'Sobre',

  learnMore: 'Saiba mais',

  blog: 'Blog',
};
