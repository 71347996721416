import React, { useEffect, useState } from 'react';

import { CaretUp, List } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import logo from 'src/assets/4i-logo.svg';
import { Button } from 'src/components/Button';
import { LanguageMenu } from 'src/components/LanguageMenu';
import { MobileMenu } from 'src/components/MobileMenu';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';
import { scrollTo } from 'src/utils/scrollTo';

import {
  ActionContent,
  Container,
  MenuContent,
  Tab,
  MainContent,
} from './styles';
import { Submenu } from './Submenu';
import { getMenuItems } from '../../MenuItems';

export function Header() {
  const [openMenu, setOpenMenu] = useState(false);

  const [openCases, setOpenCases] = useState(false);
  const [openResources, setOpenResources] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);

  const { urlPrefix } = useSelector((state: RootState) => state.config);
  const { t: translate } = useTranslation();

  const location = useLocation();

  const { casesMenu, productMenu, resourceMenu } = getMenuItems();

  useEffect(() => {
    const header = document.getElementById('header');

    function setHeaderBackground() {
      const scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.scrollY;

      if (header) {
        header.style.background =
          scrollTop !== 0 ? light.colors.white : 'transparent';
      }
    }

    window.addEventListener('scroll', setHeaderBackground);

    return () => {
      window.removeEventListener('scroll', setHeaderBackground);
    };
  }, [location]);

  return (
    <Container id="header" data-cy="header" data-testid="header">
      <MainContent>
        <MenuContent>
          <Link
            to={`${urlPrefix}/`}
            target="_self"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Header - Logo da 4intelligence',
                'Ir para a página inicial',
                location.pathname,
              );

              scrollTo(true, 0, 100);
            }}
            data-cy="logo-button"
            data-testid="logo-button"
            aria-label={translate('header4iButtonLabel') ?? ''}
          >
            <img src={logo} alt="4intelligence logo" />
          </Link>

          <nav className="header-nav">
            <Link
              to={`${urlPrefix}/plans`}
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'Header - Planos',
                  'Ir para a tela de planos',
                  location.pathname,
                );
              }}
              data-testid="plans-button"
            >
              {translate('plans')}
            </Link>

            <Tab
              onMouseEnter={() => setOpenProduct(true)}
              onMouseLeave={() => setOpenProduct(false)}
              onClick={() => setOpenProduct(!openCases)}
            >
              <div>
                {translate('product')}
                <CaretUp
                  className={openProduct ? 'show-menu' : 'hidden-menu'}
                />
              </div>

              <Submenu
                menu={productMenu}
                visible={openProduct}
                triangleLeftPosition="1.5rem"
                style={{ marginLeft: '-8rem' }}
              />
            </Tab>

            <Tab
              onMouseEnter={() => setOpenCases(true)}
              onMouseLeave={() => setOpenCases(false)}
              onClick={() => setOpenCases(!openCases)}
            >
              <div>
                {translate('cases')}
                <CaretUp className={openCases ? 'show-menu' : 'hidden-menu'} />
              </div>

              <Submenu
                menu={casesMenu}
                visible={openCases}
                triangleLeftPosition="1.5rem"
                style={{ marginLeft: '-8rem' }}
              />
            </Tab>

            <Tab
              onMouseEnter={() => setOpenResources(true)}
              onMouseLeave={() => setOpenResources(false)}
              onClick={() => setOpenResources(!openResources)}
            >
              <div>
                {translate('resource')}
                <CaretUp
                  className={openResources ? 'show-menu' : 'hidden-menu'}
                />
              </div>

              <Submenu
                menu={resourceMenu}
                visible={openResources}
                style={{ marginLeft: '-2rem' }}
              />
            </Tab>
          </nav>
        </MenuContent>

        <ActionContent>
          <LanguageMenu />

          <Link
            to="https://app.4intelligence.ai/"
            target="_blank"
            rel="noreferrer"
            className="login-button"
            data-cy="login-button"
            data-testid="login-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Acessar plataforma',
                'Acessar plataforma',
                location.pathname,
              );
            }}
          >
            {translate('accessButton')}
          </Link>

          <Link
            to={`${urlPrefix}/contact-sales`}
            className="contact-button"
            data-cy="contact-button"
            data-testid="contact-button"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Falar com vendas',
                'Ir para tela Falar com Vendas',
                location.pathname,
              );
            }}
          >
            {translate('contactButton')}
          </Link>

          <Button
            buttonType="secondary"
            icon={<List />}
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Menu mobile',
                'Abrir menu mobile',
                location.pathname,
              );
              setOpenMenu(true);
            }}
            className="menu-button"
            data-cy="menu-button"
            data-testid="menu-button"
            aria-label={translate('headerMenuButtonLabel') ?? ''}
          />
        </ActionContent>
      </MainContent>

      <MobileMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
    </Container>
  );
}
