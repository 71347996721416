import { DefaultTheme } from 'styled-components';

const light: DefaultTheme = {
  colors: {
    primaryLight: '#81B3FF',
    primary: '#4C94FF',
    primaryDark: '#2265C9',

    secondaryLight: '#FFABD3',
    secondary: '#F27DB4',
    secondaryDark: '#C74F88',

    green4: '#48BB78',
    green6: '#2F855A',

    orange4: '#ED8936',

    purple4: '#9F7AEA',
    purple6: '#6B46C1',

    red2: '#ff6b6b',
    red3: '#FC8181',

    teal4: '#38B2AC',

    cyan4: '#0BC5EA',

    yellow4: '#ECC94B',

    white: '#ffffff',
    gray0: '#F7FAFC',
    gray1: '#EDF2F7',
    gray2: '#E2E8F0',
    gray3: '#CBD5E0',
    gray4: '#A0AEC0',
    gray5: '#718096',
    gray6: '#4A5568',
    gray7: '#2D3748',
    gray8: '#1A202C',
    gray9: '#171923',
    black: '#000000',
  },
  maxWidth: '1360px',
};

export default light;
