import React, { useState } from 'react';

import {
  ArrowUpRight,
  Car,
  ForkKnife,
  HandSoap,
  Truck,
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AutomobilesIllustration from 'src/assets/automobiles-case-illustration.svg';
import FoodIllustration from 'src/assets/food-case-illustration.svg';
import HygieneIllustration from 'src/assets/hygiene-case-illustration.svg';
import LogisticsIllustration from 'src/assets/logistics-case-illustration.svg';
import { Button } from 'src/components/Button';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';

import { Container, CasesContainer, CasesTab, Content, Tabs } from './styles';

export function Cases() {
  const [selectedCase, setSelectedCase] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const allCases = [
    {
      icon: <ForkKnife weight="duotone" />,
      name: translate('casesFood'),
      url: '/cases/food',
      title: translate('landingPageCasesFoodTitle'),
      description: <p>{translate('landingPageCasesFoodDescription')}</p>,
      img: FoodIllustration,
      testID: 'food',
    },
    {
      icon: <Car weight="duotone" />,
      name: translate('casesAutomotive'),
      url: '/cases/automobiles',
      title: translate('landingPageCasesAutomotiveTitle'),
      description: <p>{translate('landingPageCasesAutomotiveDescription')}</p>,
      img: AutomobilesIllustration,
      testID: 'automobiles',
    },
    {
      icon: <HandSoap weight="duotone" />,
      name: translate('casesPersonalCare'),
      url: '/cases/hygiene-beauty',
      title: translate('landingPageCasesPersonalCareTitle'),
      description: (
        <p>{translate('landingPageCasesPersonalCareDescription')}</p>
      ),
      img: HygieneIllustration,
      testID: 'hygiene-beauty',
    },

    {
      icon: <Truck weight="duotone" />,
      name: translate('casesLogistics'),
      url: '/cases/logistics',
      title: translate('landingPageCasesLogisticsTitle'),
      description: <p>{translate('landingPageCasesLogisticsDescription')}</p>,
      img: LogisticsIllustration,
      testID: 'logistics',
    },
  ];

  return (
    <Container>
      <Title>
        <h4>{translate('landingPageCasesSubtitle')}</h4>
        <h3>{translate('landingPageCasesTitle')}</h3>
      </Title>
      <CasesContainer>
        <Tabs>
          {allCases.map((item, index) => (
            <CasesTab
              key={item.name}
              selected={selectedCase === index}
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  item.name,
                  `Visualizar aba do case ${item.name}`,
                  location.pathname,
                );
                setSelectedCase(index);
              }}
              data-testid={`case-tab-${item.testID}`}
            >
              {item.icon}
              <p>{item.name}</p>
            </CasesTab>
          ))}
        </Tabs>
        <Content>
          <div>
            <h3>{allCases[selectedCase].name}</h3>
            <h1>{allCases[selectedCase].title}</h1>
            {allCases[selectedCase].description}

            <Button
              buttonType="naked"
              icon={<ArrowUpRight />}
              iconInvert
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  `Saiba mais - ${allCases[selectedCase].name}`,
                  `Ir para o case ${allCases[selectedCase].name}`,
                  location.pathname,
                );
                navigate(urlPrefix + allCases[selectedCase].url);
              }}
              data-testid="know-more-case-button"
            >
              {translate('learnMore')}
            </Button>
          </div>
          <div>
            <img
              src={allCases[selectedCase].img}
              alt="case illustration representation"
            />
          </div>
        </Content>
      </CasesContainer>
    </Container>
  );
}
