export const PlansENUSTranslations = {
  plansPageHeadDescription:
    'Find out about our plans. See which plan works best for your business.',

  plansPageTitle: 'Plans',
  plansPageTableTitle: 'See how our plans compare',

  plansPageFreeDescription:
    'For a single user needing to access a limited amount of economic data for free.',
  plansPageFreeButton: 'Sign Up',

  plansPageStarterDescription:
    'For a single user needing to access a wide range of economic data and projections.',
  plansPageStarterButton: 'Contact Us',

  plansPageEssentialDescription:
    'For a single user needing economic data and a model prototyping environment, including set-up and individual support.',
  plansPageEssentialButton: 'Contact Us',

  plansPageBusinessDescription:
    'For multiple users needing all the functionality of the Essential plan, with additional collaboration and governance features, and advanced support.',
  plansPageBusinessButton: 'Contact Us',

  plansExplainDataTitle: 'Economic Data',
  plansExplainDataDescription:
    '4intelligence data repository with indicators from various public and private sources in one place, including historical data and projections.',

  plansPageDataRow0Name: 'Feature Store',
  plansPageDataRow1Name: 'Access to over 2000 indicators and 130000 series',
  plansPageDataRow1Free: 'Limited',
  plansPageDataRow2Name: 'Data Alerts',
  plansPageDataRow3Name: 'Projections Alerts',
  plansPageDataRow4Name: 'Chat4i',
  plansPageDataRow5Name: 'Excel Plug-in',
  plansPageDataRow6Name: 'Creation of Customized Monitoring Groups',
  plansPageDataRow7Name: 'Sharing groups and features',
  plansPageDataRow8Name: 'REST API for building automated pipelines',
  plansPageDataRow9Name: 'Export Data in Tabular Formats',
  plansPageDataRow10Name: 'Different sources and projection scenarios',
  plansPageDataRow11Name: 'Analytical Reports',

  plansExplainModelsTitle: 'AI Models',
  plansExplainModelsDescription:
    'Essential activities to obtain accurate predictive models automatically.',

  plansPageModelsRow0Name: 'Models',
  plansPageModelsRow1Name: 'Automatic Feature Discovery (Pick4me)',
  plansPageModelsRow2Name: 'Time Series Models',
  plansPageModelsRow3Name: 'Classification Models',
  plansPageModelsRow4Name: 'Model Composition',
  plansPageModelsRow5Name: 'Modeling multiple series simultaneously',
  plansPageModelsRow6Name: 'Model Updates',
  plansPageModelsRow7Name: 'Scenario Construction',
  plansPageModelsRow8Name: 'Explainability of Results and Projections',
  plansPageModelsRow9Name: 'Modeling Limit per Year',
  plansPageModelsRow9Essential: '120 or 600',
  plansPageModelsRow9Business: 'Unlimited',

  plansExplainPlanningTitle: 'Integrated Planning',
  plansExplainPlanningDescription:
    'Critical functionalities for sharing and practical use of projections in business environments, allowing monitoring of their life cycle and governance under changing scenarios.',

  plansPagePlanningRow0Name: 'Workspaces',
  plansPagePlanningRow1Name: 'Sharing with other users',
  plansPagePlanningRow2Name: 'User permissions',
  plansPagePlanningRow3Name:
    'Multi-level series compatibility (top down, middle out, and bottom up)',
  plansPagePlanningRow4Name: 'Custom visualization layouts',
  plansPagePlanningRow5Name: 'Dashboard with insights for decision-making',
  plansPagePlanningRow6Name: 'Data segmentation - ABC curve',
  plansPagePlanningRow7Name: 'Formatting and inclusion of different data types',
  plansPagePlanningRow8Name:
    'Tree View with up to 10 hierarchical levels for series categorization',
  plansPagePlanningRow9Name:
    'Inclusion of external series (modeled outside the AI Models module)',
  plansPagePlanningRow10Name: 'Explainability of results and projections',
  plansPagePlanningRow11Name: 'Governance',
  plansPagePlanningRow12Name: 'Creation of Planning Flows',
  plansPagePlanningRow13Name: 'Hierarchical approval of Planning Cycles',
  plansPagePlanningRow14Name: 'Customizable planning horizons',
  plansPagePlanningRow15Name: 'Accuracy tracking',
  plansPagePlanningRow16Name: 'Version history of Planning Cycles',
  plansPagePlanningRow17Name: 'Scheduling of activities by stages',
  plansPagePlanningRow18Name: 'Traceability of data changes and adjustments',
  plansPagePlanningRow19Name: 'Automatic compliance reporting',

  plansExplainEnvironmentTitle: 'Environment (Use and Security)',

  plansPageEnvironmentRow0Name: 'Rest APIs for Modeling',
  plansPageEnvironmentRow1Name: 'SSO',
  plansPageEnvironmentRow2Name: 'Automatic Encryption of all Data',
  plansPageEnvironmentRow3Name: 'Support',
  plansPageEnvironmentRow3Starter: '9am-5pm Email SLA Response Time: 24 hours',
  plansPageEnvironmentRow3Essential:
    '9am-5pm Email SLA Response Time: 24 hours',
  plansPageEnvironmentRow3Business:
    '9am-5pm Email & Teams SLA Response Time: 4 hours',
  // plansPageEnvironmentRow4Name: 'Atendimento',
  // plansPageEnvironmentRow4Starter:
  //   'Atendimento por email para retirar dúvidas da Plataforma 4intelligence, módulo "Feature Store"',
  // plansPageEnvironmentRow4Essential:
  //   'Atendimento por email para retirar dúvidas da Plataforma 4intelligence, módulos "Feature Store" e "Models"',
  // plansPageEnvironmentRow4Business:
  //   'Atendimento para retirada de dúvidas da Plataforma 4intelligence, na modalidade de contratação "Autosserviço", para os clientes que optam por construir seus próprios modelos preditivos + suporte de cientistas de dados, econometristas e especialistas setoriais, na modalidade de contratação "Assisted", para os clientes que optam que o time 4intelligence faça a construção dos modelos preditivos e o acompanhamento dos ciclos de vida',

  expandRow0Title: 'Open/close feature store',
};
