import styled from 'styled-components';

type ContactSalesProps = {
  language: string;
  color?: string;
  hover?: string;
};

export const Container = styled.div<ContactSalesProps>`
  position: relative;

  width: 100vw !important;
  max-width: none !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 5rem 0;

  background: ${({ color, theme }) => color || theme.colors.primary};

  h4,
  h2,
  p,
  a {
    z-index: 1;
  }

  h4 {
    color: ${({ theme }) => theme.colors.white} !important;
  }

  h2 {
    max-width: ${({ language }) => (language === 'pt-br' ? '1000px' : '800px')};

    color: ${({ theme }) => theme.colors.white} !important;
  }

  p {
    max-width: ${({ language }) => (language === 'pt-br' ? '800px' : '640px')};

    font-weight: 400;
    font-size: 150%;
    line-height: 2.4rem;

    color: ${({ theme }) => theme.colors.white};

    margin-top: 2rem;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 16px 32px 0px rgba(255, 255, 255, 0.24);

    color: ${({ color, theme }) => color || theme.colors.primary};

    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 1.5rem;
    transition: all 0.2s;

    margin-top: 3rem;

    svg {
      width: 1rem;
      height: 1rem;

      margin-right: 0.5rem;
    }

    &:hover {
      color: ${({ hover, theme }) => hover || theme.colors.primaryDark};
    }
  }

  @media (max-width: 1400px) {
    padding: 4.5rem 0;

    h2 {
      font-size: 3rem;
      line-height: 5rem;
    }

    p {
      font-size: 1.25rem;
      line-height: 2.15rem;
    }
  }

  @media (max-width: 1100px) {
    h2 {
      padding: 0 5rem;

      font-size: 2.5rem;
      line-height: 3rem;
    }

    p {
      padding: 0 5rem;

      font-size: 1rem;
      line-height: 1.75rem;
    }
  }

  @media (max-width: 500px) {
    padding: 3rem 0;

    h2 {
      padding: 0 2rem;

      font-size: 2.25rem;
      line-height: 3rem;
    }

    p {
      padding: 0 2rem;
    }
  }
`;

export const ArrowIcon = styled.div`
  width: 4rem;
  height: 4rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 100%;

  margin-bottom: 3rem;

  > svg {
    width: 2rem;
    height: 2rem;

    color: ${({ theme }) => theme.colors.white};
  }
`;

export const BackgroundImage = styled.img`
  position: absolute;
  bottom: 0;

  width: 100%;

  background-size: cover;
  opacity: 0.6;

  z-index: 0;
`;
