export const LandingPageModulesContentENUSTranslations = {
  landingPageModulesEconomicDataTitle: 'All the data you need in one place',
  landingPageModulesEconomicDataDescription:
    'The Feature Store’s up-to-date series and indicators are ready to use in your planning and decision-making activities.',
  landingPageModulesEconomicDataQuote:
    'As well as its ease of use, we chose 4intelligence because of the quality and wide functionality of the Feature Store.',
  landingPageModulesEconomicDataAuthor:
    'Gabriel Leite, Risk Coordinator, HDI Insurance',
  landingPageModulesEconomicDataImgAlt: 'Economic Data image example',

  landingPageModulesAiModelsTitle:
    'Accurately forecast the future of your business',
  landingPageModulesAiModelsDescription:
    'Generate actionable insights with predictive AI modelling, enabling you to map risks and anticipate future scenarios with precision.',
  landingPageModulesAiModelsQuote:
    '4intelligence’s platform gives us higher levels of accuracy than other providers.',
  landingPageModulesAiModelsAuthor: 'Hélio Vinicius, Risk Modelling, Bradesco',
  landingPageModulesAiModelsImgAlt: 'AI Models image example',

  landingPageModulesPlanningTitle: 'Collaborative decisions and tracking',
  landingPageModulesPlanningDescription:
    'Collaborate across teams, track results against forecasts and capture everything about the decision-making process in a single place.',
  landingPageModulesPlanningQuote:
    'Workspace enables our teams to work together through the planning cycle.',
  landingPageModulesPlanningAuthor:
    'Giuliano Suetugo, Strategy & Market Intelligence, Volvo',
  landingPageModulesPlanningImgAlt: 'Planning image example',
};
