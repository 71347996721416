import React from 'react';

export function Quotes() {
  return (
    <svg
      width="46"
      height="32"
      viewBox="0 0 46 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 19.3201C26 15.9921 26.512 13.1335 27.536 10.7441C28.56 8.26945 29.8827 6.26412 31.504 4.72812C33.1254 3.10679 34.96 1.95479 37.008 1.27212C39.1414 0.504117 41.232 0.120117 43.28 0.120117V4.60012C41.0614 4.60012 38.9707 5.36812 37.008 6.90412C35.1307 8.35478 34.0214 10.3601 33.68 12.9201C33.936 12.8348 34.2347 12.7495 34.576 12.6641C34.832 12.5788 35.1307 12.4935 35.472 12.4081C35.8987 12.3228 36.368 12.2801 36.88 12.2801C39.44 12.2801 41.5734 13.2615 43.28 15.2241C44.9867 17.1015 45.84 19.3201 45.84 21.8801C45.84 24.4401 44.944 26.7015 43.152 28.6641C41.4454 30.5415 39.1414 31.4801 36.24 31.4801C32.9974 31.4801 30.48 30.2855 28.688 27.8961C26.896 25.4215 26 22.5628 26 19.3201ZM0.400024 19.3201C0.400024 15.9921 0.912024 13.1335 1.93602 10.7441C2.96002 8.26945 4.28269 6.26412 5.90402 4.72812C7.52536 3.10679 9.36003 1.95479 11.408 1.27212C13.5414 0.504117 15.632 0.120117 17.68 0.120117V4.60012C15.4614 4.60012 13.3707 5.36812 11.408 6.90412C9.53069 8.35478 8.42136 10.3601 8.08003 12.9201C8.33603 12.8348 8.63469 12.7495 8.97602 12.6641C9.23202 12.5788 9.53069 12.4935 9.87202 12.4081C10.2987 12.3228 10.768 12.2801 11.28 12.2801C13.84 12.2801 15.9734 13.2615 17.68 15.2241C19.3867 17.1015 20.24 19.3201 20.24 21.8801C20.24 24.4401 19.344 26.7015 17.552 28.6641C15.8454 30.5415 13.5414 31.4801 10.64 31.4801C7.39736 31.4801 4.88003 30.2855 3.08802 27.8961C1.29602 25.4215 0.400024 22.5628 0.400024 19.3201Z"
        fillOpacity="0.11"
      />
    </svg>
  );
}
