import React, { useEffect } from 'react';

import { Check, Info, X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import plansBackground from 'src/assets/plans-background.png';
import { Button } from 'src/components/Button';
import { Clients } from 'src/components/Clients';
import { ContactSales } from 'src/components/ContactSales';
import { Head } from 'src/components/Head';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import {
  TrackGoogleAnalyticsEvent,
  TrackGoogleAnalyticsPageView,
} from 'src/utils/google-analytics';

import {
  Container,
  Content,
  TableContent,
  PlansType,
  Table,
  CardsContainer,
  PlanCard,
  Background,
} from './styles';

export function Plans() {
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(location.pathname, 'Tela de Planos');
  }, [location.pathname]);

  return (
    <Container>
      <Head
        title={`${translate('plans')} | 4intelligence`}
        description={translate('plansPageHeadDescription') ?? ''}
      />

      <Title style={{ padding: '0 2rem' }}>
        <h1>{translate('plansPageTitle')}</h1>
      </Title>

      <Background src={plansBackground} alt="background" />

      <Content>
        <CardsContainer>
          <PlanCard>
            <h3>Free</h3>
            <p>{translate('plansPageFreeDescription')}</p>

            <Button
              buttonType="secondary"
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'Comece grátis',
                  'Ir para cadastro do freemium',
                  location.pathname,
                );
                navigate(`${urlPrefix}/freemium/sign-up`);
              }}
              data-testid="start-free-button"
            >
              {translate('plansPageFreeButton')}
            </Button>
          </PlanCard>

          <PlanCard>
            <h3>Data Starter</h3>
            <p>{translate('plansPageStarterDescription')}</p>
            <Button
              buttonType="primary"
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'Falar com vendas - Starter',
                  'Ir para tela Falar com Vendas',
                  location.pathname,
                );
                navigate(`${urlPrefix}/contact-sales`);
              }}
              data-testid="contact-sales-plans-button"
            >
              {translate('plansPageStarterButton')}
            </Button>
          </PlanCard>

          <PlanCard>
            <h3>Essential</h3>
            <p>{translate('plansPageEssentialDescription')}</p>
            <Button
              buttonType="primary"
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'Falar com vendas - Essential',
                  'Ir para tela Falar com Vendas',
                  location.pathname,
                );
                navigate(`${urlPrefix}/contact-sales`);
              }}
              data-testid="contact-sales-plans-button"
            >
              {translate('plansPageEssentialButton')}
            </Button>
          </PlanCard>

          <PlanCard>
            <h3>Business Critical</h3>
            <p>{translate('plansPageBusinessDescription')}</p>
            <Button
              buttonType="primary"
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'Falar com vendas - Business',
                  'Ir para tela Falar com Vendas',
                  location.pathname,
                );
                navigate(`${urlPrefix}/contact-sales`);
              }}
              data-testid="contact-sales-plans-button"
            >
              {translate('plansPageBusinessButton')}
            </Button>
          </PlanCard>
        </CardsContainer>

        <Clients />

        <TableContent>
          <Title style={{ padding: '0 2rem' }}>
            <h2>{translate('plansPageTableTitle')}</h2>
          </Title>

          <Table>
            <thead>
              <tr>
                <th aria-label="nome da coluna vazia" />
                <th>
                  <PlansType>
                    <h2>
                      Free <br /> &nbsp;
                    </h2>

                    <Button
                      buttonType="secondary"
                      onClick={() => {
                        TrackGoogleAnalyticsEvent(
                          'Comece grátis',
                          'Ir para cadastro do freemium',
                          location.pathname,
                        );
                        navigate(`${urlPrefix}/freemium/sign-up`);
                      }}
                      data-testid="start-free-button"
                    >
                      {translate('plansPageFreeButton')}
                    </Button>
                  </PlansType>
                </th>
                <th>
                  <PlansType>
                    <h2>
                      Data <br /> Starter
                    </h2>

                    <Button
                      buttonType="primary"
                      onClick={() => {
                        TrackGoogleAnalyticsEvent(
                          'Falar com vendas - Starter',
                          'Ir para tela Falar com Vendas',
                          location.pathname,
                        );
                        navigate(`${urlPrefix}/contact-sales`);
                      }}
                      data-testid="contact-sales-plans-button"
                    >
                      {translate('plansPageStarterButton')}
                    </Button>
                  </PlansType>
                </th>
                <th>
                  <PlansType>
                    <h2>
                      Essential <br /> &nbsp;
                    </h2>

                    <Button
                      buttonType="primary"
                      onClick={() => {
                        TrackGoogleAnalyticsEvent(
                          'Falar com vendas - Essential',
                          'Ir para tela Falar com Vendas',
                          location.pathname,
                        );
                        navigate(`${urlPrefix}/contact-sales`);
                      }}
                      data-testid="contact-sales-plans-button"
                    >
                      {translate('plansPageEssentialButton')}
                    </Button>
                  </PlansType>
                </th>
                <th>
                  <PlansType>
                    <h2>
                      Business <br /> Critical
                    </h2>

                    <Button
                      buttonType="primary"
                      onClick={() => {
                        TrackGoogleAnalyticsEvent(
                          'Falar com vendas - Business',
                          'Ir para tela Falar com Vendas',
                          location.pathname,
                        );
                        navigate(`${urlPrefix}/contact-sales`);
                      }}
                      data-testid="contact-sales-plans-button"
                    >
                      {translate('plansPageBusinessButton')}
                    </Button>
                  </PlansType>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="table-explain">
                <td colSpan={5}>
                  <h2>{translate('plansExplainDataTitle')}</h2>
                  <p>{translate('plansExplainDataDescription')}</p>
                </td>
              </tr>

              <tr className="table-title">
                <td colSpan={5}>{translate('plansPageDataRow0Name')}</td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow1Name')}</td>
                <td
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate('plansPageDataRow1Free')}
                >
                  <p>{translate('plansPageDataRow1Free')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate('plansPageDataRow1Free')}
                  />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow2Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow3Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow4Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow5Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow6Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow7Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow8Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow9Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow10Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageDataRow11Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr className="table-explain">
                <td colSpan={5}>
                  <h2>{translate('plansExplainModelsTitle')}</h2>
                  <p>{translate('plansExplainModelsDescription')}</p>
                </td>
              </tr>

              <tr className="table-title">
                <td colSpan={5}>{translate('plansPageModelsRow0Name')}</td>
              </tr>

              <tr>
                <td>{translate('plansPageModelsRow1Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageModelsRow2Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageModelsRow3Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageModelsRow4Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageModelsRow5Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageModelsRow6Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageModelsRow7Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageModelsRow8Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageModelsRow9Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate(
                    'plansPageModelsRow9Essential',
                  )}
                >
                  <p>{translate('plansPageModelsRow9Essential')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate(
                      'plansPageModelsRow9Essential',
                    )}
                  />
                </td>
                <td
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate(
                    'plansPageModelsRow9Business',
                  )}
                >
                  <p>{translate('plansPageModelsRow9Business')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate(
                      'plansPageModelsRow9Business',
                    )}
                  />
                </td>
              </tr>

              <tr className="table-explain">
                <td colSpan={5}>
                  <h2>{translate('plansExplainPlanningTitle')}</h2>
                  <p>{translate('plansExplainPlanningDescription')}</p>
                </td>
              </tr>

              <tr className="table-title">
                <td colSpan={5}>{translate('plansPagePlanningRow0Name')}</td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow1Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow2Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow3Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow4Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow5Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow6Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow7Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow8Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow9Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow10Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr className="table-title">
                <td colSpan={5}>{translate('plansPagePlanningRow11Name')}</td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow12Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow13Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow14Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow15Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow16Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow17Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow18Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPagePlanningRow19Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr className="table-explain">
                <td colSpan={5}>
                  <h2>{translate('plansExplainEnvironmentTitle')}</h2>
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageEnvironmentRow0Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageEnvironmentRow1Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageEnvironmentRow2Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageEnvironmentRow3Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td
                  style={{ textAlign: 'left', verticalAlign: 'text-top' }}
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate(
                    'plansPageEnvironmentRow3Starter',
                  )}
                >
                  <p>{translate('plansPageEnvironmentRow3Starter')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate(
                      'plansPageEnvironmentRow3Starter',
                    )}
                  />
                </td>
                <td
                  style={{ textAlign: 'left', verticalAlign: 'text-top' }}
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate(
                    'plansPageEnvironmentRow3Essential',
                  )}
                >
                  <p>{translate('plansPageEnvironmentRow3Essential')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate(
                      'plansPageEnvironmentRow3Essential',
                    )}
                  />
                </td>
                <td
                  style={{ textAlign: 'left', verticalAlign: 'text-top' }}
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate(
                    'plansPageEnvironmentRow3Business',
                  )}
                >
                  <p>{translate('plansPageEnvironmentRow3Business')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate(
                      'plansPageEnvironmentRow3Business',
                    )}
                  />
                </td>
              </tr>

              {/* <tr>
                <td>{translate('plansPageEnvironmentRow4Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td
                  style={{ textAlign: 'left', verticalAlign: 'text-top' }}
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate(
                    'plansPageEnvironmentRow4Starter',
                  )}
                >
                  <p>{translate('plansPageEnvironmentRow4Starter')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate(
                      'plansPageEnvironmentRow4Starter',
                    )}
                  />
                </td>
                <td
                  style={{ textAlign: 'left', verticalAlign: 'text-top' }}
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate(
                    'plansPageEnvironmentRow4Essential',
                  )}
                >
                  <p>{translate('plansPageEnvironmentRow4Essential')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate(
                      'plansPageEnvironmentRow4Essential',
                    )}
                  />
                </td>
                <td
                  style={{ textAlign: 'left', verticalAlign: 'text-top' }}
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate(
                    'plansPageEnvironmentRow4Business',
                  )}
                >
                  <p>{translate('plansPageEnvironmentRow4Business')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate(
                      'plansPageEnvironmentRow4Business',
                    )}
                  />
                </td>
              </tr> */}
            </tbody>
          </Table>
        </TableContent>
      </Content>

      <Tooltip id="plans-tooltip" />

      <ContactSales />
    </Container>
  );
}
