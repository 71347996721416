export const FormPTBRTranslations = {
  formSubmittedImgAlt: 'Caixa postal sinalizando que receberá um e-mail',
  formSubmittedTitle: 'Obrigado pelo seu interesse!',
  formSubmittedDescription: 'Em breve entraremos em contato com você.',
  formPrivacyPolicyInfo:
    'Ao clicar no botão, autorizo o tratamento dos dados pessoais conforme a ',
  formPrivacyPolicy: 'Política de Privacidade',

  formComponentTitle: 'Fale conosco',
  formComponentDescription:
    'Contate-nos para um orçamento, ajuda ou para se juntar à equipe',
};
