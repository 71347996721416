import React from 'react';

export function WhatsappLogo() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2556 3.4875C18.9174 1.2375 15.8036 0 12.4944 0C5.66406 0 0.106027 5.33571 0.106027 11.8929C0.106027 13.9875 0.675223 16.0339 1.75781 17.8393L0 24L6.56808 22.3446C8.37612 23.2929 10.4129 23.7911 12.4888 23.7911H12.4944C19.3192 23.7911 25 18.4554 25 11.8982C25 8.72143 23.5938 5.7375 21.2556 3.4875ZM12.4944 21.7875C10.6417 21.7875 8.82812 21.3107 7.24888 20.4107L6.875 20.1964L2.97991 21.1768L4.01786 17.5286L3.77232 17.1536C2.73996 15.5786 2.19866 13.7625 2.19866 11.8929C2.19866 6.44464 6.8192 2.00893 12.5 2.00893C15.2511 2.00893 17.8348 3.0375 19.7768 4.90714C21.7188 6.77679 22.9129 9.25714 22.9074 11.8982C22.9074 17.3518 18.1696 21.7875 12.4944 21.7875ZM18.1417 14.3839C17.8348 14.2339 16.3114 13.5161 16.0268 13.4196C15.7422 13.3179 15.5357 13.2696 15.3292 13.5696C15.1228 13.8696 14.5312 14.5339 14.3471 14.7375C14.1685 14.9357 13.9844 14.9625 13.6775 14.8125C11.8583 13.9393 10.6641 13.2536 9.46429 11.2768C9.14621 10.7518 9.78237 10.7893 10.3739 9.65357C10.4743 9.45536 10.4241 9.28393 10.346 9.13393C10.2679 8.98393 9.64844 7.52143 9.39174 6.92679C9.14062 6.34821 8.88393 6.42857 8.6942 6.41786C8.51562 6.40714 8.30915 6.40714 8.10268 6.40714C7.8962 6.40714 7.56138 6.48214 7.27679 6.77679C6.99219 7.07679 6.1942 7.79464 6.1942 9.25714C6.1942 10.7196 7.30469 12.1339 7.45536 12.3321C7.61161 12.5304 9.63728 15.5304 12.7455 16.8214C14.7098 17.6357 15.4799 17.7054 16.4621 17.5661C17.0592 17.4804 18.2924 16.8482 18.5491 16.1518C18.8058 15.4554 18.8058 14.8607 18.7277 14.7375C18.6551 14.6036 18.4487 14.5286 18.1417 14.3839Z"
        fill="white"
      />
    </svg>
  );
}
