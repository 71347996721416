import styled, { css } from 'styled-components';

export const Container = styled.main`
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 7rem;

  h2 {
    font-size: 2.75rem;
  }

  @media (max-width: 640px) {
    padding: 0 2rem 2rem 2rem;

    > div:last-child {
      margin-top: 2rem;
    }

    h2 {
      font-size: 2rem;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-bottom: 8rem;

  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1rem;
    line-height: 180%;

    margin-top: 1rem;
  }

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 8rem;
  }

  h2 {
    line-height: 130%;
    margin-bottom: 1rem;
  }
`;

type HeroProps = {
  backgroundType: 'image' | 'color';
};

export const Hero = styled.div<HeroProps>`
  width: 100vw !important;
  height: calc(100vh - 6.5625rem) !important;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ backgroundType, theme }) =>
    backgroundType === 'color' ? theme.colors.primary : 'none'};

  padding: 5rem 7rem;

  h2 {
    color: ${({ backgroundType, theme }) =>
      backgroundType === 'color' ? theme.colors.white : theme.colors.gray8};
  }

  p {
    color: ${({ backgroundType, theme }) =>
      backgroundType === 'color' ? theme.colors.white : theme.colors.gray5};
  }

  p {
    font-size: 1.25rem;
  }

  @media (max-width: 640px) {
    height: auto !important;
    padding: 4rem 2rem;
  }
`;

export const HeroContent = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6rem;

  > div {
    margin: auto 0;
  }

  img,
  video {
    width: 100%;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    text-align: center;

    img,
    video {
      display: none;
    }
  }
`;

type HeroButtons = {
  color: string;
  hover: string;
};

export const HeroButtons = styled.div<HeroButtons>`
  display: flex;
  gap: 1.5rem;

  margin-top: 3rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    border-radius: 8px;

    font-weight: 600;

    padding: 1rem 1.5rem;

    transition: all 0.2s;
  }

  a:first-child {
    background-color: ${({ color }) => color};
    color: ${({ color, theme }) =>
      color === theme.colors.white ? theme.colors.primary : theme.colors.white};

    &:hover {
      background-color: ${({ hover }) => hover};
      color: ${({ hover, theme }) =>
        hover === theme.colors.white
          ? theme.colors.primaryDark
          : theme.colors.white};
    }
  }

  a:last-child {
    border: 1px solid ${({ color }) => color};
    color: ${({ color }) => color};
    background-color: ${({ color, theme }) =>
      color === theme.colors.white ? theme.colors.primary : theme.colors.white};

    &:hover {
      background-color: ${({ hover, theme }) =>
        hover === theme.colors.white ? theme.colors.primaryDark : hover};
      color: ${({ theme }) => theme.colors.white};
      border-color: ${({ hover, theme }) =>
        hover === theme.colors.white ? theme.colors.primaryDark : 0};
    }
  }

  @media (max-width: 640px) {
    flex-direction: column-reverse;
  }
`;

export const HeroBackground = styled.img`
  position: absolute;
  top: 0;
  right: 0;

  z-index: -1;

  height: 100vh !important;

  @media (max-width: 640px) {
    display: none;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > img {
    margin-top: 4rem;
  }

  p {
    max-width: 720px;
    margin: 0 auto;
  }

  @media (max-width: 760px) {
    flex-direction: column-reverse;

    img {
      width: 80% !important;
      margin-bottom: 3rem;
    }
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8rem;

  img,
  video {
    width: 100%;
    margin: auto 0;
  }

  > div {
    width: 100%;
    margin: 3rem 0 auto 0;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 3rem;

    > div {
      margin-top: 1rem;
    }

    img {
      width: 80%;
      order: -1;
      margin: 0 auto;
    }
  }
`;

type BackgroundSectionProps = {
  color: string;
};

export const BackgroundSection = styled.div<BackgroundSectionProps>`
  width: 100vw !important;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5rem 7rem;

  > div {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth};
  }

  ${({ color }) => css`
    background-color: ${`${color}1F`};
  `}

  @media (max-width: 640px) {
    padding: 5rem 2rem;
  }
`;

export const BackgroundImageSection = styled.div<BackgroundSectionProps>`
  width: 100vw !important;
  max-width: none !important;

  height: auto;

  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 10% 7rem 15% 7rem !important;
  margin: -2rem 0 -8rem 0 !important;

  z-index: 1 !important;

  > div {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth};
    margin-top: 10rem;
  }

  @media (max-width: 880px) {
    padding: 40% 7rem 32% 7rem;
  }

  @media (max-width: 760px) {
    background-color: ${({ color }) => `${color}1F`};
    background-image: none !important;

    padding: 5rem 7rem !important;
    margin-top: 8rem !important;
    margin-bottom: 0 !important;

    > div {
      margin-top: 0;
    }
  }

  @media (max-width: 500px) {
    padding: 5rem 2rem !important;
  }
`;
