import React, { useEffect, useState } from 'react';

import { CaretUp, X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from 'src/assets/4i-logo.svg';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';
import { scrollTo } from 'src/utils/scrollTo';

import { ActionContent, Container, Header, Menu, MenuItem } from './styles';
import { Submenu } from './Submenu';
import { MobileMenuProps } from './types';
import { Button } from '../Button';
import { LanguageMenu } from '../LanguageMenu';
import { getMenuItems } from '../MenuItems';

export function MobileMenu({ openMenu, setOpenMenu }: MobileMenuProps) {
  const [animation, setAnimation] = useState(false);

  const [openProduct, setOpenProduct] = useState(false);
  const [openCases, setOpenCases] = useState(false);
  const [openResources, setOpenResources] = useState(false);

  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { casesMenu, resourceMenu, productMenu } = getMenuItems();

  useEffect(() => {
    if (!animation && openMenu) {
      setAnimation(true);
    }
  }, [openMenu, animation]);

  return (
    <Container
      visible={openMenu}
      animation={animation}
      data-cy="mobile-menu"
      data-testid="mobile-menu"
    >
      <Header>
        <button
          type="button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Menu mobile - Logo da 4intelligence',
              'Ir para a página inicial',
              location.pathname,
            );

            scrollTo(true, 0, 100);
            navigate('/');
          }}
          data-cy="mobile-logo-button"
          data-testid="mobile-logo-button"
          aria-label={translate('header4iButtonLabel') ?? ''}
        >
          <img src={Logo} alt="4intelligence logo" />
        </button>

        <Button
          buttonType="naked"
          aria-label={translate('mobileMenuCloseButtonLabel') ?? ''}
          className="close-button"
          data-testid="mobile-close-button"
          onClick={() => setOpenMenu(false)}
        >
          <X />
        </Button>
      </Header>

      <Menu>
        {/* <MenuItem className="menu-mobile-soon-tooltip">
          {translate('mobileMenuProduct')}
        </MenuItem>
        <MenuItem className="menu-mobile-soon-tooltip">
          {translate('mobileMenuDepartment')}
        </MenuItem>
        <MenuItem className="menu-mobile-soon-tooltip">
          {translate('mobileMenuAbout')}
        </MenuItem>
        <MenuItem className="menu-mobile-soon-tooltip">
          {translate('mobileMenuResource')}
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Header - Planos',
              'Ir para a tela de planos',
              location.pathname,
            );

            navigate('/plans');
            setOpenMenu(false);
          }}
          data-testid="mobile-plans-button"
        >
          <div>{translate('plans')}</div>
        </MenuItem>

        <MenuItem onClick={() => setOpenProduct(!openProduct)}>
          <div>
            {translate('product')}
            <CaretUp className={openProduct ? 'show-menu' : 'hidden-menu'} />
          </div>

          <Submenu menu={productMenu} visible={openProduct} />
        </MenuItem>

        <MenuItem onClick={() => setOpenCases(!openCases)}>
          <div>
            {translate('cases')}
            <CaretUp className={openCases ? 'show-menu' : 'hidden-menu'} />
          </div>

          <Submenu menu={casesMenu} visible={openCases} />
        </MenuItem>

        <MenuItem onClick={() => setOpenResources(!openResources)}>
          <div>
            {translate('resource')}
            <CaretUp className={openResources ? 'show-menu' : 'hidden-menu'} />
          </div>

          <Submenu menu={resourceMenu} visible={openResources} />
        </MenuItem>
      </Menu>

      <ActionContent>
        <LanguageMenu className="mobile-language-button" />

        <Link
          to="https://app.4intelligence.ai/"
          target="_blank"
          rel="noreferrer"
          className="mobile-login-button"
          data-cy="mobile-login-button"
          data-testid="mobile-login-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Acessar plataforma',
              'Acessar plataforma',
              location.pathname,
            );
          }}
        >
          {translate('accessButton')}
        </Link>

        <Link
          to={`${urlPrefix}/contact-sales`}
          className="mobile-contact-button"
          data-cy="mobile-contact-button"
          data-testid="mobile-contact-button"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Falar com vendas',
              'Ir para tela Falar com Vendas',
              location.pathname,
            );
            setOpenMenu(false);
          }}
        >
          {translate('contactButton')}
        </Link>
      </ActionContent>
    </Container>
  );
}
