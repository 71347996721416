import React from 'react';

import { Warning } from '@phosphor-icons/react';

import { Container } from './styles';

export function LanguageWarning() {
  return (
    <Container>
      <Warning />
      <p>Currently this material is only available in Portuguese.</p>
    </Container>
  );
}
