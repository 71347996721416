export const LandingPageDepartmentsContentENUSTranslations = {
  landingPageDepartmentSubtitle: 'EXPERTISE ACROSS SECTORS',
  landingPageDepartmentTitle: 'For the unique challenges of your industry',
  landingPageDepartmentDescription: 'Solutions include:',

  landingPageDepartmentFoodTitle: 'Food & Beverage',
  landingPageDepartmentFoodList1: 'Forecast demand',
  landingPageDepartmentFoodList2: 'Optimize stock and inventory',
  landingPageDepartmentFoodList3: 'Calculate market share',
  landingPageDepartmentFoodList4: 'Define pricing strategies',
  landingPageDepartmentFoodList5: 'Project commodity prices',
  landingPageDepartmentFoodImageAlt: 'Food & Beverage image example',

  landingPageDepartmentAgribusinessTitle: 'Agribusiness',
  landingPageDepartmentAgribusinessList1:
    'Forecast productivity during harvest',
  landingPageDepartmentAgribusinessList2:
    'Forecast prices of agricultural commodities',
  landingPageDepartmentAgribusinessList3:
    'Monitor progress of harvest and seasonalities',
  landingPageDepartmentAgribusinessList4:
    'Optimize inventory management and projections of exports',
  landingPageDepartmentAgribusinessList5:
    'Monitor actual and potential market share',
  landingPageDepartmentAgribusinessImageAlt: 'Agribusiness image example',

  landingPageDepartmentLogisticsTitle: 'Logistics',
  landingPageDepartmentLogisticsList1: 'Forecast shipping price',
  landingPageDepartmentLogisticsList2: 'Forecast diesel price',
  landingPageDepartmentLogisticsList3: 'Project product supply and demand',
  landingPageDepartmentLogisticsList4: 'Define adequate storage capacity',
  landingPageDepartmentLogisticsList5:
    'Support for transport route optimization',
  landingPageDepartmentLogisticsImageAlt: 'Logistics image example',

  landingPageDepartmentFinancialTitle: 'Financial',
  landingPageDepartmentFinancialList1:
    'Define efficiency and sustainability indicators',
  landingPageDepartmentFinancialList2:
    'Diagnose portfolio offenders or promoters',
  landingPageDepartmentFinancialList3: 'Anticipate default risk',
  landingPageDepartmentFinancialList4:
    'Analyze credit supply and restriction scenarios',
  landingPageDepartmentFinancialList5:
    'Segment and forecast portfolio performance',
  landingPageDepartmentFinancialImageAlt: 'Financial image example',

  landingPageDepartmentPersonalCareTitle: 'Personal Care & Beauty',
  landingPageDepartmentPersonalCareList1:
    'Forecast Sell Out for the market and products',
  landingPageDepartmentPersonalCareList2:
    'Minimize points of sale being out of stock',
  landingPageDepartmentPersonalCareList3:
    'Improve marketing strategies by anticipating market trends and changes',
  landingPageDepartmentPersonalCareList4:
    'Ensure traceability of demand forecasts',
  landingPageDepartmentPersonalCareList5:
    'Simulate the impact of price changes on market share',
  landingPageDepartmentPersonalCareImageAlt:
    'Personal Care & Beauty image example',
};
