import styled from 'styled-components';

type ContainerProps = {
  dotsColor: string;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .slider-container {
    margin-top: 3rem;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  .slick-dots {
    bottom: -70px;

    button {
      width: 1rem;
      height: 1rem;

      &:hover::before {
        color: ${({ dotsColor }) => dotsColor};
      }
    }

    button::before {
      width: 1rem;
      height: 1rem;
      line-height: 1rem;
      color: ${({ theme }) => theme.colors.gray5};
      opacity: 1;
      font-size: 0.5rem;
    }

    .slick-active {
      button {
        border: 4px solid ${({ dotsColor }) => dotsColor};
        border-radius: 100%;
      }

      button::before {
        color: transparent;
      }
    }
  }
`;

export const Card = styled.li`
  height: 100%;

  text-align: start;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  padding: 2rem;
  margin: 0 1rem;

  list-style-type: none;

  p {
    color: ${({ theme }) => theme.colors.gray6};
  }

  > div {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    > div {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    img {
      width: 5rem;
      height: 5rem;

      border-radius: 100%;
    }

    h5 {
      color: ${({ theme }) => theme.colors.gray6};
      font-size: 1.25rem;
      font-weight: 600;
    }

    small {
      color: ${({ theme }) => theme.colors.gray5};
      font-size: 1rem;
    }
  }
`;
