import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 5.813rem);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  background: ${({ theme }) => theme.colors.gray0};

  padding: 2rem 2rem;

  > h1 {
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 2.5rem;
    font-weight: 700;

    margin-top: 2rem;
  }

  > p {
    max-width: 30rem;

    opacity: 0.6;

    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1.125rem;
    line-height: 150%;

    margin-top: 1rem;
  }

  @media (max-width: 768px) {
    img {
      width: 60%;
    }

    > h1 {
      font-size: 2rem;
    }

    > p {
      font-size: 1rem;
    }
  }
`;
