import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  padding: 5rem 7rem 0 7rem;

  > div:first-child {
    width: 100%;
    max-width: 1600px;

    align-items: center;
    text-align: center;
  }

  @media (max-width: 500px) {
    padding: 2rem;
  }
`;

export const CaseContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6rem;

  margin: 5rem 0 8rem 0;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const CaseContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 100%;
    height: 10rem;

    margin-bottom: 1rem;

    border-radius: 4px;
  }

  h2 {
    font-size: 2.25rem;
    line-height: 3.25rem;
    font-weight: 700;

    color: ${({ theme }) => theme.colors.gray7};

    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 0.875rem;
    line-height: 150%;
    font-weight: 600;

    text-transform: uppercase;
    letter-spacing: 0.08em;

    color: ${({ theme }) => theme.colors.primary};
  }

  a {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};

    width: 100%;
    height: 2.813rem;
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.75rem 1.5rem;
    transition: all 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 1rem;
      height: 1rem;

      margin-right: 0.5rem;
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.primaryDark};
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 2rem;
      line-height: 130%;
    }
  }
`;
