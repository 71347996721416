import React from 'react';

import {
  Car,
  ForkKnife,
  HandSoap,
  Newspaper,
  Question,
  Truck,
  Database,
  Graph,
  UsersThree,
  Code,
} from '@phosphor-icons/react';
import light from 'src/styles/themes/light';

export function getMenuItems() {
  return {
    resourceMenu: [
      {
        id: 'blog',
        url: 'https://blog.4intelligence.ai/',
        title: 'blog',
        description: 'menuItemsResourcesBlogDescription',
        icon: <Newspaper weight="duotone" />,
        color: light.colors.primary,
      },
      {
        id: 'help-center',
        url: '/help-center',
        title: 'helpCenter',
        description: 'menuItemsResourcesHelpCenterDescription',
        icon: <Question weight="duotone" />,
        color: light.colors.red3,
      },
      {
        id: 'api-documentation',
        url: 'https://dev.4intelligence.ai/',
        title: 'menuItemsResourcesApiDocumentation',
        description: 'menuItemsResourcesApiDocumentationDescription',
        icon: <Code weight="duotone" />,
        color: light.colors.purple4,
      },
    ],

    casesMenu: [
      {
        id: 'foods',
        url: '/cases/food',
        title: 'casesFood',
        description: 'menuItemsCasesFoodDescription',
        icon: <ForkKnife weight="duotone" />,
        color: light.colors.orange4,
      },
      {
        id: 'automobiles',
        url: '/cases/automobiles',
        title: 'casesAutomotive',
        description: 'menuItemsCasesAutomobilesDescription',
        icon: <Car weight="duotone" />,
        color: light.colors.purple4,
      },
      {
        id: 'hygiene-beauty',
        url: '/cases/hygiene-beauty',
        title: 'casesPersonalCare',
        description: 'menuItemsCasesPersonalCareDescription',
        icon: <HandSoap weight="duotone" />,
        color: light.colors.primary,
      },

      {
        id: 'logistics',
        url: '/cases/logistics',
        title: 'casesLogistics',
        description: 'menuItemsCasesLogisticsDescription',
        icon: <Truck weight="duotone" />,
        color: light.colors.teal4,
      },
    ],

    productMenu: [
      {
        id: 'economic-data',
        url: '/product/economic-data',
        title: 'productData',
        description: 'menuItemsProductEconomicDataDescription',
        icon: <Database weight="duotone" />,
        color: light.colors.primary,
      },
      {
        id: 'ai-models',
        url: '/product/ai-models',
        title: 'productModels',
        description: 'menuItemsProductModelsDescription',
        icon: <Graph weight="duotone" />,
        color: light.colors.secondary,
      },
      {
        id: 'integrated-planning',
        url: '/product/integrated-planning',
        title: 'productPlanning',
        description: 'menuItemsProductPlanningDescription',
        icon: <UsersThree weight="duotone" />,
        color: light.colors.green4,
      },
    ],
  };
}
