import React, { useEffect } from 'react';

import { X } from '@phosphor-icons/react';

import { CloseButton, Container, Content, Overlay } from './styles';
import { ModalProps } from './types';

export function Modal({
  visible = false,
  setVisible,
  cancel,
  style,
  animation = true,
  closeButton,
  children,
  ...props
}: ModalProps) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape' && setVisible) {
        setVisible(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setVisible]);

  return (
    <Container visible={visible}>
      <Overlay
        data-testid="modal-overlay"
        onClick={() => {
          setVisible && setVisible(false);
          cancel && cancel();
        }}
      />

      <Content visible={visible} style={style} animation={animation} {...props}>
        {children}

        {closeButton && (
          <CloseButton
            onClick={() => {
              setVisible && setVisible(false);
              cancel && cancel();
            }}
            data-cy="close-modal-button"
            data-testid="close-modal-button"
          >
            <X />
          </CloseButton>
        )}
      </Content>
    </Container>
  );
}
