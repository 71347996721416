import styled, { keyframes } from 'styled-components';

type ContainerProps = {
  visible: boolean;
  triangleLeftPosition?: string;
};

const start = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity:1;
  }
`;

export const Menu = styled.div`
  position: absolute;
  top: -3rem;

  width: 100%;
  height: 100%;

  padding-top: 6rem;
`;

export const Container = styled.div<ContainerProps>`
  width: max-content;

  padding: 1.5rem;

  display: ${({ visible }) => (visible ? 'flex' : 'none')} !important;
  flex-direction: column;
  text-align: start;

  animation: ${start} 0.5s;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;

  box-shadow: 0px 8px 24px 0px rgba(113, 128, 150, 0.24);

  cursor: auto;

  ::after {
    content: '';

    height: 1rem;
    width: 1rem;

    transform: rotate(45deg);

    position: absolute;
    top: 5.5rem;
    left: ${({ triangleLeftPosition }) => triangleLeftPosition ?? '2rem'};

    box-shadow: -1rem -1rem 24px 0px rgba(113, 128, 150, 0.24);

    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const MenuContent = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 200;

  a + a {
    margin-top: 1.5rem;
  }

  a {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    align-items: center;

    :hover {
      h2 {
        color: ${({ theme }) => theme.colors.gray9};
      }

      p {
        color: ${({ theme }) => theme.colors.gray7};
      }
    }
  }
`;

type IconProps = {
  color: string;
};

export const Icon = styled.div<IconProps>`
  width: 3.375rem;
  height: 3.375rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ color }) => `${color}1F`};
  border-radius: 8px;

  svg {
    width: 1.75rem;
    height: 1.75rem;

    color: ${({ color }) => color};
  }
`;

export const Information = styled.div`
  text-align: start;
  flex: 1;

  h2 {
    color: ${({ theme }) => theme.colors.gray7};

    font-size: 1.125rem;
    font-weight: 600;
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};

    font-size: 0.875rem;
    font-weight: 400;

    line-height: 150%;

    margin-top: 0.25rem;
  }
`;
