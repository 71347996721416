import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ContactSales } from 'src/components/ContactSales';
import { Head } from 'src/components/Head';
import { TrackGoogleAnalyticsPageView } from 'src/utils/google-analytics';

import { Cases } from './content/Cases';
import { Departments } from './content/Departments';
import { Hero } from './content/Hero';
import { Modules } from './content/Modules';
import { Plans } from './content/Plans';
import { ROI } from './content/ROI';
import { Video } from './content/Video';
import { Container } from './styles';
import { Clients } from '../../components/Clients';

export function Landing() {
  const { t: translate } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(location.pathname, 'Tela Inicial');
  }, [location.pathname]);

  useEffect(() => {
    let startTyping = true;

    const words = ['forecasts', 'models', 'data', 'collaboration'];

    const span = document?.getElementById('better-type');

    let i = 0;

    function typingEffect() {
      const word = words[i].split('');

      function loopTyping() {
        if (!startTyping) return;

        if (word.length > 0) {
          if (span) {
            span.innerHTML += word.shift();
          }
        } else {
          setTimeout(deletingEffect, 1000);
          return;
        }

        setTimeout(loopTyping, 150);
      }

      loopTyping();
    }

    function deletingEffect() {
      const word = words[i].split('');

      function loopDeleting() {
        if (!startTyping) return;

        if (word.length > 0) {
          word.pop();

          if (span) {
            span.innerHTML = word.join('');
          }
        } else {
          if (words.length > i + 1) i++;
          else i = 0;

          setTimeout(typingEffect, 200);
          return;
        }
        setTimeout(loopDeleting, 100);
      }
      loopDeleting();
    }

    typingEffect();

    return () => {
      startTyping = false;
      if (span) span.innerHTML = '';
    };
  }, []);

  return (
    <>
      <Head
        title={translate('landingPageHeadTitle')}
        description={translate('landingPageHeadDescription') ?? ''}
      />

      <Container>
        <Hero />
        <Clients />
        <Video />
        <ROI />
        <Modules />
        <Departments />
        <Plans />
        <Cases />
        <ContactSales />
      </Container>
    </>
  );
}
