import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Language = 'en-us' | 'pt-br';
export type UrlPrefix = '/en' | '';

export type ConfigState = {
  language: Language;
  urlPrefix: UrlPrefix;
};

const getUrlPrefix = (language: Language): UrlPrefix =>
  language === 'en-us' ? '/en' : '';
('');

const getConfigState = (): ConfigState => {
  const currentPath = window.location.pathname;
  const language =
    currentPath.startsWith('/en/') || currentPath === '/en' ? 'en-us' : 'pt-br';

  return { language, urlPrefix: getUrlPrefix(language) };
};

export const configState = createSlice({
  name: 'config',
  initialState: getConfigState(),
  reducers: {
    changeLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
      state.urlPrefix = getUrlPrefix(action.payload);
    },
  },
});

export const { changeLanguage } = configState.actions;

export default configState.reducer;
