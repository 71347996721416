import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100% !important;

  height: 100%;
  max-height: 100% !important;

  @media (max-width: 1100px) {
    flex-direction: column !important;
  }
`;

export const Tabs = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  @media (max-width: 1100px) {
    width: 100%;
    height: fit-content;

    flex-direction: row;

    margin-bottom: 4rem;
  }

  @media (max-width: 500px) {
    margin-bottom: 2rem;
  }
`;

type TabProps = {
  selected: boolean;
  color: string;
};

export const Tab = styled.button<TabProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 0.5rem;

  padding: 1rem;

  svg {
    width: 3rem;
    height: 3rem;

    padding: 0.75rem;

    background-color: ${({ theme }) => theme.colors.gray2};
    border-radius: 100%;

    color: ${({ theme }) => theme.colors.white};
    transition: color 0.2s ease-in-out;

    align-self: center;
  }

  p {
    color: ${({ theme }) => theme.colors.gray4};
    font-size: 0.85rem;
    font-weight: 500;
  }

  ${({ color, selected }) =>
    !selected &&
    css`
      &:hover {
        border-color: ${color};

        svg {
          color: ${color};
        }
      }
    `}

  ${({ selected, color, theme }) =>
    selected &&
    css`
      border: 1px solid ${color};

      > div {
        width: 2px;
        height: 0px;

        margin-right: -2px;

        background: ${color};
      }

      svg {
        background-color: ${color};
      }

      p {
        color: ${theme.colors.gray6};
        font-weight: 600;
      }
    `}

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    p {
      display: none;
    }
  }

  @media (max-width: 440px) {
    svg {
      height: 2rem;
      width: 2rem;

      background: none;
      border-radius: 0;

      color: ${({ selected, color, theme }) =>
        selected ? color : theme.colors.gray3};

      padding: 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 3rem;

  @media (max-width: 1100px) {
    height: 100%;
    margin-left: 0;
  }
`;
