export const ProductDataENUSTranslations = {
  productDataHeadDescription:
    'The Feature Store’s up-to-date indicators and series are ready to be used in your planning and decision-making activities.',

  productDataHeroTitle: 'Data-driven decisions need good data',
  productDataHeroDescription:
    'Data is the cornerstone of modern business. But data-driven decision-making is only as good as the data it uses. Log in and access useful data in seconds.',
  productDataHeroButtonPrimary: 'Book a Demo',
  productDataHeroButtonSecondary: 'See Data Starter plan',

  productDataSection1Title: 'All the data you need in one place',
  productDataSection1Description:
    'Access the Feature Store’s economic data and projections, including pricing indexes, credit, and climatic indicators. Over 130k series, 1,900 indicators and 100 trusted sources. Data analysis is easier and more effective when everything you need is in a single place.  ',
  productDataSection1ImageAlt: '',

  productDataSection2Title: 'Ready to use',
  productDataSection2Description:
    'We do the work to collect, prepare, structure, format and update the data. Our economic experts validate everything. You focus on bringing the data into your strategic and operational processes.',
  productDataSection2ImageAlt: '',

  productDataSection3ImageAlt: '',
  productDataSection3Title: 'Real-time updates',
  productDataSection3Description:
    'Set alerts to receive notifications when your datasets are updated. You receive email alerts (and Excel files) to ensure you are always working with the latest data.',

  productDataSection4Title: 'Get instant answers to your questions',
  productDataSection4Description:
    'Use Chat4i - 4intelligence´s chatGPT - to answer your questions about the data and your business. It´s simple and intuitive. Everyone from executives to specialists and non-technical teams can ask questions and get responses in seconds.',
  productDataSection4ImageAlt: '',

  productDataSection5ImageAlt: '',
  productDataSection5Title: 'Easy to integrate',
  productDataSection5Description:
    'Bring your data and projections from the Feature Store into your software. Whether it’s Power BI, Excel, Python or any other method, the Feature Store gives you complete flexibility and integrates smoothly into your workflow.',

  productDataContactSalesTitle:
    'Access ready-to-use economic data to power your business',
  productDataContactSalesButton: 'Enjoy Data for free',

  productDataTestimonialsClient1Name: 'Evandro Schulz',
  productDataTestimonialsClient1JobDescription: 'Risk Management Specialist',
  productDataTestimonialsClient1Company: 'Bradesco',
  productDataTestimonialsClient1Quote:
    '“The Feature Store impressed us the most. This tool is incredible. The data is beautifully organized, removing the headache of data processing. Everything is concentrated in one place, with standardized files. We no longer need to search on other sites.”',

  productDataTestimonialsClient2Name: 'Tatiana Celani',
  productDataTestimonialsClient2JobDescription: 'Head of Customer Intelligence',
  productDataTestimonialsClient2Company: 'Enel',
  productDataTestimonialsClient2Quote:
    '“4intelligence is a strong and reliable partner. We appreciate the format, quality and accuracy of the outputs, and also the ability to effectively resolve the problems that we identify.”',

  productDataTestimonialsClient3Name: 'Pedro Andrade',
  productDataTestimonialsClient3JobDescription: 'Projections Specialist',
  productDataTestimonialsClient3Company: 'Energisa',
  productDataTestimonialsClient3Quote:
    '“With 4intelligence, we are able to quickly analyze large volumes of data. We test various variables and get a deep understanding of which ones really impact our projections.”',
};
