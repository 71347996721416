export const LandingPageCasesContentPTBRTranslations = {
  landingPageCasesSubtitle: 'Estudos de caso',
  landingPageCasesTitle:
    'Como melhoramos a tomada de decisões em grandes empresas',

  landingPageCasesPersonalCareTitle:
    'Projeção de mercado para otimizar o processo de S&OP',
  landingPageCasesPersonalCareDescription:
    'Multinacional de higiene e beleza reduziu a burocracia em seu planejamento de operações e vendas (S&OP) por meio de projeções mais acuradas, precisas e ágeis.',

  landingPageCasesAutomotiveTitle:
    'Projeção de vendas para otimizar a produção de caminhões',
  landingPageCasesAutomotiveDescription:
    'Ajudamos uma multinacional de automóveis a prever como seriam suas vendas e alcançar um aumento de 45,7% nas vendas de caminhões.',

  landingPageCasesFoodTitle:
    'Prevendo aumento nos custos e otimizando o pricing',
  landingPageCasesFoodDescription:
    'Ajudamos uma multinacional a concluir a análise de sensibilidade e a alcançar um aumento de 26% nas receitas.',

  landingPageCasesLogisticsTitle:
    'Projeção de produção, exportação, consumo e estoque',
  landingPageCasesLogisticsDescription:
    'Ajudamos uma empresa de logística a aprimorar suas projeções de produção, exportação, consumo e estoque e alcançar um aumento de 10% no total de cargas transportadas.',
};
