import React from 'react';

import { BookBookmark, ChartLine, Cube, Database } from '@phosphor-icons/react';

export function getPhosphorIcons(
  icon: string,
  weight: 'bold' | 'duotone' | 'fill' | 'light' | 'regular' | 'thin',
) {
  switch (icon) {
    case 'book-bookmark':
      return <BookBookmark weight={weight} />;
    case 'chart-line':
      return <ChartLine weight={weight} />;
    case 'database':
      return <Database weight={weight} />;
    case 'cube':
      return <Cube weight={weight} />;
    default:
      return <BookBookmark weight={weight} />;
  }
}
