import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;

    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;

      padding: 1px;
    }
    ::-webkit-scrollbar-track {
      margin-top: 3px;
      margin-bottom: 3px;

      background: ${({ theme }) => theme.colors.gray1};
      
      transition: 0.2s;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.gray3};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.gray4};
    }
    
  }

  html, body, #root {
    width: 100%;
    height: 100%;
  }

  html {
    font-size: 100%; // 1rem = 16px
    
    @media (max-width: 1366px) {
	    font-size: 87.5%; // 1rem = 14px
	  }

    @media (max-width: 1000px) {
	    font-size: 75%; // 1rem = 12px
	  }
  }

  body, button, input, textarea {
    font-family: 'Poppins', sans-serif;
  }

  body {
    overflow-x: hidden;

    -webkit-font-smoothing: antialiased;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;

    background: none;
    border: none;
  }

  h1 {
    font-size: 4rem;
    line-height: 5.5rem;
    font-weight: 700;

    color: ${({ theme }) => theme.colors.gray9};
  }

  h2 {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray9};
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 120%;

    color: ${({ theme }) => theme.colors.primary};
  }

  h4 {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.08rem;
    text-transform: uppercase;

    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray7};
  }

  .customTooltipTheme {
    background: ${({ theme }) => theme.colors.white} !important;
    color: ${({ theme }) => theme.colors.gray6} !important;
    max-width: 18.75rem;
    width: fit-content !important;
    border: 2px solid ${({ theme }) => theme.colors.primary} !important;
    border-radius: 8px;

    opacity: 1;

    font-size: 0.875rem;
  }

  .react-tooltip__place-left {
    > div {
      border-bottom: 2px solid ${({ theme }) =>
        theme.colors.primary} !important;
      border-right: 2px solid ${({ theme }) => theme.colors.primary} !important;

      top: 0.8rem !important;
      right: -5px !important;
    }
  }

  .react-tooltip__place-right {
    > div {
      border-bottom: 2px solid ${({ theme }) =>
        theme.colors.primary} !important;
      border-right: 2px solid ${({ theme }) => theme.colors.primary} !important;

      top: 0.8rem !important;
      left: -5px !important;
    }
  }

  .react-tooltip__place-top {
    > div {
      border-bottom: 2px solid ${({ theme }) =>
        theme.colors.primary} !important;
      border-right: 2px solid ${({ theme }) => theme.colors.primary} !important;

      bottom: -6.4px !important;
    }
  }

  .react-tooltip__place-bottom {
    > div {
      border-top: 2px solid ${({ theme }) => theme.colors.primary} !important;
      border-left: 2px solid ${({ theme }) => theme.colors.primary} !important;

      top: -6.4px !important;
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 3rem;
      line-height: 130%;
    }
  }
`;
