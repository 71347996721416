import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import heroImgEN from 'src/assets/hero-en.svg';
import heroImgPT from 'src/assets/hero-pt.svg';
import { Button } from 'src/components/Button';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';

import {
  Container,
  Background,
  Information,
  Content,
  ImageContainer,
} from './styles';

export function Hero() {
  const { language } = useSelector((state: RootState) => state.config);
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container>
      <Background />

      <Information>
        <Content>
          <h1>
            Better <span id="better-type" />,
          </h1>
          <h1>better decisions</h1>

          <p>{translate('landingPageHeroParagraph')}</p>

          <Button
            buttonType="primary"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                'Solicite uma demonstração',
                'Ir para falar com vendas',
                location.pathname,
              );
              navigate('/contact-sales');
            }}
            data-testid="book-demo-button"
          >
            {translate('bookDemo')}
          </Button>
        </Content>

        <ImageContainer>
          <img
            src={language === 'pt-br' ? heroImgPT : heroImgEN}
            alt={translate('landingPageHeroImgAlt') ?? undefined}
          />
        </ImageContainer>
      </Information>
    </Container>
  );
}
