import React, { useEffect } from 'react';

import { ChartLineDown, ProjectorScreenChart } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SolutionCase from 'src/assets/logistics-case-solution.png';
import Thumb from 'src/assets/logistics-case.jpg';
import { ContactSales } from 'src/components/ContactSales';
import { Description } from 'src/components/Description/styles';
import { Head } from 'src/components/Head';
import { LanguageWarning } from 'src/components/LanguageWarning';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsPageView } from 'src/utils/google-analytics';

import {
  ChallengeContent,
  Container,
  Content,
  Image,
  SectionContainer,
} from '../components/Case/styles';

export function LogisticsCase() {
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(
      location.pathname,
      'Tela de Cases - Logística',
    );
  }, [location.pathname]);

  return (
    <Container>
      <Head
        title={`Case | ${translate('casesLogistics')} | 4intelligence`}
        description={translate('casesPageLogisticsHeadDescription') ?? ''}
      />

      <Title>
        <h4>Case empresa de logística</h4>
        <h2>Projeção de produção, exportação, consumo e estoque de produtos</h2>
      </Title>

      {urlPrefix === '/en' && <LanguageWarning />}

      <Description>
        <p>
          Veja como empresa de logística resolveu a necessidade de aprimoramento
          das projeções de produção, exportação, consumo e estoque com solução
          da 4intelligence.
        </p>
      </Description>

      <img src={Thumb} alt="logistics case" />

      <SectionContainer className="case-challenge">
        <Content>
          <h2>Tomada de decisão embasada em dados econômicos</h2>
          <p>
            A empresa de logística tinha a necessidade de ter projeções de
            produção, exportação, consumo e estoque robustas e construir
            cenários adequados a fim de que contribuam para as tomadas de
            decisão e, por conseguinte, para o planejamento estratégicos da
            companhia.
          </p>
        </Content>
        <ChallengeContent>
          <div>
            <div>
              <ChartLineDown />
            </div>
            <h3>Ferramenta customizada</h3>
            <p>
              A multinacional implementou a nova plataforma para fazer o
              monitoramento das projeção de vendas, sell out e gerenciamento do
              market share companhia
            </p>
          </div>

          <div>
            <div>
              <ProjectorScreenChart />
            </div>
            <h3>Mais agilidade e assertividade</h3>
            <p>
              A implementação da ferramenta customizada na companhia veio para
              agilizar o processo de tomada de decisão e torná-lo mais preciso
            </p>
          </div>
        </ChallengeContent>
      </SectionContainer>

      <SectionContainer>
        <Image>
          <img
            src={SolutionCase}
            alt="Represents logistics case"
            style={{ height: '100%' }}
          />
        </Image>

        <Content>
          <h2>Solução</h2>
          <p>
            Com a 4intelligence, foram construídas projeções de produção,
            exportação, consumo e estoque robustas que impactaram diretamente no
            lucro da companhia, tendo em vista o aumento no total de carga
            transportado pela mesma.
          </p>
        </Content>
      </SectionContainer>

      <SectionContainer>
        <Content style={{ width: '80%', textAlign: 'center' }}>
          <h2>Resultados</h2>
          <p style={{ fontStyle: 'italic' }}>
            “O lucro antes de juros, impostos, depreciação e amortização
            (Ebitda) atingiu R$ 563,7 milhões, apresentando um salto de R$ 46,4
            milhões na comparação com 2020. Além disso, houve aumento de 10% no
            total de cargas transportadas em 2021 na comparação com o exercício
            anterior, atingindo 11,1 milhões de tonelada.&rdquo;
          </p>
        </Content>
      </SectionContainer>

      <ContactSales />
    </Container>
  );
}
