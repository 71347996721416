export const ProductPlanningPTBRTranslations = {
  productPlanningHeroTitle:
    'Aprimore a tomada de decisões através da colaboração',
  productPlanningHeroDescription:
    'O planejamento tradicional, no qual diferentes áreas da organização criam seus próprios planos, muitas vezes é fragmentado e difícil de implementar. Por outro lado, oferecemos a você um único espaço para as equipes trabalharem juntas. Faça login e comece a colaborar. ',

  productPlanningHeroButtonPrimary: 'Fale com vendas',
  productPlanningHeroButtonSecondary: 'Conheça nossos planos',

  productPlanningSection1Title: 'Única fonte de verdade',
  productPlanningSection1Description:
    'O Workspace permite que você capture tudo sobre o processo de tomada de decisões em um único local. Mantenha um registro de todas as decisões, dos dados utilizados e das pessoas envolvidas. Evite a confusão de um processo de planejamento desarticulado otimizando a colaboração e os processos entre diferentes áreas.',
  productPlanningSection1ImageAlt: '',

  productPlanningSection2Title:
    'Possibilita a verificação dos resultados em relação às previsões',
  productPlanningSection2Description:
    'É hora de aumentar a responsabilidade e monitorar suas decisões! Simplesmente navegue entre as versões publicadas e compare os resultados de hoje com as previsões feitas anteriormente. A plataforma permite que você adeque os resultados do planejamento estratégico à sua realidade operacional diária.',
  productPlanningSection2ImageAlt: '',

  productPlanningSection3ImageAlt: '',
  productPlanningSection3Title: 'Permite múltiplos usuários',
  productPlanningSection3Description:
    'No Workspace, você tem vários logins de usuários. Qualquer pessoa envolvida em um projeto pode atualizar as informações no painel de controle. Você decide os níveis de acesso e garante que os usuários recebam resultados apropriados para o seu perfil, seja mais técnico ou comercial.',

  productPlanningSection4Title:
    'Simplifica as aprovações e governança do processo',
  productPlanningSection4Description:
    'À medida que o planejamento integrado acontece em todos os níveis da organização, uma governança eficaz é vital. Quem pode tomar a decisão? Quem aprova a decisão? Quem é responsável pelos resultados? Além de mostrar o histórico completo de como cada decisão é tomada, o Workspace tem um sistema simples de gerenciamento de aprovações. ',
  productPlanningSection4ImageAlt: '',

  productPlanningSection5ImageAlt: '',
  productPlanningSection5Title: 'Projetado para suas necessidades exclusivas',
  productPlanningSection5Description:
    'Sua organização é única, com suas próprias prioridades, prazos, estruturas e processos. Nossa plataforma é flexível e se adapta aos detalhes de sua realidade operacional. Como cliente, fazemos parceria com você e incentivamos ativamente sua opinião sobre como podemos melhorar o produto.',

  productPlanningContactSalesTitle:
    'Você deseja um processo de planejamento mais integrado?',
  productPlanningContactSalesButton: 'Solicite uma demonstração',

  productPlanningTestimonialsClient1Name: 'Gabriel Leite',
  productPlanningTestimonialsClient1JobDescription: 'Risk Coordinator',
  productPlanningTestimonialsClient1Company: 'HDI Insurance',
  productPlanningTestimonialsClient1Quote:
    '“Fazemos projeções para muitos produtos diferentes. Em vez de levar nossos resultados para o Excel, começamos a usar o Workspace. É muito fácil de usar. Nos economiza muito tempo ao apresentar nossos resultados para o restante da empresa.”',

  productPlanningTestimonialsClient2Name: 'Renata Maria Oliveira',
  productPlanningTestimonialsClient2JobDescription: 'Revenue Management',
  productPlanningTestimonialsClient2Company: 'M Dias Branco',
  productPlanningTestimonialsClient2Quote:
    '“Estamos gostando muito do Workspace. Poder visualizar projeções agregadas e desagregadas é muito útil para nossos processos de planejamento e previsão de demanda.”',
};
