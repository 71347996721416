import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk, { ThunkAction } from 'redux-thunk';

import configReducer, { ConfigState } from './reducers/Config';

type CombineReducer = {
  config: ConfigState;
};

const reducers = combineReducers({
  config: configReducer,
});

export const store = configureStore<CombineReducer>({
  reducer: reducers,
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
