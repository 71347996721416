import styled, { css } from 'styled-components';

export const Container = styled.div`
  > div + div {
    margin-top: 8rem;
  }

  @media (max-width: 500px) {
    > div + div {
      margin-top: 5rem;
    }
  }
`;

type ModuleProps = {
  index: number;
};

export const Module = styled.div<ModuleProps>`
  ${({ index, theme }) => {
    const colors = [
      { normal: theme.colors.primary, hover: theme.colors.primaryDark },
      { normal: theme.colors.secondary, hover: theme.colors.secondaryDark },
      { normal: theme.colors.green4, hover: theme.colors.green6 },
    ];

    return css`
      width: 100%;

      display: flex;
      align-items: center;
      gap: 6rem;

      h4 {
        color: ${colors[index].normal};
      }

      p {
        color: ${theme.colors.gray5};
        font-size: 1rem;
        line-height: 150%;

        margin-top: 1rem;
      }

      button {
        border-color: ${colors[index].normal};
        color: ${colors[index].normal};

        margin-top: 2rem;

        svg {
          margin-left: 0.5rem;
          margin-right: 0;
        }

        &:hover {
          border-color: ${colors[index].hover};
          background-color: ${colors[index].hover};
          color: ${theme.colors.white};
        }
      }

      aside {
        width: 560px;
        height: 400px;

        display: flex;
        align-items: ${index !== 3 ? 'center' : 'end'};
        justify-content: ${index !== 3 ? 'start' : 'center'};
      }

      @media (max-width: 880px) {
        flex-direction: column;
        gap: 1rem;

        &:not(:nth-child(2)) {
          flex-direction: column-reverse;
        }

        > div {
          display: flex;
          flex-direction: column;
        }

        aside {
          width: 100%;
          height: auto;
        }

        img {
          width: 100%;
          margin: 0 auto;
        }
      }
    `;
  }}
`;

export const Quote = styled.div<ModuleProps>`
  ${({ index, theme }) => {
    const colors = [
      theme.colors.primary,
      theme.colors.secondary,
      theme.colors.green4,
    ];

    return css`
      position: relative;

      width: 100%;

      background-color: ${colors[index]};
      background: ${`${colors[index]}14`};

      border-radius: 0.5rem;

      padding: 2.5rem 3.5rem;
      margin-top: 3rem;

      p {
        color: ${theme.colors.gray5};
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0.75rem;
      }

      small {
        color: ${theme.colors.gray5};
      }

      svg {
        position: absolute;
        top: 1rem;
        left: 1.5rem;

        width: 4rem;
        height: 4rem;

        path {
          fill: ${colors[index]} !important;
        }
      }
    `;
  }}
`;
