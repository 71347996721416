import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import notFoundImage from 'src/assets/404.svg';
import { TrackGoogleAnalyticsPageView } from 'src/utils/google-analytics';

import { Container } from './styles';

export function NotFound() {
  const { t: translate } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(location.pathname, 'Tela 404');
  }, [location.pathname]);

  return (
    <Container>
      <img src={notFoundImage} alt="404 error" />

      <h1>{translate('notFoundPageTitle')}</h1>
      <p>{translate('notFoundPageDescription')}</p>
    </Container>
  );
}
