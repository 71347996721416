import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  margin: auto 0;

  img:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
