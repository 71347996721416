import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  padding: 5rem 7rem 0 7rem;

  > div:first-child {
    align-items: center;
    text-align: center;
  }

  @media (max-width: 500px) {
    padding: 3rem 2rem 0 2rem;
  }
`;

export const Content = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  background: ${({ theme }) => theme.colors.white};

  padding-bottom: 8rem;
  margin-top: 5rem;
`;

export const Card = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: start;

  padding: 1.5rem;

  border: 2px solid rgba(194, 219, 255, 0.58);
  box-shadow: 0px 0px 16px 0px rgba(129, 179, 255, 0.12);
  border-radius: 16px;

  background: ${({ theme }) => theme.colors.white};
  z-index: 1;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    margin-bottom: 1rem;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;

    color: ${({ theme }) => theme.colors.primary};

    margin-right: 1rem;
  }

  button {
    padding: 0;
    margin-top: 1rem;

    font-size: 1.25rem;
  }

  h2 {
    font-size: 1.65rem;
    line-height: 2.525rem;
    font-weight: 600;

    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    font-size: 1.15rem;
    line-height: 1.875rem;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;
