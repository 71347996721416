import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    color: ${({ theme }) => theme.colors.primary};

    margin-bottom: 1rem;
  }

  h1 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 130%;

    color: ${({ theme }) => theme.colors.gray9};
  }

  h2 {
    font-size: 3rem;
    font-weight: 600;
    line-height: 130%;

    color: ${({ theme }) => theme.colors.gray8};
  }

  h3 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 130%;

    color: ${({ theme }) => theme.colors.gray7};
  }
`;
