import React from 'react';

import { Container } from './styles';

export function TermsOfUseContent() {
  return (
    <Container id="terms-of-use">
      <b>Versão 01 atualizado em 25/09/2023</b>

      <p>
        Este instrumento estabelece as condições de Utilização dos Serviços do
        produto 4casthub mediante licença de uso gratuito que pertencente, única
        e exclusivamente a 4I FOR INTELLIGENCE DATA SCIENCE E CONSULTORIA LTDA
        (“4intelligence” ou “4i”), empresa jurídica entidade de direito privado,
        inscrita no CNPJ nº 26.326.521/0001-27, sediada na Avenida João de
        Camargo, 510, Prédio VI (ICC) – 7º andar, Sala 02, Bairro: Centro, CEP:
        37.536-001, na cidade de Santa Rita do Sapucaí - MG, única e exclusiva
        proprietária das marcas registradas e domínios associados ao aplicativo.
      </p>

      <p>
        Além de seguir os Termos de Uso, você deve estar atento à nossa Política
        de Privacidade disponível nos sites da 4intelligence (
        <a
          href="https://www.4intelligence.ai"
          target="_blank"
          rel="noreferrer"
          data-testid="landing-page-link"
        >
          https://www.4intelligence.ai
        </a>
        ), que também integra este documento e estabelece informações sobre as
        condições sob as quais a 4intelligence (“O Controlador”) atua no
        processamento de seus dados pessoais durante a oferta e uso de nosso
        Aplicativo, bem como, informações sobre os seus direitos como titular de
        tais dados.
      </p>

      <p style={{ marginTop: '1rem' }}>1. SOBRE OS TERMOS DE USO</p>

      <p>
        Todas as regras de utilização das Aplicações pelo “Cliente” e quando o
        contexto assim o exigir, o “Cliente” deve incluir “Usuários” associados
        a ele que também serão regidos por este documento.
      </p>

      <p>
        Como condição de acesso e utilização do Aplicativo e de qualquer uma de
        suas funcionalidades, os termos aqui descritos{' '}
        <b>deverão ser aceitos</b>. Portanto, solicitamos que antes da
        manifestação da sua concordância, o Cliente{' '}
        <b>LEIA ESTE DOCUMENTO COM ATENÇÃO</b>.
      </p>

      <p>
        Ao aceitar estes termos, por qualquer um dos mecanismos que expressam
        aceitação, como clicando em “Li e concordo com os termos”, acessando,
        usando ou transmitindo informações para nosso Aplicativo, bem como pela
        formalização do contrato de licença de uso, você concorda ampla e
        irrestritamente com todos os termos, cláusulas e condições deste
        documento.
      </p>

      <p>
        Se o Cliente não concordar ou deixar de concordar com qualquer
        disposição deste documento, o uso do Aplicativo será descontinuado
        imediatamente.
      </p>

      <p style={{ marginTop: '1rem' }}>
        <b>2. SOBRE APLICATIVO</b>
      </p>

      <p>
        <u>2.1. Acesso e uso</u>
      </p>

      <p>
        O acesso e uso do Aplicativo se dá via navegadores web, por meio de
        internet de conexão contínua (on-line), de responsabilidade do usuário.
        Caberá a você e aos seus respectivos usuários garantir que seu
        equipamento é compatível com as características técnicas (software e
        hardware) para permitir o uso do aplicativo, bem como, mas não se
        limitando a:
      </p>

      <ul>
        <li>Sistemas operacionais:</li>
        <ul>
          <li>Windows versão 11</li>
          <li>Linux Ubuntu 16.04 em diante ou equivalente</li>
          <li>Resolução mínima de tela: 1024x768</li>
        </ul>
      </ul>

      <ul>
        <li>Navegadores suportados pelo 4casthub:</li>
        <ul>
          <li>Chrome: versão 116.05845.187 em diante ou equivalente</li>
          <li>Firefox versão 117.0 em diante ou equivalente</li>
        </ul>
      </ul>

      <p>
        Outros navegadores também podem ser usados sem qualquer problema de
        renderização e layout das páginas.
      </p>

      <p>
        O Aplicativo requer conexão constante com a Internet para serem
        utilizados. A 4intelligence não é responsável pelo serviço de acesso à
        Internet do Cliente, bem como pelo seu funcionamento e manutenção.
      </p>

      <p>
        Eventuais oscilações, interrupções e qualquer outra má funcionalidade da
        internet poderá causar interrupção no serviço.
      </p>

      <p>
        <u>2.2. Finalidades e Funcionalidades</u>
      </p>

      <p>
        Em geral, o Aplicativo é uma ferramenta de{' '}
        <em>Business Intelligence</em>, contratada isoladamente ou
        concomitantemente, utilizado para orientar o Cliente em suas decisões
        táticas e estratégicas, que trabalha através do <em>upload</em> de dados
        que, posteriormente, disponibilizarão projeções de forma automatizada
        relacionada ao conteúdo relacionado e aos parâmetros definidos pelo
        Cliente.
      </p>

      <p>
        O <em>4CastHub</em> é aplicativo que utiliza dados carregados pelo
        Cliente e um repositório de dados públicas, para retornar o conjunto de
        modelos de séries temporais com melhor precisão preditiva para fins de
        tomada de decisão, , através de seu proprietário algoritmos. Seu módulo
        atualmente funcional para licença de uso gratuito é:
      </p>

      <div
        style={{
          marginLeft: '1.5rem',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
        }}
      >
        <p>
          <b>Feature Store:</b> um repositório de dados organizado com
          informações públicas e privadas, processadas ou não, com o objetivo de
          facilitar a construção de inteligência pelo usuário. A informação
          contida nele está pronta para uso, seja por meio de modelagem
          estatística ou pelo uso direto dos dados. Ele possui as seguintes
          características:
        </p>

        <p>i) Coleta automatizada de dados.</p>
        <p>
          ii) Banco de dados diversificado, organizado, padronizado e compatível
          com as necessidades do processo de modelagem de previsão.
        </p>
        <p>iii) Visualização das características, bem como seus metadados.</p>
        <p>
          iv) Projeções para várias variáveis já existentes no banco de dados.
        </p>
        <p>
          v) Espaço do usuário, onde o usuário favorita variáveis para
          acompanhamento rotineiro.
        </p>
        <p>
          vi) Chat 4i, intelligence artificial generativa da 4intelligence, que
          simplifica a jornada de busca com indicadores.
        </p>
      </div>

      <p>
        <u>2.3. Limitação de recursos</u>
      </p>
      <p>
        2.3.1. A Aplicação é o tipo de ferramenta destinada apenas a auxiliar o
        Cliente no projeto, análise, simulação, estimativa, testes e/ou outras
        atividades e NÃO cumpre a função de substituir o projeto em si, análise,
        simulação, estimativa, testes e/ou outros e atividades independentes e
        já desenvolvidas pelo cliente.
      </p>
      <p>
        2.3.2. Você está ciente de que certas ações de sua parte podem causar
        estresse na infraestrutura quanto à capacidade do Aplicativo, como
        solicitações de inserção simultânea de muitos recursos ou manipulação de
        grandes bancos de dados, o que pode impactar o uso do Formulários.
      </p>
      <p>
        2.3.3. Devido à grande variedade de usos potenciais para o conteúdo do
        Aplicativo, não são todas as situações em que suas respectivas
        funcionalidades que podem ser utilizadas foram testadas.{' '}
        <b>
          O Cliente reconhece e concorda que os recursos podem não alcançar os
          resultados desejados dentro
        </b>{' '}
        das restrições do conteúdo utilizado.
      </p>
      <p>
        2.3.4 Usuários com licença de uso gratuita poderão utilizar todos os
        recursos do módulo Feature Store descritos na seção “2.2 Finalidades e
        Funcionalidades” com limitação ao conjunto de dados que podem ser
        acessados e ao número de interações com a funcionalidade Chat 4i.
      </p>
      <p>
        2.3.5 Usuários com licença de uso gratuita não tem acesso ao suporte
        ponta a ponta por parte da equipe de ciência de dados da 4intelligence.
        O suporte a esses Usuários será feito exclusivamente através do e-mail{' '}
        <span>support@4intelligence.ai</span>.
      </p>
      <p>
        2.3.6. Ainda em referência aos usuários com licença de uso gratuita,{' '}
        <b>
          haverá as seguintes restrições de uso que, caso o cliente ou os
          usuários apliquem, ensejará a imediata descontinuidade do fornecimento
          e a rescisão do presente termos por culpa exclusiva do cliente e dos
          usuários
        </b>
        :
      </p>
      <ul>
        <li>
          Utilização abusiva que venha a gerar sobrecarga ou uso excessivo de
          recursos do Aplicativo;
        </li>
        <li>Compartilhamento de acessos a usuários não cadastrados;</li>
        <li>Uso compartilhado de contas do aplicativo;</li>
        <li>
          Utilização de práticas que tentem contornar restrições de uso da sob a
          licença gratuita;
        </li>
        <li>
          Utilização de práticas que possam ser consideradas nocivas ao bom
          funcionamento do aplicativo ou que possam gerar prejuízo a outros
          usuários;
        </li>
        <li>
          Replicação de projeções 4intelligence fora do aplicativo sem a
          indicação da fonte.
        </li>
        <li>
          Reprodução de projeções 4intelligence fora do aplicativo com fins
          comerciais.
        </li>
      </ul>

      <p>
        2.3.7. A lista acima é meramente explicativa e exemplificativa, qualquer
        outra ação não prevista nessa lista que ocasione danos a ferramenta em
        seu uso, bem como, qualquer ação de má-fé, demais ações previstas nesse
        termo ou que seja considerada qualquer tipo de crime pela legislação
        local do país e pela legislação brasileira acarretará a imediata
        descontinuidade do fornecimento, sem aviso prévio e sem prejuízo a
        apuração de eventuais perdas e danos.
      </p>

      <p>
        2.3.8. Em caso de descontinuidade da licença gratuita, o cliente poderá
        recuperar trabalhos lançados no Chat4i em até 30 (trinta) dias após o
        cancelamento do serviço através do canal descrito no item 2.3.7.
      </p>

      <p style={{ marginTop: '1rem' }}>
        <b>3. CONDIÇÕES DE USO DOS APLICATIVOS</b>
      </p>

      <p>
        <u>3.1. Conteúdo</u>
      </p>

      <p>
        3.1.1. O Usuário é o único responsável pelo acesso e utilização do
        Aplicativo e seu conteúdo. A 4intelligence não terá qualquer
        responsabilidade ou interferência na forma como os dados e as
        informações que compõem o conteúdo são utilizadas e manipuladas, nem
        sobre como eles são inseridos e processados, ficando, desde já,
        estabelecido que toda a responsabilidade relacionada ao uso da aplicação
        e o seu conteúdo serão assumidos exclusivamente pelo Cliente.
      </p>

      <p>
        3.1.2. Além disso, a 4intelligence não edita os dados inseridos pelo
        usuário para uso próprio ou da organização a que pertence, a eventual
        inserção de dados incorretos pode levar a resultados falsos, imprecisos
        ou incorretos, o que será de responsabilidade única e exclusiva do
        Cliente.
      </p>

      <p>
        3.1.3. A 4inteligence não revisará ou fará qualquer revisão prévia,
        controle, moderação ou censura de qualquer conteúdo do Cliente antes de
        ser armazenado, de modo que qualquer conteúdo do Cliente armazenado no
        Aplicativo não são e não serão de forma alguma endossados pela
        4intelligence.
      </p>

      <p>
        3.1.4. Em qualquer caso repudiamos aqueles conteúdos que contenham: (i)
        utilização de quaisquer expressões, sinais ou palavras contrárias à
        moral e aos bons costumes; (ii) termos que ofendem a privacidade a honra
        ou a imagem de pessoas, produtos e empresas; (iii) sinais ou palavras
        que ofendem a liberdade de consciência, religião, sexo e raça; (iv) uso
        não autorizado de marca registrada e propriedade intelectual de
        terceiros; (v) incentivar o uso de violência, drogas ou qualquer outra
        atividade ilícita; ou (vi) preconceituosa, discriminatória, notas
        embaraçosas, pornográficas ou semelhantes.
      </p>

      <p>
        3.1.5. O Usuário é o único responsável pela legalidade dos dados e pela
        utilização dos mesmos, tornando a 4inteligência isenta de qualquer
        ilegalidade ou erro que ele possa cometer.
      </p>

      <p>
        <u>3.2. Uso de aplicativo</u>
      </p>

      <p>
        3.2.1. O Cliente reconhece e concorda que deve usar o Aplicativo apenas
        para seus fins comerciais normais e:
      </p>

      <p>
        (i) você não poderá usar o Aplicativo e seu conteúdo de maneira
        inconsistente com as disposições contidas nas leis, regulamentos,
        códigos, decisões aplicáveis ao desempenho de suas atividades, ou
        qualquer outro uso que dissuada o propósito do Aplicativo, de acordo com
        o disposto nos Termos de Uso e na Política de Privacidade, incluindo,
        mas não restrita, as violações de direitos de propriedade intelectual;
        (ii) você deverá abster-se de usar, sem as devidas autorizações e/ou
        licenças necessárias para tanto, quaisquer obras ou bens de terceiros
        que possam ser protegidos por direitos de propriedade intelectual,
        independentemente de tais obras ou ativos são acessíveis através do
        Aplicativo; (iii) você será responsável por proteger seu acesso a dados
        e aplicações de forma confidencial e segura, incluindo, mas não se
        limitando a, seu login e senha, especialmente contra invasões indevidas,
        falhas de segurança e outras situações indesejadas; (iv) você não poderá
        compartilhar suas credenciais de acesso individuais entre diferentes
        usuários, ou seja, duas pessoas não poderão se cadastrar e/ou utilizar
        os Aplicativos através das mesmas credenciais de usuário/senha; (v) você
        não poderá vender, alugar, sublicenciar ou arrendar qualquer parte do
        serviço oferecido pela 4inteligência ou seu conteúdo;vi) você não pode
        fazer engenharia reversa, descompilar, desmontar, modificar ou criar
        Aplicativos derivados usando os códigos ou outras partes internas que
        compõem nossos formulários; (vii) você poderá se utilizar de técnicas
        para tentar responsabilizar terceiros ou ocultar sua identidade ou
        autoria no contexto de utilização do Aplicativo e/ou de acesso e
        utilização de conteúdo; (viii) você não pode usar nenhum mecanismo (como
        robôs rastreadores, extratores, spiders ou outros) que capturam e
        extraem dados do Aplicativo, deixando as informações restritas apenas ao
        ambiente do Aplicativo; (ix) você não pode enviar qualquer conteúdo que
        contenha vírus de computador, arquivos maliciosos ou usar qualquer
        serviço associado que possa danificar, desabilitar, sobrecarregar,
        permitir acessar ou dificultar o acesso aos Aplicativos ou que possa
        interferir no seu uso ou uso por outros licenciados; (x) quaisquer
        outros usos não compatíveis com os fins para os quais os Aplicativos foi
        pretendido;
      </p>
      <p>
        <b>
          3.2.2. Qualquer suspeita de uma das ações acima descritas ensejará a
          imediata descontinuidade de utilização da ferramenta, bem como, as
          devidas apurações cíveis e criminais eventualmente cometidas, sem
          prejuízo a eventuais apurações de perdas e danos.
        </b>
      </p>
      <p>
        3.2.3. Se a 4intelligence descobrir evidências de violação da Licença e
        dos Termos de Uso, condutas ilícitas ou que impactem a prestação dos
        serviços da 4inteligência a outros clientes, sem prejuízo de quaisquer
        outros medida judicial ou extrajudicial para pôr fim à violação na forma
        da lei aplicável, suspenderá imediatamente a prestação do serviço
        gratuito.
      </p>
      <p style={{ marginTop: '1rem' }}>
        <b>4. RESPONSABILIDADES DAS PARTES</b>
      </p>

      <p>
        4.1. O Cliente será o único responsável pela divulgação dos dados de
        acesso aos Usuários e, consequentemente, para possível acesso ao
        Aplicativo por terceiros não autorizados pela 4inteligência e/ou
        Cliente. A 4intelligence não é responsável por qualquer acesso e/ou uso
        do Aplicativo através da utilização dos dados do respectivo Usuário. O
        Usuário, por sua vez, compromete-se a informar prontamente à
        4inteligência qualquer acesso de origem desconhecida à conta, pelo canal
        devido.
      </p>

      <p>
        4.2. O Cliente será responsável por indenizar a 4intelligence e/ou
        quaisquer terceiros por lucros diretos ou indiretos, morais e/ou
        perdidos causados por ou relacionados ao uso indevido da ferramenta.
      </p>

      <p>
        4.3. A obrigação da 4intelligence com relação à execução dos serviços é
        uma obrigação de meio e não de resultado ou finalidade específica, não
        podendo garantir, em qualquer caso de utilização do Aplicativo, o
        alcance de um fim ou resultado específico, a maximização e realização de
        valor a favor do Cliente e não assume nenhuma responsabilidade
        relacionada ao sucesso da implementação/utilização.
      </p>

      <p>
        4.4. A 4intelligence compromete-se a não divulgar e/ou partilhar dados
        que contenham informações confidenciais e informações do cliente com
        suas partes, exceto metadados, dados estatísticos ou anonimizados.
      </p>

      <p style={{ marginTop: '1rem' }}>
        <b>5. PRIVACIDADE, CAPTURA E UTILIZAÇÃO DE DADOS</b>
      </p>

      <p>
        5.1. A 4intelligence, o Cliente e seus Usuários concordam e
        comprometem-se a cumprir todas leis e regulamentos aplicáveis no
        contexto do uso do Aplicativo e, particularmente, à Lei Federal
        Brasileira nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD).
      </p>

      <p>
        5.2. Além disso, no que diz respeito à proteção de dados e possíveis
        formas de utilização do conteúdo pelo Cliente e seus Usuários já
        concordaram em:
      </p>

      <p>
        (i) cumprir e fazer cumprir os Termos de Uso e as Políticas de
        Privacidade da 4intelligence, bem como como todas as outras regras
        aplicáveis ao uso do Aplicativo e do conteúdo que você acessa, incluindo
        garantir que seus Usuários o façam;
      </p>

      <p>
        (ii) garantir que os dados pessoais que podem ser carregados no
        Aplicativo, inclusive no que diz respeito a quaisquer dados sensíveis,
        estão enquadrados numa das bases legais para o tratamento de dados
        previstos no Artigo 7º e/ou Artigo 11 da LGPD. Quando o tratamento é
        baseado mediante consentimento, o Cliente será direta e exclusivamente
        responsável pela sua coleta de forma livre, informada, inequívoca e
        relacionada a um determinado fim, de acordo com a Lei;
      </p>

      <p>
        (iii) não utilizar quaisquer dados pessoais inseridos no Aplicativo para
        quaisquer fins que estão em desacordo ao expressamente fornecido ao
        Titular, nos termos da Lei;
      </p>

      <p>
        5.3. O Cliente reconhece e concorda que, no momento da conclusão das
        informações necessárias ao uso, dados pessoais de sua titularidade podem
        ter sido recuperados, base legal da Execução do Contrato, de acordo com
        políticas da 4intelligence sobre privacidade e proteção de dados, bem
        como de acordo com legislação em vigor
      </p>

      <p>
        5.4. Além dos dados pessoais, a 4i poderá coletar e analisar dados sobre
        o uso do Aplicativo, como frequência de logins, logs de utilização,
        comportamento de navegação, forma de interagir com a aplicação,
        registros técnicos e outras informações de entrada e uso da aplicação.
        Os Dados de Uso não serão considerados Informações Sensíveis, e a 4i
        poderá usar Dados de Uso para: (i) realizar diagnósticos de desempenho,
        falhas e correções no Aplicativo; e/ou (ii) fazer melhorias no
        Aplicativo, ferramentas de aprendizado na máquina, de modelos e
        desenvolver novos produtos e funcionalidades.
      </p>

      <p>
        5.4.1.{' '}
        <b>
          Para o melhor atendimento de suporte, acompanhamentos de desempenho da
          aplicação e melhorias de usabilidade, eventuais supressores de coleta
          de dados de uso da nossa aplicação podem não funcionar adequadamente.
        </b>
      </p>

      <p>
        5.5. Os demais termos e condições aplicáveis à coleta, armazenamento,
        processamento, e utilização de dados pessoais relativos única e
        exclusivamente ao Cliente (não ao conteúdo por ele inserido nos
        Aplicativos) são regidos pela Política de Privacidade, disponível nos
        sites da 4itelligence (“Política de Privacidade”).
      </p>

      <p>
        5.6. Depois de ler este Termos de Uso, bem como a Política de
        Privacidade, se você tiver mais perguntas ou reclamações{' '}
        <b>sobre seus dados pessoais</b>, você poderá exercer seus direitos
        entrando em contato com nosso DPO (Data Protection Officer), através do
        seguinte canal: <span>lgpd@4intelligence.com.br</span>.
      </p>

      <p style={{ marginTop: '1rem' }}>
        <b>6. LICENÇA DE USO TEMPORÁRIO</b>
      </p>

      <p>
        6.1. A 4intelligence é a legítima proprietária do Aplicativo e detentora
        de todos os direitos intelectuais, direitos de propriedade deles
        decorrentes e pode usar, usufruir e dispor de tais bens, tanto de forma
        remunerada ou não, conforme conferido pela Lei Federal brasileira nº
        9.608/1.998.
      </p>

      <p>
        6.2. A Aplicação Contratada está abrangida por um contrato não
        exclusivo, intransmissível, não havendo sublicença para uso do conjunto
        de softwares de inteligência de mercado e ciência de dados, não
        implicando a transferência do direito de propriedade, cuja titularidade
        permanece 4intelligence.
      </p>

      <p>
        6.3. O Cliente reconhece e concorda que os algoritmos, metodologias e
        processos desenvolvidos pela 4intelligence para a criação do Aplicativo
        e dos relatórios nele produzidos, além de serem de propriedade exclusiva
        da 4intelligence, que a 4intelligence não tem e não terá qualquer
        obrigação de divulgar seus algoritmos para o Cliente.
      </p>

      <p style={{ marginTop: '1rem' }}>
        <b>7. MANUTENÇÃO, SUPORTE, ATENDIMENTO AO CLIENTE</b>
      </p>

      <p>
        O Cliente será informado de dificuldades técnicas ou de manutenção que
        resultem em instabilidades temporárias, situações em que a 4intelligence
        empregará os recursos viáveis e meios razoáveis para conter quaisquer
        danos, mitigados e resolvidos conforme o possível.
      </p>

      <p>
        <b>
          Além disso, a 4intelligence reserva-se o direito de, a qualquer
          momento, modificar ou descontinuar, temporária ou permanentemente,
          funções e recursos do Aplicativo.
        </b>
      </p>

      <p>
        Para informações de suporte ao cliente com dúvidas relacionadas ao
        acesso e utilização, o Usuário poderá enviar um e-mail para{' '}
        <span>support@4intelligence.ai</span>.
      </p>

      <p style={{ marginTop: '1rem' }}>
        <b>8. DISPOSIÇÕES FINAIS</b>
      </p>

      <p>
        No cabeçalho deste documento está indicada a última data de atualização
        deste documento e a 4intelligence reserva-se o direito de alterar estes
        Termos de Uso a qualquer momento com aviso prévio para alterações no
        Aplicativo, seus serviços, suas políticas e práticas empresariais,
        avanços tecnológicos, mudanças na legislação e/ou boas práticas
        comerciais e/ou ajustar integrações com serviços ou produtos fornecidos
        por terceiros.
      </p>

      <p>
        As alterações serão informadas ao Cliente através de aviso destacado
        dentro do Aplicativo ou por e-mail. A continuidade no uso do Aplicativo
        após as alterações informadas constituirá plena aceitação das alterações
        para todos os efeitos e fins de direito.
      </p>

      <p>
        Se você não desejar continuar usando o serviço após a nova versão dos
        Termos de Uso, o Cliente e o Usuário devem rescindir o contrato de
        licença entrando em contato conosco através do e-mail{' '}
        <span>support@4intelligence.ai</span>.
      </p>

      <p>
        O foro eleito para dirimir quaisquer conflitos judiciais referente a
        este termo é o de São Paulo/SP
      </p>
    </Container>
  );
}
