import React, { useState } from 'react';

import { CaretDown } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/redux/store';
import { getPhosphorIcons } from 'src/utils/getPhosphorIcons';

import { Container, SidebarItem } from './styles';
import { SidebarProps } from './types';
import { ItemProps } from '../../types';

export function Sidebar({ menuData, currentPath }: SidebarProps) {
  const [openMenu, setOpenMenu] = useState<string[]>([currentPath]);

  const { language } = useSelector((state: RootState) => state.config);
  const navigate = useNavigate();

  const handleClickMenu = (path: string, clickedItem: string) => {
    if (clickedItem.endsWith('.md')) {
      navigate(`/help-center/${path}/${clickedItem}`);
    } else {
      const newPath = `${path}/${clickedItem}`;

      const index = openMenu.findIndex((item) =>
        item.startsWith(path ? newPath : clickedItem),
      );

      if (index !== -1) {
        if (openMenu[index].startsWith(`${clickedItem}`)) {
          setOpenMenu(
            openMenu.filter((item) => !item.startsWith(`${clickedItem}`)),
          );
        } else {
          const updatedOpenMenu = [...openMenu];
          updatedOpenMenu[index] = path;

          setOpenMenu(updatedOpenMenu);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (path === '') setOpenMenu([...openMenu, clickedItem]);
        else {
          const pathIndex = openMenu.findIndex((item) => item === path);

          const updatedOpenMenu = [...openMenu];

          if (pathIndex !== -1) updatedOpenMenu[pathIndex] = newPath;
          else {
            updatedOpenMenu.push(newPath);
          }

          setOpenMenu(updatedOpenMenu);
        }
      }
    }
  };

  const subMenu = (menu: ItemProps[], path: string) => (
    <ul>
      {menu.map((item) => (
        <SidebarItem
          key={`${path}/${item.path}`}
          selected={
            currentPath === `${path}/${item.path}` ||
            (!item.path.endsWith('.md') &&
              currentPath.startsWith(`${path}/${item.path}`))
          }
          isOpen={
            !!openMenu.find((value) => value.startsWith(`${path}/${item.path}`))
          }
          isLastLevel={item.path.endsWith('.md')}
        >
          <button
            type="button"
            onClick={() => handleClickMenu(path, item.path)}
          >
            {item.name[language]}
            {item.submenu && (
              <CaretDown
                weight={
                  currentPath.startsWith(`${path}/${item.path}`)
                    ? 'bold'
                    : 'regular'
                }
              />
            )}
          </button>
          {item.submenu && subMenu(item.submenu, `${path}/${item.path}`)}
        </SidebarItem>
      ))}
    </ul>
  );

  return (
    <Container>
      <div>
        <h3>Central de Ajuda</h3>
        <ul>
          {menuData.map((item, index) => (
            <SidebarItem
              key={item.path}
              selected={currentPath === item.path && item.path.endsWith('.md')}
              isLastLevel={item.path.endsWith('.md')}
              isOpen
              style={{ marginTop: index !== 0 ? '3rem' : 0 }}
            >
              <div className="first-level">
                {getPhosphorIcons(item.icon, 'duotone')}
                {item.name[language]}
              </div>
              {item.submenu && subMenu(item.submenu, item.path)}
            </SidebarItem>
          ))}
        </ul>
      </div>
    </Container>
  );
}
