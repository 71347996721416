import React from 'react';

import { Container } from './styles';

type VectorImage = {
  over: string;
  under: string;
  alt: string;
};

export function VectorImage({ over, under, alt }: VectorImage) {
  return (
    <Container>
      <img src={under} alt={alt} />
      <img src={over} alt={alt} />
    </Container>
  );
}
