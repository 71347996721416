import styled, { css } from 'styled-components';

export const Container = styled.aside`
  width: 25rem;
  height: auto;

  position: relative;

  border-right: 1px solid ${({ theme }) => theme.colors.gray2};

  > div {
    position: sticky;
    top: 4rem;

    width: 100%;
    height: calc(100vh - 8rem);

    padding-right: 2rem;

    overflow-x: hidden;
    overflow-y: auto;

    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 0.313rem;
      height: 0.313rem;
      padding: 0.063rem;
    }

    ::-webkit-scrollbar-track {
      transition: 0.2s;
      margin: 0.188rem;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
    }
  }

  h3 {
    font-size: 1.75rem;
    line-height: 2rem;
    font-weight: 600;

    color: ${({ theme }) => theme.colors.gray7};

    margin-bottom: 1.5rem;
  }

  ul {
    list-style: none;
  }

  li + li {
    margin-top: 0.5rem;
  }

  li > ul {
    margin-top: 0.75rem;
  }

  ul {
    ul {
      ul {
        margin-left: 0.75rem;
      }
    }
  }

  .first-level {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    font-size: 1rem;
    line-height: 2rem;
    font-weight: 600;

    text-transform: uppercase;
    letter-spacing: 0.08em;

    color: ${({ theme }) => theme.colors.primary} !important;

    &:hover {
      color: ${({ theme }) => theme.colors.primary} !important;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  @media (max-width: 1366px) {
    > div {
      padding-right: 1rem;
    }
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

type SidebarItemProps = {
  selected: boolean;
  isLastLevel: boolean;
  isOpen: boolean;
};

export const SidebarItem = styled.li<SidebarItemProps>`
  ${({ selected, isLastLevel, isOpen, theme }) => css`
    > button {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      text-align: start;

      color: ${selected && isLastLevel
        ? theme.colors.primary
        : selected
        ? theme.colors.gray7
        : theme.colors.gray6} !important;

      background: ${selected && isLastLevel
        ? 'rgba(76, 148, 255, 0.12)'
        : 'white'};
      border-radius: 5px;
      padding: ${isLastLevel ? '0.75rem 1rem' : '0.25rem 0'};

      font-weight: ${selected ? 600 : 400};

      transition: all 0.2s;

      svg {
        width: 1rem;
        height: 1rem;

        transition: all 0.2s;

        rotate: ${isOpen ? '-180deg' : '0deg'};
      }

      &:hover {
        color: ${selected && isLastLevel
          ? theme.colors.primary
          : theme.colors.gray8} !important;
      }
    }
  `}

  > ul {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  }
`;
