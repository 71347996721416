import React from 'react';

import { Footer } from './Footer';
import { Header } from './Header';
import { Container, Content } from './styles';
import { LayoutProps } from './types';

export function Layout({ children }: LayoutProps) {
  return (
    <Container>
      <Header />

      <Content>{children}</Content>

      <Footer />
    </Container>
  );
}
