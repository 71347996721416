import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100vw !important;
  max-width: none !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: ${({ theme }) => theme.colors.gray1};

  padding: 5rem 7rem;

  @media (max-width: 500px) {
    padding: 5rem 2rem;
  }
`;

export const Content = styled.div`
  max-width: 1100px;
  height: max-content;

  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 1rem;

  margin-top: 7rem;

  @media (max-width: 760px) {
    flex-direction: column;
    gap: 8rem;
  }
`;

type PlansProps = {
  index: number;
};

export const Card = styled.div<PlansProps>`
  ${({ index, theme }) => {
    const colors = [
      { normal: theme.colors.primary, hover: theme.colors.primaryDark },
      { normal: theme.colors.secondary, hover: theme.colors.secondaryDark },
      { normal: theme.colors.green4, hover: theme.colors.green6 },
    ];

    return css`
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background: ${theme.colors.white};

      border-radius: 16px;
      border: 1px solid ${theme.colors.gray2};

      padding: 2rem;

      .icon {
        width: 7rem;
        height: 7rem;

        display: flex;
        align-items: center;
        justify-content: center;

        background: ${colors[index].normal};

        border-radius: 50%;
        border: 12px solid ${theme.colors.white};

        padding: 1.5rem;
        margin-top: -6rem;

        svg {
          width: 100%;
          height: 100%;

          color: ${theme.colors.white};
        }
      }

      h2 {
        color: ${theme.colors.gray7};
        font-weight: 500;

        margin: 1rem 0;
      }

      > p {
        color: ${theme.colors.gray5};
        margin-bottom: auto;
      }

      > span {
        opacity: 0;
      }

      a {
        width: 100%;

        background-color: ${colors[index].normal};
        border-radius: 0.5rem;

        color: ${theme.colors.white};
        font-weight: 600;

        padding: 1rem 2rem;
        margin-top: 2rem;

        transition: background-color 0.2s;

        &:hover {
          background-color: ${colors[index].hover};
        }

        svg {
          width: 1.25rem;
          height: 1.25rem;

          margin-left: 1rem;
          margin-right: 0;
        }
      }
    `;
  }}
`;

export const Benefits = styled.div<PlansProps>`
  ${({ index, theme }) => {
    const colors = [
      theme.colors.primary,
      theme.colors.secondary,
      theme.colors.green4,
    ];

    return css`
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 1.5rem;

      .idealIcon {
        width: 2.5rem;
        height: 2.5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: ${colors[index]};

        border-radius: 100%;

        svg {
          width: 1.5rem;
          height: 1.5rem;

          color: ${theme.colors.white};
        }
      }

      span {
        color: ${theme.colors.gray4};
        font-size: 0.825rem;

        margin-top: 1rem;
      }

      p {
        color: ${theme.colors.gray6};
        font-weight: 600;
      }
    `;
  }}
`;
