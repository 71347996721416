import React from 'react';

import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { Title } from 'src/components/Title/styles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Container, Card } from './styles';

type TestimonialsProps = {
  dotsColor: string;
  testimonials: {
    name: string;
    jobDescription: string;
    company: string;
    quote: string;
    image: string;
  }[];
};

export function Testimonials({ testimonials, dotsColor }: TestimonialsProps) {
  const { t: translate } = useTranslation();

  return (
    <Container dotsColor={dotsColor}>
      <Title>
        <h3>{translate('productTestimonialsTitle')}</h3>
      </Title>

      <div className="slider-container">
        <Slider
          slidesToShow={2}
          slidesToScroll={2}
          arrows={false}
          dots
          responsive={[
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {testimonials.map((testimonial) => (
            <div key={testimonial.name}>
              <Card>
                <div>
                  <img
                    src={testimonial.image}
                    alt={translate('productTestimonialClientAlt') ?? ''}
                  />

                  <div>
                    <h5>{testimonial.name}</h5>
                    <small>{testimonial.jobDescription}</small>
                    <small>{testimonial.company}</small>
                  </div>
                </div>

                <p>{testimonial.quote}</p>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  );
}
