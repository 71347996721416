import styled from 'styled-components';

interface SelectLanguage {
  openModal: boolean;
}

export const SelectLanguage = styled.div<SelectLanguage>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;

  z-index: 300;

  > svg {
    width: 1.5rem;
    height: 1.5rem;

    color: ${({ theme }) => theme.colors.gray5};

    transition: color 0.2s;
  }

  > svg:last-of-type {
    width: 1rem;
    height: 1rem;

    transform: ${({ openModal }) =>
      openModal ? 'rotate(180deg)' : 'rotate(0deg)'};

    cursor: pointer;
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

interface MenuProps {
  visible: boolean;
}

export const Menu = styled.div<MenuProps>`
  width: fit-content;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;

  margin-left: auto;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 16px 32px rgba(97, 97, 97, 0.16);
  border-radius: 5px;

  position: absolute;
  top: 3rem;
  left: 50%;

  transform: translate(-50%, 0);
`;

interface MenuButtonProps {
  position: 'start' | 'middle' | 'end';
  selected: boolean;
}

export const MenuButton = styled.button<MenuButtonProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;

  padding: 1rem;

  background: ${({ theme, selected }) =>
    selected ? 'rgba(76, 148, 255, 0.2)' : theme.colors.white};

  transition: 0.2s;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  border-radius: ${({ position }) =>
    position === 'start' ? '5px 5px 0 0' : position === 'end' && '0 0 5px 5px'};

  img {
    width: 1.688rem;
    height: 1.25rem;
  }

  p {
    color: ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.gray6};

    font-size: 1rem;
    font-weight: ${({ selected }) => (selected ? '600' : '400')};
    line-height: 1.6875rem;
  }

  :hover {
    background: rgba(76, 148, 255, 0.2);
  }
`;
