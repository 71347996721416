import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Bank,
  Circle,
  CurrencyCircleDollar,
  GlobeHemisphereWest,
  Sun,
  TrendUp,
} from '@phosphor-icons/react';
import { isCompanyEmail } from 'company-email-validator';
import Recaptcha from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Logo from 'src/assets/4i-icon.svg';
import ChatAI from 'src/assets/chat-ai-image.svg';
import Error from 'src/assets/error.svg';
import FeatureStoreImage from 'src/assets/fs-image.svg';
import MailboxIcon from 'src/assets/mailbox.svg';
import TermsOfUse from 'src/assets/terms-of-use.svg';
import { Button } from 'src/components/Button';
import { CheckBox } from 'src/components/CheckBox';
import { Head } from 'src/components/Head';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Select } from 'src/components/Select';
import { freemiumApi } from 'src/service/freemiumApi';
import light from 'src/styles/themes/light';
import {
  TrackGoogleAnalyticsEvent,
  TrackGoogleAnalyticsPageView,
} from 'src/utils/google-analytics';
import * as Yup from 'yup';

import {
  CarouselContent,
  CarouselPagination,
  CheckboxContainer,
  Container,
  DotButton,
  ErrorContainer,
  FormContainer,
  FormError,
  IndicatorIcon,
  IndicatorsCard,
  IndicatorsContainer,
  LeftContent,
  Overlay,
  RightContent,
  TermsOfUseContainer,
} from './styles';
import { FormFields, SelectFields, SignUpForm, SignUpParams } from './types';
import { TermsOfUseContent } from '../components/TermsOfUse';

export function FreemiumSignUp() {
  const { t: translate } = useTranslation();

  const sectorOptions = [
    translate('freemiumSignUpPageSector1'),
    translate('freemiumSignUpPageSector2'),
    translate('freemiumSignUpPageSector3'),
    translate('freemiumSignUpPageSector4'),
    translate('freemiumSignUpPageSector5'),
    translate('freemiumSignUpPageSector6'),
    translate('freemiumSignUpPageSector7'),
    translate('freemiumSignUpPageSector8'),
    translate('freemiumSignUpPageSector9'),
    translate('freemiumSignUpPageSector10'),
    translate('freemiumSignUpPageSector11'),
    translate('freemiumSignUpPageSector12'),
    translate('freemiumSignUpPageSector13'),
    translate('freemiumSignUpPageSector14'),
    translate('freemiumSignUpPageSector15'),
    translate('freemiumSignUpPageSector16'),
  ];

  const departmentOptions = [
    translate('freemiumSignUpPageDepartment1'),
    translate('freemiumSignUpPageDepartment2'),
    translate('freemiumSignUpPageDepartment3'),
    translate('freemiumSignUpPageDepartment4'),
    translate('freemiumSignUpPageDepartment5'),
    translate('freemiumSignUpPageDepartment6'),
    translate('freemiumSignUpPageDepartment7'),
    translate('freemiumSignUpPageDepartment8'),
  ];

  const [carouselPage, setCarouselPage] = useState(1);
  const [pageIsClicked, setPageIsClicked] = useState(false);
  const [openTermsOfUseModal, setOpenTermsOfUseModal] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const [differentEmails, setDifferentEmails] = useState(false);

  const [successSignUp, setSuccessSignUp] = useState(false);
  const [loadingSignUp, setLoadingSignUp] = useState(false);

  const [acceptTermsEnabled, setAcceptTermsEnabled] = useState(false);
  const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(false);

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [expiredRecaptcha, setExpiredRecaptcha] = useState(false);

  const recaptcha = useRef<Recaptcha>(null);

  const siteKey = process.env.REACT_APP_RECAPTCHA_KEY || '';
  const regexInputName = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]*$/;

  const userSchema = Yup.object().shape({
    name: Yup.string()
      .matches(regexInputName, 'nameErrorNoSpecialCharacters')
      .max(100, 'charactersLengthError')
      .required('requiredField'),
    company: Yup.string()
      .max(100, 'charactersLengthError')
      .required('requiredField'),
    sector: Yup.object()
      .shape({
        label: Yup.string().required('requiredField'),
        value: Yup.string().required('requiredField'),
      })
      .required('requiredField'),
    department: Yup.object()
      .shape({
        label: Yup.string().required('requiredField'),
        value: Yup.string().required('requiredField'),
      })
      .required('requiredField'),
    position: Yup.string()
      .max(100, 'charactersLengthError')
      .required('requiredField'),
    email: Yup.string()
      .trim()
      .email('invalidEmail')
      .required('requiredField')
      .test('isCorporate', 'isNotCompanyEmail', (value) =>
        isCompanyEmail(value ?? ''),
      ),
    confirmEmail: Yup.string()
      .trim()
      .email('invalidEmail')
      .required('requiredField')
      .test('isCorporate', 'isNotCompanyEmail', (value) =>
        isCompanyEmail(value ?? ''),
      ),
    phone: Yup.string().matches(
      /^(\(\d{2}\) \d{5}-\d{4})?$/,
      'invalidPhoneNumber',
    ),
  });

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<SignUpForm>({
    resolver: yupResolver(userSchema),
  });

  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(
      location.pathname,
      'Tela de Cadastro do Freemium',
    );
  }, [location.pathname]);

  const signUp = async () => {
    const confirmEmailCorrect =
      getValues('email').trim() === getValues('confirmEmail').trim();

    if (!confirmEmailCorrect) setDifferentEmails(true);
    else setDifferentEmails(false);

    if (
      acceptedTermsOfUse &&
      confirmEmailCorrect &&
      recaptchaToken &&
      !loadingSignUp &&
      !emailAlreadyExists
    ) {
      setEmailAlreadyExists(false);
      setSuccessSignUp(false);
      setLoadingSignUp(true);

      try {
        const params: SignUpParams = {
          name: getValues('name'),
          email: getValues('email'),
          company: getValues('company'),
          position: getValues('position'),
          sector: getValues('sector').value,
          department: getValues('department').value,
        };

        const mobilephone = getValues('phone');

        if (mobilephone) {
          params.mobilephone = mobilephone;
        }

        await freemiumApi.post('/clients/users/freemium', params, {
          headers: {
            Authorization: recaptchaToken,
          },
        });

        setSuccessSignUp(true);

        window.scrollTo({ top: 0 });
      } catch (error: any) {
        if (error?.response?.status === 409) {
          setEmailAlreadyExists(true);
        } else if (error?.response?.status === 401) {
          setExpiredRecaptcha(true);
        } else {
          setOpenModalError(true);
        }
      }

      recaptcha.current?.reset();
      setRecaptchaToken(null);

      setLoadingSignUp(false);
    }
  };

  useEffect(() => {
    const termsContainer = document.getElementById('terms-of-use');

    if (termsContainer) {
      const enableTermsOfUseButton = () => {
        if (
          termsContainer.scrollTop + termsContainer.clientHeight + 1 >=
          termsContainer.scrollHeight
        ) {
          setAcceptTermsEnabled(true);
        }
      };

      termsContainer.addEventListener('scroll', enableTermsOfUseButton);

      return () => {
        termsContainer.removeEventListener('scroll', enableTermsOfUseButton);
      };
    }
  }, [openTermsOfUseModal]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        const nextPage = carouselPage === 3 ? 1 : carouselPage + 1;

        setCarouselPage(nextPage);
        setPageIsClicked(false);
      },
      pageIsClicked ? 10000 : 3000,
    );

    return () => clearInterval(interval);
  }, [carouselPage, pageIsClicked]);

  return (
    <Container>
      <Head
        title={translate('freemiumSignUpPageHeadTitle')}
        description={translate('freemiumSignUpPageHeadDescription') ?? ''}
      />

      {successSignUp ? (
        <LeftContent className="success-sign-up">
          <img src={MailboxIcon} alt={translate('formSubmittedImgAlt') ?? ''} />

          <h2>
            {translate('freemiumSignUpPageCreatedTitle')}{' '}
            <span>{getValues('email')}</span>
          </h2>

          <p>{translate('freemiumSignUpPageCreatedDescription')}</p>

          <Button
            buttonType="secondary"
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                `Esse não é meu e-mail`,
                `E-mail cadastrado errado, voltar para cadastro do freemium`,
                location.pathname,
              );
              setSuccessSignUp(false);
            }}
          >
            {translate('freemiumSignUpPageCreatedButton')}
          </Button>
        </LeftContent>
      ) : (
        <LeftContent>
          <h2>{translate('freemiumSignUpPageTitle')}</h2>
          <p>{translate('freemiumSignUpPageDescription')}</p>

          <FormContainer onSubmit={handleSubmit(signUp)}>
            <Controller
              name="name"
              key="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  label={translate('freemiumSignUpPageNameFieldLabel')}
                  placeholder={
                    translate('freemiumSignUpPageNameFieldPlaceholder') ?? ''
                  }
                  onChange={({ target: { value: val } }) => {
                    onChange(val);

                    trigger('name');
                  }}
                  value={value}
                  error={
                    errors.name?.message &&
                    errors.name?.message !== 'requiredField'
                      ? translate(errors.name.message) ?? ''
                      : ''
                  }
                  data-testid="input-name"
                />
              )}
            />
            <Controller
              name="company"
              key="company"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  label={translate('freemiumSignUpPageCompanyFieldLabel')}
                  placeholder={
                    translate('freemiumSignUpPageCompanyFieldPlaceholder') ?? ''
                  }
                  onChange={({ target: { value: val } }) => {
                    onChange(val);

                    trigger('company');
                  }}
                  value={value}
                  error={
                    errors.company?.message &&
                    errors.company?.message !== 'requiredField'
                      ? translate(errors.company.message) ?? ''
                      : ''
                  }
                  data-testid="input-company"
                />
              )}
            />
            <Controller
              name="sector"
              key="sector"
              control={control}
              defaultValue={undefined}
              render={({ field: { onChange, value } }) => (
                <Select
                  label={translate('freemiumSignUpPageSectorFieldLabel') ?? ''}
                  placeholder={
                    translate('freemiumSignUpPageSectorFieldPlaceholder') ?? ''
                  }
                  onChange={onChange}
                  value={value}
                  options={sectorOptions.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              )}
            />
            <Controller
              name="department"
              key="department"
              control={control}
              defaultValue={{ label: '', value: '' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  label={
                    translate('freemiumSignUpPageDepartmentFieldLabel') ?? ''
                  }
                  placeholder={
                    translate('freemiumSignUpPageDepartmentFieldPlaceholder') ??
                    ''
                  }
                  onChange={onChange}
                  value={value.label === '' ? undefined : value}
                  options={departmentOptions.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              )}
            />
            <Controller
              name="position"
              key="position"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  label={translate('freemiumSignUpPagePositionFieldLabel')}
                  placeholder={
                    translate('freemiumSignUpPagePositionFieldPlaceholder') ??
                    ''
                  }
                  onChange={({ target: { value: val } }) => {
                    onChange(val);

                    trigger('position');
                  }}
                  value={value}
                  error={
                    errors.position?.message &&
                    errors.position?.message !== 'requiredField'
                      ? translate(errors.position.message) ?? ''
                      : ''
                  }
                  data-testid="input-position"
                />
              )}
            />
            <Controller
              name="email"
              key="email"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  label={translate('freemiumSignUpPageEmailFieldLabel')}
                  placeholder={
                    translate('freemiumSignUpPageEmailFieldPlaceholder') ?? ''
                  }
                  onChange={({ target: { value: val } }) => {
                    onChange(val);

                    setEmailAlreadyExists(false);
                    setDifferentEmails(false);

                    trigger('email');
                  }}
                  value={value}
                  error={
                    errors.email?.message === 'invalidEmail'
                      ? translate('freemiumSignUpPageInvalidEmail') ?? ''
                      : errors.email?.message === 'isNotCompanyEmail'
                      ? translate('freemiumSignUpPageIsNotCompanyEmail') ?? ''
                      : emailAlreadyExists
                      ? translate('freemiumSignUpPageEmailAlreadyExists') ?? ''
                      : ''
                  }
                  data-testid="input-email"
                />
              )}
            />
            <Controller
              name="confirmEmail"
              key="confirmEmail"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  label={translate('freemiumSignUpPageConfirmEmailFieldLabel')}
                  placeholder={
                    translate(
                      'freemiumSignUpPageConfirmEmailFieldPlaceholder',
                    ) ?? ''
                  }
                  onChange={({ target: { value: val } }) => {
                    onChange(val);

                    setDifferentEmails(false);

                    trigger('confirmEmail');
                  }}
                  onBlur={() => {
                    const confirmEmailCorrect =
                      getValues('email').trim() ===
                      getValues('confirmEmail').trim();

                    if (!confirmEmailCorrect) setDifferentEmails(true);
                    else setDifferentEmails(false);
                  }}
                  value={value}
                  error={
                    errors.confirmEmail?.message === 'invalidEmail'
                      ? translate('freemiumSignUpPageInvalidEmail') ?? ''
                      : errors.confirmEmail?.message === 'isNotCompanyEmail'
                      ? translate('freemiumSignUpPageIsNotCompanyEmail') ?? ''
                      : differentEmails
                      ? translate('freemiumSignUpPageDifferentEmail') ?? ''
                      : ''
                  }
                  data-testid="input-confirm-email"
                />
              )}
            />
            <Controller
              name="phone"
              key="phone"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  type="tel"
                  label={translate('freemiumSignUpPagePhoneFieldLabel')}
                  placeholder={
                    translate('freemiumSignUpPagePhoneFieldPlaceholder') ?? ''
                  }
                  onChange={({ target: { value: val } }) => {
                    let input = val.replace(/\D/g, '');

                    if (input.length > 0 && input.length <= 2) {
                      input = `(${input}`;
                    } else if (input.length > 2 && input.length <= 7) {
                      input = `(${input.slice(0, 2)}) ${input.slice(2)}`;
                    } else if (input.length > 7) {
                      input = `(${input.slice(0, 2)}) ${input.slice(
                        2,
                        7,
                      )}-${input.slice(7, 11)}`;
                    }

                    onChange(input);

                    if (input.length === 15) {
                      trigger('phone');
                    }
                  }}
                  value={value}
                  onBlur={() => trigger('phone')}
                  error={
                    errors.phone?.message
                      ? translate(errors.phone.message) ?? ''
                      : ''
                  }
                  data-testid="input-phone-number"
                />
              )}
            />

            <Recaptcha
              ref={recaptcha}
              sitekey={siteKey}
              onChange={(value) => {
                setExpiredRecaptcha(false);
                setRecaptchaToken(value);
              }}
              onErrored={() => setRecaptchaToken(null)}
              onExpired={() => setRecaptchaToken(null)}
              data-testid="recaptcha"
            />

            {expiredRecaptcha && (
              <span className="expired-recaptcha">
                A verificação expirou. Marque a caixa de seleção novamente.
              </span>
            )}

            <CheckboxContainer>
              <CheckBox
                onClick={() => {
                  TrackGoogleAnalyticsEvent(
                    `Checkbox dos termos de uso`,
                    `Visualizer modal dos termos de uso do freemium`,
                    location.pathname,
                  );
                  setOpenTermsOfUseModal(true);
                }}
                checked={acceptedTermsOfUse}
                data-testid="terms-of-use-checkbox"
              />
              <p>
                {translate('freemiumSignUpPageAgreement1')}
                <a
                  href={process.env.REACT_APP_PRODUCT_TERMS_OF_USE_FREEMIUM}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    TrackGoogleAnalyticsEvent(
                      `Link Termos de Uso`,
                      `Visualizer arquivo dos termos de uso do freemium`,
                      location.pathname,
                    );
                  }}
                >
                  {translate('freemiumSignUpPageAgreement2')}
                </a>
                {translate('freemiumSignUpPageAgreement3')}
                <a
                  href={process.env.REACT_APP_PRIVACY_POLICY}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    TrackGoogleAnalyticsEvent(
                      `Link Política de Privacidade`,
                      `Visualizer arquivo da política de privacidade`,
                      location.pathname,
                    );
                  }}
                >
                  {translate('freemiumSignUpPageAgreement4')}
                </a>
              </p>
            </CheckboxContainer>

            {Object.keys(errors).find(
              (field) =>
                errors?.[field as FormFields]?.message === 'requiredField' ||
                errors?.[field as SelectFields]?.label?.message ===
                  'requiredField',
            )?.length && (
              <FormError data-testid="required-field-error">
                <p>{translate('freemiumSignUpPageRequiredField')}</p>
              </FormError>
            )}

            <Button
              type="submit"
              buttonType="primary"
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  `Criar conta`,
                  `Criar conta do freemium`,
                  location.pathname,
                );
                handleSubmit(signUp);
              }}
              loading={loadingSignUp}
              className={loadingSignUp ? 'loading-sign-up' : ''}
              data-testid="sign-up-button"
            >
              {translate('freemiumSignUpPageCreateAccount')}
            </Button>
          </FormContainer>
        </LeftContent>
      )}

      <RightContent>
        <img src={Logo} alt="4intelligence logo" />

        <CarouselPagination>
          <DotButton
            type="button"
            onClick={() => {
              setCarouselPage(1);
              setPageIsClicked(true);
            }}
            selected={carouselPage === 1}
            aria-label={
              translate('freemiumSignUpPageFirstCarouselPageButton') ?? ''
            }
          >
            <Circle weight="fill" />
          </DotButton>

          <DotButton
            type="button"
            onClick={() => {
              setCarouselPage(2);
              setPageIsClicked(true);
            }}
            selected={carouselPage === 2}
            aria-label={
              translate('freemiumSignUpPageSecondCarouselPageButton') ?? ''
            }
          >
            <Circle weight="fill" />
          </DotButton>

          <DotButton
            type="button"
            onClick={() => {
              setCarouselPage(3);
              setPageIsClicked(true);
            }}
            selected={carouselPage === 3}
            aria-label={
              translate('freemiumSignUpPageThirdCarouselPageButton') ?? ''
            }
          >
            <Circle weight="fill" />
          </DotButton>
        </CarouselPagination>

        {carouselPage === 1 ? (
          <CarouselContent>
            <h1>{translate('freemiumSignUpPageFirstCarouselTitle')}</h1>
            <p>{translate('freemiumSignUpPageFirstCarouselDescription')}</p>

            <IndicatorsContainer>
              <IndicatorsCard>
                <IndicatorIcon color={light.colors.teal4}>
                  <TrendUp weight="fill" />
                </IndicatorIcon>

                <h3>
                  {translate('freemiumSignUpPageFirstCarouselIndicator1')}
                </h3>
              </IndicatorsCard>

              <IndicatorsCard>
                <IndicatorIcon color={light.colors.yellow4}>
                  <Sun weight="fill" />
                </IndicatorIcon>

                <h3>
                  {translate('freemiumSignUpPageFirstCarouselIndicator2')}
                </h3>
              </IndicatorsCard>

              <IndicatorsCard>
                <IndicatorIcon color={light.colors.purple4}>
                  <Bank weight="fill" />
                </IndicatorIcon>

                <h3>
                  {translate('freemiumSignUpPageFirstCarouselIndicator3')}
                </h3>
              </IndicatorsCard>

              <IndicatorsCard>
                <IndicatorIcon color={light.colors.cyan4}>
                  <GlobeHemisphereWest weight="fill" />
                </IndicatorIcon>

                <h3>
                  {translate('freemiumSignUpPageFirstCarouselIndicator4')}x
                </h3>
              </IndicatorsCard>

              <IndicatorsCard>
                <IndicatorIcon color={light.colors.teal4}>
                  <CurrencyCircleDollar weight="fill" />
                </IndicatorIcon>

                <h3>
                  {translate('freemiumSignUpPageFirstCarouselIndicator5')}
                </h3>
              </IndicatorsCard>
            </IndicatorsContainer>

            <Overlay />
          </CarouselContent>
        ) : carouselPage === 2 ? (
          <CarouselContent>
            <h1>{translate('freemiumSignUpPageSecondCarouselTitle')}</h1>
            <p>{translate('freemiumSignUpPageSecondCarouselDescription')}</p>

            <img src={FeatureStoreImage} alt="Feature Store" />
          </CarouselContent>
        ) : (
          <CarouselContent>
            <h1>{translate('freemiumSignUpPageThirdCarouselTitle')}</h1>
            <p>{translate('freemiumSignUpPageThirdCarouselDescription')}</p>

            <img src={ChatAI} alt="4i chat" />
          </CarouselContent>
        )}
      </RightContent>

      {openTermsOfUseModal && (
        <Modal
          visible={openTermsOfUseModal}
          style={{
            width: '40rem',
            maxWidth: 'calc(100% - 1rem)',
            height: '35rem',
          }}
        >
          <TermsOfUseContainer>
            <img
              src={TermsOfUse}
              alt="Cadeado representando os termos de uso"
            />

            <h3>Termos de Uso</h3>

            <TermsOfUseContent />

            <ModalFooter>
              <Button
                buttonType="naked"
                className="naked-button"
                onClick={() => {
                  TrackGoogleAnalyticsEvent(
                    `Cancelar`,
                    `Fechar/cancelar modal de termos de uso do freemium`,
                    location.pathname,
                  );
                  setOpenTermsOfUseModal(false);
                }}
                data-testid="cancel-terms-of-use-button"
              >
                Cancelar
              </Button>
              <Button
                buttonType="primary"
                disabled={!acceptTermsEnabled}
                onClick={() => {
                  TrackGoogleAnalyticsEvent(
                    `Eu li e concordo com os Termos de Uso`,
                    `Aceitar termos de uso do freemium`,
                    location.pathname,
                  );
                  setAcceptedTermsOfUse(true);
                  setOpenTermsOfUseModal(false);
                }}
                data-testid="confirm-terms-of-use-button"
              >
                Eu li e concordo com os Termos de Uso
              </Button>
            </ModalFooter>
          </TermsOfUseContainer>
        </Modal>
      )}

      {openModalError && (
        <Modal
          visible={openModalError}
          style={{
            width: '30rem',
            maxWidth: 'calc(100% - 1rem)',
            height: '25rem',
          }}
        >
          <ErrorContainer>
            <img src={Error} alt="error" data-testid="cloud-error-img" />

            <h3>Desculpa, ocorreu um erro ao criar a sua conta</h3>
            <p>Por favor, tente novamente.</p>

            <ModalFooter>
              <Button
                buttonType="naked"
                className="naked-button"
                onClick={() => {
                  TrackGoogleAnalyticsEvent(
                    `Ok`,
                    `Erro ao criar conta`,
                    location.pathname,
                  );
                  setOpenModalError(false);
                }}
              >
                Ok
              </Button>
            </ModalFooter>
          </ErrorContainer>
        </Modal>
      )}
    </Container>
  );
}
