import styled, { css } from 'styled-components';

type PropsInput = {
  disabled?: boolean;
};

type PropsContainerInput = {
  error: boolean;
};

export const ContainerInput = styled.div<PropsContainerInput>`
  p {
    color: ${({ theme }) => theme.colors.red2} !important;
    line-height: 1rem;
    font-style: normal;
    font-size: ${({ error }) => (error ? '0.875rem' : '0')};
    opacity: 0;

    transition: opacity 0.3s ease-in-out;
    transition: margin-top 0.3s ease-in-out;

    ${({ error }) =>
      error &&
      css`
        margin-top: 0.5rem;
        font-size: 0.875rem;
        opacity: 1;
      `}
  }

  > div {
    border: ${({ theme, error }) =>
      `1px solid ${error ? theme.colors.red2 : theme.colors.gray2}`};
    transition: border 0.3s ease-in-out;
  }

  a {
    color: ${({ theme }) => theme.colors.red2};
    font-weight: 500;

    text-decoration-line: underline;
    text-underline-offset: 0.25rem;
  }
`;

export const ContentInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  height: 2.625rem;
`;

export const Input = styled.input<PropsInput>`
  width: 100%;
  font-size: 0.875rem !important;
  color: ${({ theme }) => theme.colors.gray6};
  padding-left: 0px;
  border: 0;
  outline: 0;
  font-weight: 400;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;
