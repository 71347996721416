import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  height: fit-content;

  .expired-recaptcha {
    color: ${({ theme }) => theme.colors.red2};
    font-weight: 500;
    font-size: 0.875rem;

    margin-top: -1rem;
  }

  .success-sign-up {
    justify-content: center;
    height: 63rem;

    @media (max-width: 1366px) {
      justify-content: start;
    }
  }

  > div {
    width: 50%;
  }

  @media (max-width: 1366px) {
    width: 100%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    > div {
      width: 70%;
    }
  }

  @media (max-width: 768px) {
    > div {
      width: 90%;
    }
  }

  @media (max-width: 500px) {
    > div {
      width: 100%;
    }
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  background: ${({ theme }) => theme.colors.white};

  padding: 5rem 7.5rem;

  h2 {
    width: 100%;

    font-size: 1.75rem;
    font-weight: 600;
    line-height: 150%;

    text-align: center;

    color: ${({ theme }) => theme.colors.gray7};

    margin-bottom: 1rem;

    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  > p {
    width: 80%;

    text-align: center;

    font-size: 1.125rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 2rem;
  }

  img {
    width: 13.39288rem;
    height: 12.5rem;

    margin-bottom: 3rem;
  }

  > button {
    background: rgba(76, 148, 255, 0.12);
    color: ${({ theme }) => theme.colors.primary};
    border: none;

    &:hover {
      background: ${({ theme }) => theme.colors.primaryDark};
      color: ${({ theme }) => theme.colors.white};
      border: none;
    }
  }

  @media (max-width: 1366px) {
    padding: 4rem 3.25rem;

    > p {
      font-size: 1rem;
    }
  }

  @media (max-width: 1200px) {
    padding: 4rem 2.5rem;
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: start;

    > div {
      text-align: start;
    }
  }

  @media (max-width: 768px) {
    padding: 4rem 1.5rem;
  }

  @media (max-width: 500px) {
    padding: 3rem 0.75rem;
  }
`;

export const FormContainer = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-top: 2rem;

  .loading-sign-up {
    cursor: wait;
  }
`;

export const FormError = styled.div`
  text-align: center;

  padding: 1rem 2rem;

  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;

  background: ${({ theme }) => `${theme.colors.red3}14`};
  color: ${({ theme }) => theme.colors.red3};
`;

export const RightContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  padding: 5rem 7.5rem;

  background: radial-gradient(
    155.11% 142.68% at 100% 0%,
    rgba(242, 125, 180, 0.05) 0%,
    rgba(244, 171, 207, 0.35) 0.01%,
    rgba(245, 210, 229, 0.59) 0.02%,
    rgba(247, 250, 252, 0.85) 26.63%,
    #f7fafc 69.77%,
    rgba(230, 240, 252, 0.9) 91.15%,
    rgba(218, 233, 253, 0.83) 99.95%,
    rgba(218, 233, 253, 0.83) 99.96%,
    rgba(195, 219, 253, 0.7) 99.97%,
    rgba(161, 199, 254, 0.5) 99.98%,
    rgba(129, 180, 254, 0.32) 99.99%,
    rgba(230, 240, 252, 0.9) 99.99%,
    rgba(76, 148, 255, 0.01) 100%,
    rgba(224, 236, 252, 0.87) 100%
  );

  > img {
    width: 3rem;
    height: 3rem;
  }

  @media (max-width: 1366px) {
    padding: 4rem 3.25rem;
  }

  @media (max-width: 1200px) {
    padding: 4rem 2.5rem;
  }

  @media (max-width: 1000px) {
  }

  @media (max-width: 768px) {
    padding: 4rem 1.5rem;
  }

  @media (max-width: 500px) {
    padding: 3rem 0.75rem;
  }
`;

export const CarouselPagination = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  margin-top: 3rem;
  margin-bottom: 2rem;
`;

type DotButtonProps = {
  selected: boolean;
};

export const DotButton = styled.button<DotButtonProps>`
  display: flex;

  width: fit-content;
  height: fit-content;

  svg {
    width: 0.75rem;
    height: 0.75rem;

    color: ${({ selected, theme }) =>
      selected ? theme.colors.primary : theme.colors.gray2};

    transition: all 0.2s;

    &:hover {
      color: ${({ selected, theme }) =>
        selected ? theme.colors.primary : theme.colors.gray4};
    }
  }
`;

export const CarouselContent = styled.div`
  h1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray7};
  }

  p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};

    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
    max-height: 30rem;
  }

  @media (max-width: 1366px) {
    p {
      font-size: 1rem;
    }

    h1 {
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 150%;

      color: ${({ theme }) => theme.colors.gray7};
    }
  }

  @media (max-width: 1000px) {
    height: 45rem;
    overflow: hidden;
  }
`;

export const IndicatorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const IndicatorsCard = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;

  padding: 1.5rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  background: ${({ theme }) => theme.colors.white};

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    color: ${({ theme }) => theme.colors.gray6};
  }

  @media (max-width: 1366px) {
    padding: 1rem;

    h3 {
      font-size: 1rem;
    }
  }
`;

type IndicatorIconProps = {
  color: string;
};

export const IndicatorIcon = styled.div<IndicatorIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem;

  background: ${({ color }) => `${color}1F`};
  border-radius: 8px;

  svg {
    width: 2rem;
    height: 2rem;

    color: ${({ color }) => color};
  }

  @media (max-width: 1366px) {
    padding: 0.75rem;

    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 50%;

  position: absolute;
  bottom: 0;
  left: 0;

  background: linear-gradient(
    transparent,
    ${({ theme }) => `${theme.colors.gray0}bf`},
    ${({ theme }) => theme.colors.gray0}
  );
`;

export const TermsOfUseContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 5rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;

    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const ErrorContainer = styled.div`
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;

    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.75rem;
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;

  p,
  a {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 400;

    display: inline;
  }

  p {
    text-align: start;
  }

  a {
    cursor: pointer;

    text-decoration-line: underline;
    text-underline-offset: 0.25rem;
  }
`;
