import styled, { css } from 'styled-components';

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${({ theme }) => theme.colors.gray5};

  label {
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

type PropsContainerSelect = {
  isMulti?: boolean;
};

export const ContainerSelect = styled.div<PropsContainerSelect>`
  position: relative;
  text-align: start;

  > p {
    color: ${({ theme }) => theme.colors.red2};
    line-height: 1rem;
    font-style: normal;
    font-size: 0px;
    opacity: 0;

    transition: opacity 0.3s ease-in-out;
    transition: margin-top 0.3s ease-in-out;
  }

  > svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: ${({ theme }) => theme.colors.red2};
  }

  ${({ isMulti }) =>
    isMulti &&
    css`
      .css-2b097c-container {
        position: unset;
      }
      .select__control {
        max-height: 316px;
        overflow-y: auto;
      }

      /* .select__control {
        > div:nth-child(2) {
          align-self: baseline !important;
          position: sticky !important;
          top: calc(50% - 16px) !important;
        }
      } */
    `}

  //React-Select
  .select__indicator-separator {
    // sepadador lado esquerdo da arrow bottom |
    display: none;
  }

  .css-2b097c-container {
    // Container do select
    width: 100%;
  }

  .select__placeholder {
    color: ${({ theme }) => theme.colors.gray4} !important;
  }

  .select__control {
    ///container do select
    width: 100%;
    border-radius: 8px;

    border: ${({ theme }) => `1px solid ${theme.colors.gray2}`};
  }

  .select__control:hover {
    //container select selecionado
    border: ${({ theme }) => `1px solid ${theme.colors.gray1}`};
  }

  .select__single-value {
    color: ${({ theme }) => theme.colors.gray6};
  }

  .select__menu {
    ///options
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    color: ${({ theme }) => theme.colors.gray5};

    .select__option {
      /// div contendo o texto
      color: ${({ theme }) => theme.colors.gray5};

      padding: 0.75rem 1rem;
      background: transparent;
      cursor: pointer !important;
    }

    .select__option:hover {
      ///div contendo texto com hover
      background: ${({ theme }) => theme.colors.gray1};
      cursor: pointer !important;
    }
  }

  .select__menu-list {
    ::-webkit-scrollbar {
      width: 0.313rem;
      height: 0.313rem;
      padding: 0.063rem;
    }

    ::-webkit-scrollbar-track {
      transition: 0.2s;
      margin: 0.188rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
    }
  }

  .select__indicator {
    //Arrow bottom do select quando nao esta com as options aberta
    cursor: pointer;

    > svg {
      stroke-width: 0px;
    }
  }

  .select__control--is-focused {
    .select__indicator {
      //Arrow bottom do select quando esta com as options aberta
      > svg {
        transform: rotate(180deg) !important;
      }
    }
  }

  .select__input-container {
    margin: 0px !important;
    padding: 0px !important;
  }

  .select__value-container {
    //container contendo o input de pesquisa
    cursor: text !important;

    color: ${({ theme }) => theme.colors.gray6} !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;

    padding: 0.75rem 1rem !important;

    .select__input {
      color: ${({ theme }) => theme.colors.gray6};

      padding: 0;
      margin: 0;
    }
  }

  .select__multi-value {
    ///container multivalorado
    border-radius: 16px;
    background: rgba(76, 148, 255, 0.12);
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;

    .select__multi-value__remove {
      color: ${({ theme }) => theme.colors.primary};
      cursor: pointer;

      transition: color 0.2s;

      &:hover {
        background: unset;
        color: ${({ theme }) => theme.colors.primaryDark};
      }
    }

    .select__multi-value__label {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  .select__control {
    transition: border 0.2s;
  }

  .select__control--is-focused {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .select__option--is-selected {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary} !important;
    background-color: ${({ theme }) => `${theme.colors.primary}14`} !important;
  }

  .select__dropdown-indicator {
    svg {
      color: ${({ theme }) => theme.colors.gray4};
      stroke-width: 1px;
    }
  }
`;
