import styled, { css, keyframes } from 'styled-components';

type PropsContainer = {
  visible: boolean;
};

export const Container = styled.div<PropsContainer>`
  width: 100vw !important;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ visible }) => (visible ? 'flex' : 'none')} !important;
  justify-content: center;
  align-items: center;
  z-index: 2000;

  margin: 0 !important;
`;

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
`;

const start = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity:1;
  }
`;

type PropsContent = {
  visible: boolean;
  animation: boolean;
};

export const Content = styled.div<PropsContent>`
  position: relative;

  background: ${({ theme }) => theme.colors.white};
  padding: 1.5rem;
  border-radius: 8px;
  z-index: 2002;

  ${({ animation }) =>
    animation &&
    css`
      animation: ${start} 1s;
    `};

  ${({ visible }) =>
    visible === false &&
    css`
      display: none;
    `};
`;

export const CloseButton = styled.button`
  position: absolute;

  top: 0;
  right: -4rem;

  width: 3rem;
  height: 3rem;

  z-index: 100000;
  border-radius: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.white};

  svg {
    width: 2rem;
    height: 2rem;

    color: ${({ theme }) => theme.colors.gray4};

    transition: color 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.gray7};
    }
  }

  @media (max-width: 500px) {
    display: none;
  }
`;
