import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import CookieImg from 'src/assets/terms-of-use.svg';
import { Button } from 'src/components/Button';

import { Container, TextContainer } from './styles';

export function Cookies() {
  const [modalCookieVisible, setModalCookieVisible] = useState(false);

  const { t: translate } = useTranslation();

  useEffect(() => {
    const value = localStorage.getItem('modalCookieVisible');
    if (value !== 'false') setModalCookieVisible(true);
  }, []);

  const handleClickAcceptCookie = () => {
    setModalCookieVisible(false);

    localStorage.setItem('modalCookieVisible', 'false');
  };

  return (
    <Container
      data-testid="modal-cookie"
      data-cy="modal-cookie"
      visible={modalCookieVisible}
    >
      <div>
        <img src={CookieImg} alt="Cadeado de cookies" />

        <TextContainer>
          <p>{translate('cookiesText')}</p>
        </TextContainer>

        <Button
          buttonType="primary"
          onClick={handleClickAcceptCookie}
          data-testid="accept-cookies-button"
          data-cy="accept-cookies-button"
        >
          {translate('cookiesButtonText')}
        </Button>
      </div>
    </Container>
  );
}
