import styled from 'styled-components';

type Container = {
  visible?: boolean;
};

export const Container = styled.div<Container>`
  position: fixed;
  display: ${({ visible }) => (visible ? 'unset' : 'none')};
  bottom: 2rem;
  left: 50%;

  z-index: 1000000;

  width: 90%;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 64px rgba(10, 32, 66, 0.16);

  transform: translate(-50%, 0);
  border-radius: 8px;
  padding: 2rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;

    button {
      white-space: nowrap;

      background: ${({ theme }) => theme.colors.primary};

      padding: 0.75rem 2rem;

      :hover {
        background: ${({ theme }) => theme.colors.primaryDark};
      }
    }

    img {
      height: 4rem;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 1rem;

      button {
        padding: 0.75rem 1.5rem;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 1.5rem;
  }
`;

export const TextContainer = styled.div`
  overflow-y: auto;

  p {
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.gray5};

    a {
      position: relative;
      color: ${({ theme }) => theme.colors.secondary};

      &:after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 2px;
        border-bottom: ${({ theme }) => `1px solid ${theme.colors.secondary}`};
      }
    }
  }

  @media (max-width: 500px) {
    p {
      font-size: 0.875rem;
      line-height: 1.35rem;
    }
  }
`;
