import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MailboxIcon from 'src/assets/mailbox.svg';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';

import {
  Container,
  FormContent,
  Informations,
  SubmittedContent,
} from './styles';
import { FormProps } from './types';
import { Skeleton } from '../Skeleton';

export function Form({
  hubspotFormId,
  hubspotPortalId,
  privacyPolicy,
}: FormProps) {
  const [loadingForm, setLoadingForm] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const { t: translate } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    function handleSubmit(event: MessageEvent) {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormSubmitted' &&
        event.origin === document.location.origin
      ) {
        setSubmitted(true);
      }
    }

    function addHubspotForm() {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: 'na1',
          portalId: hubspotPortalId,
          formId: hubspotFormId,
          target: '#contact-form',
          css: '',
        });
      }
    }

    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    window.addEventListener('message', handleSubmit);
    script.addEventListener('load', addHubspotForm);

    const observer = new MutationObserver(() => {
      if (
        document.contains(document.getElementById(`hsForm_${hubspotFormId}`))
      ) {
        setLoadingForm(false);
        observer.disconnect();
      }
    });

    observer.observe(document, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    });

    return () => {
      script.removeEventListener('load', addHubspotForm);
      window.removeEventListener('message', handleSubmit);
      observer.disconnect();
    };
  }, [hubspotFormId, hubspotPortalId]);

  if (submitted) {
    return (
      <Container style={{ justifyContent: 'center' }}>
        <SubmittedContent>
          <img src={MailboxIcon} alt={translate('formSubmittedImgAlt') ?? ''} />

          <h2>{translate('formSubmittedTitle')}</h2>

          <p>{translate('formSubmittedDescription')}</p>
        </SubmittedContent>
      </Container>
    );
  }

  return (
    <Container>
      <FormContent id="contact-form" className="contact-form" />

      {loadingForm ? (
        <Skeleton
          data-testid="form-loading"
          data-cy="form-loading"
          style={{ height: '700px' }}
        />
      ) : (
        <Informations>
          <p>
            {translate('formPrivacyPolicyInfo')}
            <a
              href={privacyPolicy}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'Link Política de Privacidade',
                  'Visualizer arquivo da política de privacidade',
                  location.pathname,
                );
              }}
            >
              {translate('formPrivacyPolicy')}
            </a>
            .
          </p>
        </Informations>
      )}
    </Container>
  );
}
