import React, { useEffect } from 'react';

import { ChatCircleDots, CheckCircle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ColorfulIcon from 'src/assets/colorful-icon.svg';
import Diamond from 'src/assets/diamond.svg';
import { Form } from 'src/components/Form';
import { Head } from 'src/components/Head';
import { LanguageWarning } from 'src/components/LanguageWarning';
import { RootState } from 'src/redux/store';
import {
  TrackGoogleAnalyticsEvent,
  TrackGoogleAnalyticsPageView,
} from 'src/utils/google-analytics';

import {
  Card,
  CardHeader,
  Container,
  Divider,
  FreemiumInformation,
  Icon,
  LeftContent,
  PlatformInformation,
  PlatformList,
  RightContent,
  Tag,
} from './styles';

export function FreemiumUpgrade() {
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(
      location.pathname,
      'Tela de Upgrade do Freemium',
    );
  }, [location.pathname]);

  return (
    <Container>
      <Head
        title={translate('freemiumUpgradePageHeadTitle')}
        description={translate('freemiumUpgradePageHeadDescription') ?? ''}
      />

      <LeftContent>
        <h2>{translate('freemiumUpgradePageTitle')}</h2>
        <p>{translate('freemiumUpgradePageDescription')}</p>

        {urlPrefix === '/en' && <LanguageWarning />}

        <Link
          to="https://api.whatsapp.com/send?phone=5531987888326&text=Olá, tenho interesse em conversar sobre a 4intelligence"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              'Falar pelo Whatsapp',
              'Falar pelo Whatsapp',
              location.pathname,
            );
          }}
        >
          <ChatCircleDots weight="fill" />
          {translate('contactSalesPageWhatsAppButton')}
        </Link>

        <Divider className="contact-divider" />

        <Form
          hubspotFormId={process.env.REACT_APP_FREEMIUM_HUBSPOT_FORM_ID ?? ''}
          hubspotPortalId={
            process.env.REACT_APP_FREEMIUM_HUBSPOT_PORTAL_ID ?? ''
          }
          privacyPolicy={process.env.REACT_APP_PRIVACY_POLICY ?? ''}
        />
      </LeftContent>

      <Divider className="info-divider" />

      <RightContent>
        <Card>
          <CardHeader>
            <Icon>
              <img
                src={Diamond}
                alt="Diamante representando o acesso gratuito da plataforma"
              />
            </Icon>

            <FreemiumInformation>
              <h3>Free</h3>
              <p>Acesso grátis e restrito à plataforma</p>
            </FreemiumInformation>
          </CardHeader>

          <Tag>
            <p>Seu acesso atual</p>
          </Tag>
        </Card>

        <Card>
          <CardHeader>
            <Icon>
              <img
                src={ColorfulIcon}
                alt="Ícone da 4intelligence representando o acesso completo da plataforma"
              />
            </Icon>

            <PlatformInformation>
              <h3>Business</h3>
            </PlatformInformation>
          </CardHeader>

          <PlatformList>
            <div>
              <CheckCircle weight="fill" />

              <p>Acesso a mais de 100.000 séries</p>
            </div>

            <div>
              <CheckCircle weight="fill" />

              <p>Dados de diferentes setores, países e fontes</p>
            </div>

            <div>
              <CheckCircle weight="fill" />

              <p>Atualizações automáticas e projeções confiáveis</p>
            </div>

            <div>
              <CheckCircle weight="fill" />

              <p>Modelagem em poucos cliques</p>
            </div>

            <div>
              <CheckCircle weight="fill" />

              <p>
                Conciliação da explicabilidade e acuracidade através da nossa
                inteligência artificial
              </p>
            </div>

            <div>
              <CheckCircle weight="fill" />

              <p>
                Interpretação dos impactos dos principais fatores no seu setor
                de atuação
              </p>
            </div>

            <div>
              <CheckCircle weight="fill" />

              <p>União da ciência de dados e inteligência artificial</p>
            </div>
          </PlatformList>
        </Card>
      </RightContent>
    </Container>
  );
}
