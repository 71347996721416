import React from 'react';

import { ArrowDown } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import contactSalesBackground from 'src/assets/contact-sales-background.svg';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';

import { Container, ArrowIcon, BackgroundImage } from './styles';

type ContactSalesProps = {
  content?: {
    title: string;
    button: string;
  };
  color?: {
    normal?: string;
    hover?: string;
  };
};

export function ContactSales({ content, color }: ContactSalesProps) {
  const { t: translate } = useTranslation();
  const { urlPrefix, language } = useSelector(
    (state: RootState) => state.config,
  );

  const location = useLocation();

  return (
    <Container language={language} color={color?.normal} hover={color?.hover}>
      <BackgroundImage
        src={contactSalesBackground}
        alt={translate('contactSalesBackgroundAlt') ?? ''}
      />

      <ArrowIcon>
        <ArrowDown />
      </ArrowIcon>

      <Title>
        {!content && <h4>{translate('contactSalesCallout')}</h4>}
        <h2>{content?.title || translate('contactSalesTitle')}</h2>
      </Title>

      {!content && <p>{translate('contactSalesDescription')}</p>}

      <Link
        to={`${urlPrefix}/contact-sales`}
        data-testid="contact-sales-button"
        onClick={() => {
          TrackGoogleAnalyticsEvent(
            'Falar com vendas',
            'Ir para tela Falar com Vendas',
            location.pathname,
          );
        }}
      >
        {content ? content.button : translate('bookDemo')}
      </Link>
    </Container>
  );
}
