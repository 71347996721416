import React from 'react';

import { BarContents, Container, Content } from './styles';
import { LoadingProps } from './types';

export function Loading({ ...rest }: LoadingProps) {
  return (
    <Container>
      <Content {...rest}>
        <BarContents>
          <div />
          <div />
          <div />
        </BarContents>
      </Content>
    </Container>
  );
}
