import styled from 'styled-components';

export const LabelStyle = styled.label`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.375rem;

  color: ${({ theme }) => theme.colors.gray5};

  display: flex;
  margin-bottom: 0.5rem;
`;
